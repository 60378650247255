import React, { useState } from 'react'

import BoxWhite from '../../components/Box/BoxWhite'
import './Rules.scss'

const Rules = () => {
  return (

    <BoxWhite containerClass='rules__wrapper'>
      {/*<div className='rules__title-wrapper'>*/}
      {/*  <h2 className='rules__title'>กฎกติกา</h2>*/}
      {/*</div>*/}
      <div className='rules__content mt-20'>
        <h2 className='rules__sub-title'>สวัสดีครับ ผู้เล่นผู้น่ารักทุกท่าน</h2>
        <div className='rules__content-text-wrapper'>
          <p className='rules__content-text-wrapper-paragraph'>
            &nbsp;&nbsp;&nbsp;&nbsp;สมาชิกที่รักทุกท่าน ยินดีต้อนรับสู่ หวยยอดธง เทคโนโลยีออนไลน์อันทรงประสิทธิภาพที่สุดในเวลานี้
            เพื่อการเล่นล็อตโต้ อันลื่นไหลประดุจแพรหางแห่งเทพเจ้านกยูงที่งดงาม เพื่อสมาชิกให้ได้รับความบันเทิงเริงใจ
            ภายใต้ความสุจริตยุติธรรม สมาชิกทุกคนจะอยู่ภายใต้กติกาเดียวนี้โดยไม่มีข้อยกเว้น
          </p>
          <p className='rules__content-text-wrapper-paragraph'>
            &nbsp;&nbsp;&nbsp;&nbsp;กติกาในการเล่น หวยยอดธง มีดังนี้ครับ
          </p>
          <ul style={{ listStyle: 'disc', paddingLeft: '10px' }}>
            <li>
              หวยยอดธง ให้บริการสำหรับผู้ที่เป็นสมาชิกเท่านั้น
            </li>
            <li>
              สมาชิกมีสิทธิในการเล่นเกมส์ต่างๆ บนเว็บไซด์ และได้รับรางวัลตามที่ หวยยอดธง กำหนด
            </li>

            <li>
              รายการที่เป็นโมฆะ อันเนื่องมาจากสาเหตุต่างๆ อาทิ การไม่กดยืนยันเมื่อทำรายการ การทำรายการช้ากว่ากำหนด ปัญหาเครือข่ายอินเตอร์เน็ตของลูกค้า การมีเครดิตไม่พอ และอื่นๆ หากรายการนั้นเป็นโมฆะ จะไม่ได้รับรางวัลทั้งสิ้นทุกกรณี            </li>
            <li>
              หากพบการทุจริตไม่ว่ากรณีใดก็ตาม ทั้งที่เกิดจากตัวสมาชิก หรือตัวผู้ให้กำเนิดหวย เช่น การล็อคผลรางวัล การซื้อขายเลขล็อค ทางเราขอสงวนสิทธิ์ในการดำเนินการตรวจสอบ และระงับการถอนเงินของสมาชิกท่านนั้นที่เข้าข่ายมีพฤติกรรมทุจริต ซึ่งหากพิสูจน์ได้ว่าบริสุทธิ์จริง ทางเราจะดำเนินการคืนเงินเข้าสู่บัญชีของสมาชิก แต่หากพิสูจน์ได้ว่าผิดจริงสมาชิกรายนั้นจะถูกระงับบัญชี และเพิกถอนสิทธิ์การเป็นสมาชิกถาวร หรือ ลงโทษต่างๆ เหมาะสม            </li>
            <li>
              หากพิสูจน์ได้ว่ามีการทุจริตเกิดขึ้นจริง ทางเรามีความจำเป็นในการแบนบัญชีผู้เล่นถาวร และบทลงโทษอื่นๆ
              ตามพฤติการณ์ครับ
            </li>
          </ul>
          <p className='rules__content-text-wrapper-paragraph' style={{ paddingTop: '20px' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;หวยยอดธง มีความยินดีอย่างยิ่งที่ได้รับใช้คุณลูกค้า ขอให้ลูกค้าทุกท่านโชคดีตลอดปี
            เล่นแต่พอดี เล่นให้สนุก รู้จักวางแผน รู้จักชนะใจตัวเอง รวยแน่นอนครับ
          </p>
        </div>
      </div>
    </BoxWhite>
  )
}

export default Rules
