import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import moment from 'moment'

export default function ZodiacModal(props) {
  const showModal = props.showModal

  const setShowModal = props.setShowModal
  const lottoName = 'สถิตินักษัตรวันนี้'
  const lottoGroup = 'สิบสองนักษัตร'
  const drawDateStr = moment(new Date()).format('DD/MM/YY')
  // const drawDateStr = '08/06/23'

  const [lottoData, setLottoData] = useState([])
  const [hotNumber, setHotNumber] = useState([])
  const [coldNumber, setColdNumber] = useState([])
  const [halfNumber, setHalfNumber] = useState(0)
  const [doubleNumber, setDoubleNumber] = useState(0)
  const [tripleNumber, setTripleNumber] = useState(0)
  const [isLastest, setIsLastest] = useState(false)
  const [storeData, setStoreData] = useState('empty')

  // console.log(lottoName, lottoGroup, drawDateStr, 'asdf;alksdf')

  const allStar = ['ชวด', 'ฉลู', 'ขาล', 'เถาะ', 'มะโรง', 'มะเส็ง', 'มะเมีย', 'มะแม', 'วอก', 'ระกา', 'จอ', 'กุน']
  const targetStar = (zodiacReward) => {
    return allStar[zodiacReward - 1]
  }

  const getHotAndCold = (data) => {
    // console.log(data)
    let newArray = []
    let half = 0
    let double = 0
    let triple = 0
    for (let i = 0; i < data.length; i++) {
      const fifteen = data[i].results['6S'].slice(0, 2)
      const twenty = data[i].results['6S'].slice(2, 4)
      const trueReward = data[i].results['2B']

      newArray.push(trueReward)
      // newArray.push(twoA[1])
      if (fifteen === twenty && twenty === trueReward) {
        ++triple
      } else {
        if (fifteen === trueReward) {
          ++half
        }
        if (twenty === trueReward) {
          ++double
        }
      }
    }
    // console.log(newArray, 'newArray')

    const counts = {}
    newArray.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1
    })
    // console.log(counts, 'counts')
    let max = Object.entries(counts).reduce((max, entry) => (entry[1] >= max[1] ? entry : max), [0, -Infinity])
    let min = Object.entries(counts).reduce((min, entry) => (entry[1] <= min[1] ? entry : min), [0, +Infinity])
    // console.log(max, 'max')
    // console.log(min, 'min')

    // console.log(half, double, triple, max, min, 'Statistic Zodiac')
    setHalfNumber(half)
    setDoubleNumber(double)
    setTripleNumber(triple)
    setHotNumber(max)
    setColdNumber(min)
  }

  useEffect(() => {
    if (lottoName === '' || lottoName === undefined) {
      return
    }

    if (isLastest === false) {
      if (storeData === 'empty') {
        // console.log('Get data from api')
        API.get('lotto', `/results?limit=480&status=rewarded&lottoGroup=${lottoGroup}&drawDateStr=${drawDateStr}`)
          .then((res) => {
            const lottoData = res.detail
            // console.log(lottoData, 'lottoData')
            setLottoData(lottoData)
            getHotAndCold(lottoData)
          })
          .catch((err) => console.error(err))
      } else {
        const dataStore = storeData
        setLottoData(dataStore)
        getHotAndCold(dataStore)
      }
    } else {
      // console.log('Get data from filter')
      setStoreData(lottoData)
      const lastestLotto = lottoData.slice(0, 10)
      setLottoData(lastestLotto)
      getHotAndCold(lastestLotto)
    }

    return () => {
      // console.log('return in useEffect')
    }
  }, [lottoName, isLastest])

  // const dateNow = (date) => {
  //   // console.log(date, 'date')
  //   if (date !== undefined) {
  //     const dateString = date.substr(3, 2) + '/' + date.substr(0, 2) + '/' + date.substr(6, 4)
  //     // console.log(dateString)
  //     return new Date(dateString).toLocaleDateString('th-TH', {
  //       year: '2-digit',
  //       month: 'short',
  //       day: 'numeric',
  //       weekday: 'long',
  //     })
  //   }
  //   return new Date().toLocaleDateString('th-TH', {
  //     year: '2-digit',
  //     month: 'short',
  //     day: 'numeric',
  //     weekday: 'long',
  //   })
  // }

  // const lottoRound =
  //   lottoData.lottoGroup === 'หวยยี่กี'
  //     ? 'Y' + lottoData.lottoName.split(' ')[1]
  //     : lottoData.lottoGroup === 'หวยป้าตุ๊ก'
  //     ? 'P' + lottoData.lottoName.split(' ')[1]
  //     : lottoData.lottoName.slice(-2)

  return showModal.isShow && showModal.currentModal === 'สถิตินักษัตร' ? (
    <section className={`buy-lotto__modal active`}>
      <div className={`buy-lotto__modal-box active`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          <div className='buy-lotto__lotto-number text-xl mr-3 text-white bold strong'>
            <div style={{ fontWeight: 'bold' }}>
              นักษัตร {lottoData.length} รอบ{isLastest ? 'ล่าสุด' : 'วันนี้'}
            </div>
          </div>
          <button
            className='danger'
            onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={() => {
              setIsLastest(!isLastest)
            }}
            style={{
              color: 'white',
              background: 'linear-gradient(to right, #C4A85A 0%, #794D1F 50%, #887936 100%)',
              fontWeight: 'bold',
              padding: '0 10px',
              borderRadius: '10px',
              border: '2px ridge #c8b785',
            }}
          >
            {isLastest ? 'ดูของวันนี้ทั้งหมด' : 'ดู 10 รอบล่าสุด'}
          </button>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-hotNumber'>
            <span className='home__detailsBox-purchaseDetails-amount-title'>ปีดวงเฮง</span>
          </div>
          {hotNumber[0] ? (
            <div className='home__detailsBox-purchaseDetails-amount'>
              {/* <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}> */}
              <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}>
                {targetStar(hotNumber[0])}
              </span>

              {/* </span> */}
            </div>
          ) : null}
          {hotNumber[0] ? (
            <div className='home__detailsBox-purchaseDetails-amount'>
              {/* <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}> */}
              <img
                className='zodiac-result'
                style={{ maxHeight: '50px' }}
                src={require(`../../assets/zodiac/mini-star/${targetStar(hotNumber[0])}.jpg`)}
                alt='zodiacResult'
              />

              {/* </span> */}
            </div>
          ) : null}
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}>
              {hotNumber[1] || 0} ครั้ง
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-coldNumber'>
            <span className='home__detailsBox-purchaseDetails-amount-title'>ปีดวงตก</span>
          </div>
          {coldNumber[0] ? (
            <div className='home__detailsBox-purchaseDetails-amount'>
              {/* <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}> */}
              <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'blue' }}>
                {targetStar(coldNumber[0])}
              </span>

              {/* </span> */}
            </div>
          ) : null}
          {coldNumber[0] ? (
            <div className='home__detailsBox-purchaseDetails-amount'>
              {/* <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}> */}
              <img
                className='zodiac-result'
                style={{ maxHeight: '50px' }}
                src={require(`../../assets/zodiac/mini-star/${targetStar(coldNumber[0])}.jpg`)}
                alt='zodiacResult'
              />

              {/* </span> */}
            </div>
          ) : null}
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'blue' }}>
              {coldNumber[1] || 0} ครั้ง
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-double' style={{ color: '#71291D' }}>
            <span className='home__detailsBox-purchaseDetails-amount-title'>สิบห้าเท่า</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#71291D' }}></span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#71291D' }}>
              {halfNumber} ครั้ง
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-double' style={{ color: '#808080' }}>
            <span className='home__detailsBox-purchaseDetails-amount-title'>ยี่สิบเท่า</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#808080' }}></span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#808080' }}>
              {doubleNumber} ครั้ง
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-double' style={{ color: '#D4AF37' }}>
            <span className='home__detailsBox-purchaseDetails-amount-title'> สามสิบเท่า</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#D4AF37' }}></span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: '#D4AF37' }}>
              {tripleNumber} ครั้ง
            </span>
          </div>
        </div>

        {lottoData.map((lotto, index) => {
          return (
            <div
              className='home__detailsBox-purchaseDetails'
              key={index}
              style={
                lotto.results['2B'] === hotNumber[0]
                  ? { borderBottom: '2px solid #dddddd', background: '#FFCCCB' }
                  : lotto.results['2B'] === coldNumber[0]
                  ? { borderBottom: '2px solid #dddddd', background: 'lightblue' }
                  : { borderBottom: '2px solid #dddddd', color: '' }
              }
            >
              <div className='home__detailsBox-purchaseDetails-detail'>
                <span
                  className='home__detailsBox-purchaseDetails-amount-title'
                  style={{ fontSize: '15px', fontWeight: 'bold' }}
                >
                  {'รอบที่ ' + lotto.lottoName.split(' ')[2]}
                  {/*{new Date(`20${lotto.drawDateStr.split('/').reverse().join(',')}`).toLocaleDateString('th-TH', {*/}
                  {/*  year: '2-digit',*/}
                  {/*  month: 'short',*/}
                  {/*  day: 'numeric',*/}
                  {/*  weekday: 'long',*/}
                  {/*}) || ''}*/}
                </span>
              </div>
              {/* {lotto.results['3A'].split('')[0] === lotto.results['3A'].split('')[1] &&
              lotto.results['3A'].split('')[0] === lotto.results['3A'].split('')[2] ? (
                <div className='home__detailsBox-purchaseDetails-amount'>
                  <span style={{ color: 'gold' }}>{lotto.results['3A']}</span>
                </div>
              ) : (
                <div className='home__detailsBox-purchaseDetails-amount'>
                  <span>{lotto.results['3A'].split('')[0]}</span>
                  <span
                    style={
                      lotto.results['3A'].split('')[1] === lotto.results['3A'].split('')[2]
                        ? { color: 'green' }
                        : lotto.results['3A'].split('')[1] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['3A'].split('')[1] === coldNumber[0]
                        ? { color: 'blue' }
                        : { color: '' }
                    }
                  >
                    {lotto.results['3A'].split('')[1]}
                  </span>
                  <span
                    style={
                      lotto.results['3A'].split('')[1] === lotto.results['3A'].split('')[2]
                        ? { color: 'green' }
                        : lotto.results['3A'].split('')[2] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['3A'].split('')[2] === coldNumber[0]
                        ? { color: 'blue' }
                        : { color: '' }
                    }
                  >
                    {lotto.results['3A'].split('')[2]}
                  </span>
                </div>
              )} */}
              <div className='home__detailsBox-purchaseDetails-amount'>
                {/* <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}> */}
                <span className='home__detailsBox-purchaseDetails-amount-title'>{targetStar(lotto.results['2B'])}</span>

                {/* </span> */}
              </div>

              <div
                className='home__detailsBox-purchaseDetails-amount'
              >
                <img
                  className='zodiac-result'
                  style={{ maxHeight: '50px' }}
                  src={require(`../../assets/zodiac/mini-star/${targetStar(lotto.results['2B'])}.jpg`)}
                  alt='zodiacResult'
                />
                <span
                  style={
                    !lotto.results['3F1'] && !lotto.results['3B1'] && !lotto.results['2A']
                      ? { color: '#EFEFEF' }
                      : lotto.results['2B'] === hotNumber[0]
                      ? { color: 'red' }
                      : lotto.results['2B'] === coldNumber[0]
                      ? { color: 'blue' }
                      : { color: '' }
                  }
                >
                  &nbsp;&nbsp;&nbsp;
                  {lotto.results['3F1'] ? 'X30' : lotto.results['3B1'] ? 'X15' : lotto.results['2A'] ? 'X20' : 'X10'}
                </span>
                {/* <span
                  style={
                    lotto.results['2B'].split('')[0] === lotto.results['2B'].split('')[1]
                      ? { color: 'green' }
                      : lotto.results['2B'].split('')[1] === hotNumber[0]
                      ? { color: 'red' }
                      : lotto.results['2B'].split('')[1] === coldNumber[0]
                      ? { color: 'blue' }
                      : { color: '' }
                  }
                >
                  {lotto.results['2B'].split('')[1]}
                </span> */}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  ) : null
}

ZodiacModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
}
