import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { API, Auth } from 'aws-amplify'
import Swal from 'sweetalert2'

import { getStorage } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
import { composePhoneNumber } from '../../utils/phoneNumber'
import { getCognitoProvider } from '../../config/cognito'
import Input from '../../components/Form/Input'
import { FacebookIcon } from '../../components/Icon/Facebook'
import { LineIcon } from '../../components/Icon/Line'

export default function Login() {
  const cognitoProvider = { provider: getCognitoProvider() }
  const auth = useSelector(getAuth)
  const dispatch = useDispatch()
  const history = useHistory()

  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    userConfirmPassword: '',
  })

  const handleLogin = (e) => {
    e.preventDefault()
    if (newUser.username.length !== 10 || newUser.password.length < 8) {
      return
    }
    const swFire = async (options) => {
      await Swal.fire({
        ...options,
        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'ลุ้นฟอร์จูนเนอร์',
        showCloseButton: true,
      }).then((result) => {
        // history.replace('/lottos/68152')
        if (result.isConfirmed) {
          window.open('https://lin.ee/nxwonlh')
        }
      })
    }

    const storage = getStorage()

    try {
      const newUsername = composePhoneNumber('+66', newUser.username)
      Auth.signIn(newUsername, newUser.password).then((user) => {
        const testUser = API.post('user', `/test-otp`, { isVerify: true, countdown: 'test', number: 'test' })
       /*  console.log(testUser, 'testUserrrrr in Login Pages') */
        swFire({
          html: 'ยินดีต้อนรับเข้าสู่หวยยอดธง.com',
          // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/valentine-iphone.jpg`,
          imageUrl: require('../../assets/banner/congrat.jpg'),
        })
        dispatch(setAuth({ user }))
      })
      history.replace('/callback')
    } catch (e) {
      if (e.message === 'Incorrect username or password.') {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: e.message || '',
        })
      }
    }
  }

  const handleFacebookLogin = async () => {
    await Auth.federatedSignIn({ provider: 'Facebook' })
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLineLogin = async () => {
    await Auth.federatedSignIn(cognitoProvider)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  if (auth && auth.username) {
    return <Redirect to='/' />
  }

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    if (inputName === 'username') {
      const newValue = value.replace(/[^0-9]/g, '')
      setNewUser({ ...newUser, [inputName]: newValue })
      return
    }

    setNewUser({ ...newUser, [inputName]: value })
  }

  return (
    <div className='lg:w-1/2 md:w-1/2 sm:mx-auto mt-20 m-5'>
      <div className='text-xl pt-3 pb-6'>
        {/*<form className='form__wrapper' onSubmit={handleLogin}>*/}
        {/*  <div className='text-white'>เบอร์โทรศัพท์มือถือ</div>*/}
        {/*  <div className='form__item-login'>*/}
        {/*    <Input*/}
        {/*      onChange={(e) => _onChangeForm(e, 'username')}*/}
        {/*      placeholder='กรอกเบอร์โทรศัพท์มือถือ'*/}
        {/*      type='text'*/}
        {/*      id='login-username'*/}
        {/*      value={newUser.username}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div className='text-white'>รหัสผ่าน</div>*/}
        {/*  <div className='form__item-login p'>*/}
        {/*    <Input*/}
        {/*      onChange={(e) => _onChangeForm(e, 'password')}*/}
        {/*      placeholder='กรอกรหัสผ่านของท่าน'*/}
        {/*      type='password'*/}
        {/*      id='login-password'*/}
        {/*      autocomplete='login-password'*/}
        {/*      value={newUser.password}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div className='flex justify-center pt-3'>*/}
        {/*    <button*/}
        {/*      className={`bg-white text-xl px-6 py-2 font-bold text-black rounded-full ${*/}
        {/*        newUser.username.length !== 10 || newUser.password.length < 8 ? 'disabled' : ''*/}
        {/*      }`}*/}
        {/*      type='submit'*/}
        {/*    >*/}
        {/*      เข้าสู่ระบบ*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</form>*/}
      </div>

      <div className='home__banking-action'>
        <div style={{ marginBottom: '10px', marginTop: '0' }}>
          {/* <button onClick={handleFacebookLogin} className='btn-bevel text-xl px-1 py-2 font-bold text-white __facebook'>
            <FacebookIcon /> <span>เข้าสู่ระบบด้วยเฟสบุ๊ค</span>
          </button> */}
          {/*<span>หรือ</span>*/}
          <button onClick={handleLineLogin} className='btn-bevel text-xl px-1 py-2 font-bold text-white __line'>
            <LineIcon /> <span>เข้าสู่ระบบด้วยไลน์</span>
          </button>
        </div>
      </div>
    </div>
  )
}
