import { colorCode } from './color'

export const lottoType = {
  '2A': 'สองตัวบน',
  '2B': 'สองตัวล่าง',
  '3A': 'สามตัวบน',
  '3R': 'สามตัวโต๊ด',
  '3F': 'สามตัวหน้า',
  '3B': 'สามตัวล่าง',
  SA: 'เลขวิ่งบน',
  SB: 'เลขวิ่งล่าง',
}

export const getReceiptStatusText = (status = '') => {
  if (status === 'created') {
    return 'สร้างแล้ว ยังไม่ได้ยืนยัน'
  }
  if (status === 'confirm') {
    return 'รอออกผล'
  }
  if (status === 'cancelled') {
    return 'ยกเลิกแล้ว'
  }
  if (status === 'rewarded') {
    return 'ไม่ถูกรางวัล'
  }
  if (status === 'rejected') {
    return 'ถูกปฏิเสธ'
  }
  if (status === 'error') {
    return 'ล้มเหลว'
  }
  if (status === 'timeout') {
    return 'หมดเวลา'
  }

  return 'ไม่ทราบสถานะ'
}

export const getReceiptTypeText = (type = '') => {
  const typeText = lottoType[type] || 'ไม่พบประเภทหวย'
  return typeText
}

export const getReceiptStatusBackgroundColor = (status = '') => {
  if (status === 'created') {
    return colorCode.lottoGreen
  }
  if (status === 'confirm') {
    return colorCode.lottoDarkGreen
  }
  if (status === 'cancelled') {
    return colorCode.lottoGray
  }
  if (status === 'rewarded') {
    return colorCode.lottoRed
  }
  if (status === 'rejected') {
    return colorCode.lottoGray
  }
  if (status === 'error') {
    return colorCode.lottoGray
  }
  if (status === 'timeout') {
    return colorCode.lottoGray
  }
}
