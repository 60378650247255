import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import SignUp from '../../components/SignUp/SignUp'
import { getCognitoProvider } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
import Swal from 'sweetalert2'
import { composePhoneNumber } from '../../utils/phoneNumber'

function Marketing() {
  const history = useHistory()
  const dispatch = useDispatch()
  const cognitoProvider = { provider: getCognitoProvider() }
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    userConfirmPassword: '',
  })
  const [confirmPassword, setConfirmPassword] = useState(false)
  const auth = useSelector(getAuth)
  const handleLogin = async (e, type) => {
    e.preventDefault()
    // console.log(window.location.hash)
    if (type === 'facebook') {
      await Auth.federatedSignIn({ provider: 'Facebook' }).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else if (type === 'line') {
      await Auth.federatedSignIn(cognitoProvider).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else {
      // if (newUser.username.length !== 10 || newUser.password.length < 8) {
      //   return
      // }

      try {
        // const newUsername = newUser.username
        const newUsername = composePhoneNumber('+66', newUser.username)
        await Auth.signIn(newUsername, newUser.password).then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
            console.log(requiredAttributes)
            const newPassword = newUser.password
            Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword // the new password
            )
              .then((res) => {
                // at this time the user is logged in if no MFA required
                console.log(res)
              })
              .catch((e) => {
                console.log(e)
              })
          }
          console.log(user, 'user signIn')
        })
        // console.log(testUser, 'testUserrrrr Home Container')
        history.replace('/callback')
      } catch (e) {
        console.log(e, 'e message')
        if (e.message === 'Incorrect username or password.') {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          })
        } else if (e.message === 'User is not confirmed.') {
          setShowModal({ isShow: true, currentModal: 'verify' })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: e.message || '',
          })
        }
      }
    }
  }


  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const status = params.get('status')
    if (status) {
      if (status === 'success') {
        history.replace('/lottos')
      }
      if (status === 'failed') {
        history.replace('/deposit')
        Swal.fire({
          icon: 'error',
          title: 'การฝากเงินไม่สำเร็จ',
        })
      }
    } else {
      const code = params.get('o')
      localStorage.removeItem('marketingCode')
      setShowModal({ isShow: true, currentModal: 'register' })
      localStorage.setItem('marketingCode', code)
      if (auth.username) {
        history.replace('/')
      } else {
        setShowModal(() => ({ isShow: true, currentModal: 'register' }))
      }
    }
    // Auth.currentAuthenticatedUser()
    //   .then(() => history.replace('/'))
    //   .catch(() =>
    //     Auth.federatedSignIn({
    //       customState: code,
    //     })
    //   )
  }, [])

  return (
    <SignUp
      showModal={showModal}
      setShowModal={setShowModal}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      handleLogin={handleLogin}
      setNewUser={setNewUser}
      newUser={newUser}
    />)
}

export default Marketing
