import React, { useState, useEffect } from 'react'
// import moment from 'moment'
// import _ from 'lodash'
// import _isEmpty from 'lodash/isEmpty'
// import _some from 'lodash/some'
// import Swal from 'sweetalert2'
import { API } from 'aws-amplify'

// import Flare1 from '../../components/Flare/Flare1'
// import Flare2 from '../../components/Flare/Flare2'
import { STOCK_LIST } from '../../utils/stockList'
// // import paatookFlag from '../../assets/flags/paatook-flag.png'
// import shine from '../../assets/shine.png'
import iconmonstrArrow65 from '../../assets/svg/iconmonstr-arrow-65.svg'
import useComponentVisible from './useComponentVisible'
import { indexOf } from 'lodash'


/*API FORMAT
  result: {
    lotto2a: "56"
    lotto2b: "32"
    lotto3a: "456"
    lotto3b: ""
    lotto3f: ""
    lotto6: ""
  }
*/

export default ({ _id, title = 'หวย XXX', src = null, result = {}, createdAt }) => {
  // title = lottoGroup
  // console.log(title, result, 'result')
  const { has2a = false, has2b = true, has3a = true, has3b = false, has3f = false, has6 = false } =
  {
    หวยรัฐบาลไทย: {
      has2a: false,
      has2b: true,
      has3a: false,
      has3b: true,
      has3f: true,
      has6: true,
    },
    หวยออมสิน: {
      has2a: false,
      has2b: true,
      has3a: true,
      has3b: false,
      has3f: false,
      has6: true,
    },
    'หวยธกส.': {
      has2a: false,
      has2b: true,
      has3a: true,
      has3b: false,
      has3f: false,
      has6: true,
    },
  }[title] || {}
  const [isCollapse, setIsCollapse] = useState(true)
  const [lottoData, setLottoData] = useState([])
  const [lottoName, setLottoName] = useState('')
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true)

  useEffect(() => {
    if (isComponentVisible === false) {
      setIsCollapse(true)
    }
    setIsComponentVisible(true)
  }, [isComponentVisible])
  useEffect(() => {
    if (lottoName === '') {
      return
    }
    API.get('lotto', `/results?limit=10&status=rewarded&lottoName=${lottoName}`)
      .then((res) => {
        const lottoData = res.detail
        console.log(lottoData, 'lottoData')
        setLottoData(lottoData)
      })
      .catch((err) => console.error(err))
    return () => {
      // console.log('return in useEffect')
    }
  }, [lottoName])
  const dateNow = (date) => {
    // console.log(date, 'date')
    if (date !== undefined) {
      const dateString = date.substr(3, 2) + '/' + date.substr(0, 2) + '/' + date.substr(6, 4)
      // console.log(dateString)
      return new Date(dateString).toLocaleDateString('th-TH', {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
      })
    }
    return new Date().toLocaleDateString('th-TH', {
      year: '2-digit',
      month: 'short',
      day: 'numeric',
      weekday: 'long',
    })
  }

  return (
    // <div className={`home__lotto ${title === 'หวย XXX' ? 'hidden' : ''}`}>

      <table className='home_table'>
        <tbody>
          <tr>
          <div className='flagimage'>
          <img
          src={
            title.substring(0, 5) === 'ยี่กี'
              ? require('../../assets/lotto-flags/yodtong.png')
              : STOCK_LIST.find((o) => o.title === title)?.src || require('../../assets/lotto-flags/yodtong.png')
          }
          alt='flags'
        />
        </div>
            <td>{title}</td>
        {has6 && (
          <div className='home__lotto-box'>
            <div className='text-xl'>รางวัลที่ 1</div>
            {/*<div>{result.lotto6 ? result.lotto6.replace(' ', ',') : 'XXXXXX'}</div>*/}
            <div>
              {title === 'หวยธกส.' || title === 'หวยรัฐบาลไทย'
                ? result.lotto6
                  ? result.lotto6
                  : 'XXXXXX'
                : title === 'หวยออมสิน'
                  ? result.lotto6
                    ? result.lotto6.replace(' ', ',')
                    : 'XXXXXX,XXXXXX'
                  : 'XXXXXX'}
            </div>
          </div>
        )}
          {has3f && (<td>{result.lotto3f ? result.lotto3f.replace(' ', ',') : 'XXX'}</td>)}
          {has3a && (<td>{result?.lotto3a || 'XXX'}</td>)}
          {has3b && (<td>{result.lotto3b ? result.lotto3b.replace(' ', ',') : 'XXX'}</td>)}
          {has2a && (<td>{result?.lotto2a || 'XX'}</td>)}
          {has2b && (<td>{result?.lotto2b || 'XX'}</td>)}
          </tr>
        </tbody>
      </table>

  )
}
