import PropTypes from 'prop-types'
import React from 'react'
import noop from 'lodash/noop'

import './ButtonGroup.scss'

const disabledButtonStyle = { pointerEvents: 'none', backgroundColor: '  #A9A9A9', borderRadius: '0px' }

const ButtonGroup = ({
  firstText,
  secondText,
  thirdText,
  firstTextStyle,
  secondTextStyle,
  containerClass,
  withBorder,
  disabled,
  disabledFirst,
  disabledSecond,
  disabledThird,
  onClickFirstButton,
  onClickSecondButton,
  onClickThirdButton,
  activeType,
}) => {
  const border = withBorder ? { border: '2px solid white' } : {}

  const _onClickFirstButton = (e) => {
    e.preventDefault()
    if (typeof onClickFirstButton === 'function') {
      onClickFirstButton(e)
    }
  }

  const _onClickSecondButton = (e) => {
    e.preventDefault()
    if (typeof onClickSecondButton === 'function') {
      onClickSecondButton(e)
    }
  }

  const _onClickThirdButton = (e) => {
    e.preventDefault()
    if (typeof onClickThirdButton === 'function') {
      onClickThirdButton(e)
    }
  }

  const firstTextStyleFinal = disabled ? { ...firstTextStyle, ...disabledButtonStyle } : firstTextStyle
  const secondTextStyleFinal = disabled ? { ...secondTextStyle, ...disabledButtonStyle } : secondTextStyle

  const firstButtonClass =
    (activeType.length <= 0 ? 'active' : '') + (disabled || disabledFirst ? ' ' + 'disabled' : '')
  const secondButtonClass =
    (activeType === 'deposit' ? 'active' : '') + (disabled || disabledSecond ? ' ' + 'disabled' : '')
  const thirdButtonClass =
    (activeType === 'withdraw' ? 'active' : '') + (disabled || disabledThird ? ' ' + 'disabled' : '')

  return (
    <div style={border} className={`doubleButton__container ${containerClass ? containerClass : ''}`}>
      <button className={firstButtonClass} onClick={_onClickFirstButton} style={firstTextStyleFinal}>
        {firstText}
      </button>
      <button className={secondButtonClass} onClick={_onClickSecondButton} style={secondTextStyleFinal}>
        {secondText}
      </button>
      {thirdText && (
        <button className={thirdButtonClass} onClick={_onClickThirdButton}>
          {thirdText}
        </button>
      )}
    </div>
  )
}

ButtonGroup.propTypes = {
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  firstTextStyle: PropTypes.object,
  secondTextStyle: PropTypes.object,
  thirdText: PropTypes.string,
  containerClass: PropTypes.string,
  withBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledFirst: PropTypes.bool,
  disabledSecond: PropTypes.bool,
  disabledThird: PropTypes.bool,
  onClickFirstButton: PropTypes.func,
  onClickSecondButton: PropTypes.func,
  onClickThirdButton: PropTypes.func,
  activeType: PropTypes.string,
}

ButtonGroup.defaultProps = {
  firstText: 'ยกเลิก',
  secondText: 'ถอนเงิน',
  withBorder: false,
  disabled: false,
  activeType: '',
  onClickFirstButton: noop,
  onClickSecondButton: noop,
  onClickThirdButton: noop,
}

export default ButtonGroup
