import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API, Auth } from 'aws-amplify'
import _get from 'lodash/get'
import _map from 'lodash/map'
import Swal from 'sweetalert2'
import Modal from 'react-modal'
import _mapKeys from 'lodash/mapKeys'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'
import AliceCarousel from 'react-alice-carousel'

import { getAuth } from '../../redux/selectors/auth'
import BoxWhite from '../../components/Box/BoxWhite'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import { mapBankCodeToBankName } from '../../utils/mapBankCodeToBankName'
import Button from '../../components/Button/Button'
import { composePhoneNumber } from '../../utils/phoneNumber'
import Input from '../../components/Form/Input'
import lineIcon from '../../assets/icon/line-icon.png'
// import phoneIcon from '../../assets/icon/phone-icon.png'
import magnifyingGlass from '../../assets/bank/magnifying-glass.svg'
import 'react-alice-carousel/lib/alice-carousel.css'
import './AddBankAccount.scss'
// import congrat from '../../assets/banner/congrat.jpg'
// import newPayRate from '../../assets/banner/newPayRate.jpg'
// import maxRate from '../../assets/banner/maxRate.jpg'
// import payRate from '../../assets/banner/payRate.jpg'
// import mobile01 from '../../assets/mobile01.jpg'
// import pc01 from '../../assets/pc01.jpg'
// import mobile02 from '../../assets/mobile02.jpg'
// import pc02 from '../../assets/pc02.jpg'
// import mobile03 from '../../assets/mobile03.jpg'
// import pc03 from '../../assets/pc03.jpg'
// import mobile04 from '../../assets/mobile04.jpg'
// import pc04 from '../../assets/pc04.jpg'
// import mobile05 from '../../assets/mobile05.jpg'
// import pc05 from '../../assets/pc05.jpg'
import girl1 from '../../assets/yodtongAddBank/one.jpg'
import girl2 from '../../assets/yodtongAddBank/two.jpg'
import girl3 from '../../assets/yodtongAddBank/three.jpg'
import girl4 from '../../assets/yodtongAddBank/four.jpg'
import girl5 from '../../assets/yodtongGirl/one.jpg'

const imagesMobile = [girl1, girl2, girl3, girl4, girl5]

const SlideshowMobile = () => {
  return (
    <div className='home all' style={{ marginBottom: '10px', margin: 'auto' }}>
      {/*<div className='home__marquee'>*/}
      {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
      {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
      {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
      {/*    เว็บดีๆ จงบอกเพื่อน*/}
      {/*  </Marquee>*/}
      {/*</div>*/}
      <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={2000} mouseTracking>
        {imagesMobile.map((each, index) => (
          <img key={index} className='home__sliderimg' src={each} alt='slide'/>
        ))}
      </AliceCarousel>
    </div>
  )
}

//
// const SlideshowPc = () => {
//   return (
//     <div className='home desktop' style={{ marginBottom: '10px', margin: 'auto' }}>
//       {/*<div className='home__marquee'>*/}
//       {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
//       {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
//       {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
//       {/*    เว็บดีๆ จงบอกเพื่อน*/}
//       {/*  </Marquee>*/}
//       {/*</div>*/}
//       <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
//         {imagesMobile.map((each, index) => (
//           <img key={index} className='home__sliderimg' src={each} alt='slide' />
//         ))}
//       </AliceCarousel>
//     </div>
//   )
// }

const AddBankAccount = () => {
  const auth = useSelector(getAuth)
  const username = auth.username
  const [formState, setFormState] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isFormChange, setIsFormChange] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [playerBankAccount, setPlayerBankAccount] = useState(null)

  const [fetchedUserData, setFetchedUserData] = useState({})
  const [formData, setFormData] = useState({
    tel: '',
  })
  const [isPhoneRegister, setIsPhoneRegister] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await Auth.currentAuthenticatedUser()
      console.log(user, 'user')
      setFetchedUserData(user)
      if (user.attributes.phone_number) {
        setIsPhoneRegister(true)
      }
      // setIsPhoneRegister(true)
    }
    fetchUserData()
  }, [])

  useEffect(() => {
    setFormData({
      tel: _get(fetchedUserData, 'attributes.phone_number', ''),
    })
  }, [fetchedUserData])

  const mapToCognitoAttributes = {
    tel: 'phone_number',
  }

  const _handleChangeForm = ({ target }) => {
    const profileData = { ...formData }
    profileData[target.name] = target.value
    setFormData(profileData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const payload = _mapKeys(formData, (value, key) => {
        return mapToCognitoAttributes[key] || key
      })
      Object.keys(payload).forEach((key) => {
        if (key === null || key === 'null' || typeof key === 'undefined') {
          delete payload[key]
        }
      })
      payload.phone_number = composePhoneNumber('+66', payload.phone_number)
      if (payload.phone_number.length !== 12) {
        Swal.fire({
          icon: 'error',
          title: 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้องครับ',
        })
        return
      }
      const finalPayload = _omitBy(payload, _isNil)

      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, finalPayload)

      setIsPhoneRegister(true)
    } catch (error) {
      console.error('error profile handleSubmit>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  useEffect(() => {
    fetchActiveBankAccount()
  }, [username])

  const validateAll = () => {
    if (
      !formState.accountName ||
      !formState.accountNumber ||
      !formState.accountNumberConfirm ||
      !formState.bankCode ||
      !formState.bankName
    ) {
      return false
    }

    if (formState.accountNumber !== formState.accountNumberConfirm) {
      return false
    }

    return true
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '0',
      right: '0',
      bottom: 'auto',
      transform: 'translateY(-50%)',
      maxWidth: '550px',
      margin: '0 auto',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      overflow: 'inherit',
    },
  }

  const _onClickSecondButton = async () => {
    if (!username) {
      return Swal.fire({
        icon: 'error',
        title: 'กรุณาเข้าสู่ระบบ',
      })
    }

    const isValidForm = validateAll()
    if (!isValidForm) {
      return Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      })
    }

    const isChangeBankAccount = !!playerBankAccount
    if (isChangeBankAccount) {
      const isConfirmedChangeBankAccount = await Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text: 'หลังจากยืนยันการเปลี่ยนบัญชี ต้องติดต่อแอดมินเพื่อแสดงหลักฐาน ท่านแน่ใจหรือไม่',
        confirmButtonColor: '#B6964E',
        confirmButtonText: 'ยืนยัน',
        showCancelButton: true,
        cancelButtonColor: ' #A9A9A9',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.value) {
          return true
        }
        return false
      })

      if (!isConfirmedChangeBankAccount) {
        return
      }
    }

    setIsLoading(true)

    const payload = {
      accountName: formState.accountName,
      bankCode: formState.bankCode,
      bankName: mapBankCodeToBankName[formState.bankCode],
      accountNumber: formState.accountNumber,
    }
    API.post('playerBankAccount', `/players/${username}/request`, {
      body: payload,
    })
      .then((res) => {
        const resBankAccount = _get(res, 'detail', null)
        if (resBankAccount) {
          if (isChangeBankAccount) {
            setIsLoading(false)
            setPlayerBankAccount(resBankAccount)
            return Swal.fire({
              icon: 'success',
              title: 'ส่งคำสั่งเปลี่ยนบัญชีธนาคารสำเร็จ กรุณาติดต่อแอดมินเพื่อแสดงหลักฐาน',
              text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
              confirmButtonText: 'ติดต่อแอดมิน',
            }).then(function(isConfirm) {
              if (isConfirm) {
                setContactModal(!contactModal)
              }
            })
          }
          setIsLoading(false)
          setPlayerBankAccount(resBankAccount)
          return Swal.fire({
            icon: 'success',
            title: 'เพิ่มบัญชีธนาคารสำเร็จ',
            text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
          })
        } else {
          throw new Error('ระบบผิดพลาด ไม่สามารถเพิ่มบัญชีธนาคารได้ กรุณาติดต่อเจ้าหน้าที่')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('error add bank account ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'กรุณาติดต่อเจ้าหน้าที่',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _onClickFirstButton = () => {
    setFormState({})
  }

  const _onChangeForm = (e, inputName) => {
    setIsFormChange(true)
    const value = e.target.value

    if (inputName === 'bankCode') {
      setFormState({ ...formState, [inputName]: value, bankName: mapBankCodeToBankName[value] })
      return
    }

    if (inputName === 'accountNumber' || inputName === 'accountNumberConfirm') {
      const newValue = value.replace(/[^0-9]/g, '')
      setFormState({ ...formState, [inputName]: newValue })
      return
    }

    setFormState({ ...formState, [inputName]: value })
  }

  const fetchActiveBankAccount = async () => {
    setIsLoading(true)
    const result = await API.get('playerBankAccount', `/players/${username}/bank-account`)
      .then((res) => {
        const detail = _get(res, 'detail', null)
        const code = _get(res, 'code', null)
        if (code === 0) {
          setPlayerBankAccount(detail)
          setFormState({ ...detail, accountNumberConfirm: detail.accountNumber })
          setIsLoading(false)

          return detail
        }
      })
      .catch((err) => {
        console.log('error check bank account ======')
        console.error(err)
        setPlayerBankAccount(null)
        setIsLoading(false)
        // Swal.fire({
        //   icon: 'error',
        //   title: 'เกิดข้อผิดพลาด',
        //   text: err.message,
        // })

      })
      .finally(() => {
        setIsLoading(false)
      })

    return result
  }

  const fetchPendingBankAccount = async () => {
    setIsLoading(true)
    const result = await API.get('playerBankAccount', `/players/${username}/request`)
      .then((res) => {
        const detail = _get(res, 'detail', null)
        const code = _get(res, 'code', null)
        if (code === 0) {
          setIsLoading(false)
          Swal.fire({
            icon: 'info',
            title: 'บัญชีธนาคารของคุณกำลังรอการยืนยันจากเจ้าหน้าที่',
            text: `ชื่อบัญชี: ${detail.accountName}, เลขบัญชี: ${detail.accountNumber})`,
          })
          return true
        } else {
          throw new Error('ไม่พบบัญชีธนาคารของคุณ กรุณาติดต่อเจ้าหน้าที่')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('error check bank account ======')
        console.log(err.message)

        let message = ''
        if (err.message === 'Request failed with status code 400') {
          message = 'ไม่พบบัญชีธนาคารของคุณ'
        }

        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: message || 'กรุณาติดต่อเจ้าหน้าที่',
        })

      })
      .finally(() => {
        setIsLoading(false)
      })

    return result
  }

  const _onClickCheckBankStatus = async () => {
    if (!username) {
      return Swal.fire({
        icon: 'error',
        title: 'กรุณาเข้าสู่ระบบ',
      })
    }

    // Check "active" bank account
    const activeBankAccount = await fetchActiveBankAccount()
    if (activeBankAccount) {
      // no need to check further more for pending bank account
      Swal.fire({
        icon: 'success',
        title: 'บัญชีธนาคารของคุณได้รับการยืนยันแล้ว',
        text: `ชื่อบัญชี: ${activeBankAccount.accountName}, เลขบัญชี: ${activeBankAccount.accountNumber})`,
      })
      return
    }

    // Check "pending" bank account
    await fetchPendingBankAccount()
  }

  return (
    <section className='newAccount newAccount__container mt-20 text-black'>
      <SlideshowMobile/>
      {/*<SlideshowPc />*/}
      {/*<BoxWhite containerClass='newAccount__add'>*/}
      {/*  <p className='newAccount__add-icon'>*/}
      {/*    เพิ่มบัญชีธนาคาร <span className='remark'>(เติมเครดิตครั้งแรก และเพิ่มบัญชีธนาคารอื่นๆ)</span>*/}
      {/*  </p>*/}
      {/*  <button*/}
      {/*    className='newAccount__button'*/}
      {/*    style={{ color: 'white', borderRadius: '0px', background: '#C5C95B', marginBottom: '10px' }}*/}
      {/*    onClick={_onClickCheckBankStatus}*/}
      {/*  >*/}
      {/*    <img src={magnifyingGlass} alt='icon' style={{ opacity: '0.5' }} />*/}
      {/*    <span>เช็คสถานะบัญชี</span>*/}
      {/*  </button>*/}
      {/*</BoxWhite>*/}
      {isPhoneRegister ? (
        <BoxWhite containerClass='newAccount__form'>
          <form className='form__wrapper'>
            <div className='form__setWrapper'>
              <div className='form__itemBank'>
                <label className='form__item_bank' htmlFor='bank-selection'>
                  {formState.bankName ? 'ธนาคารของท่าน' : 'เลือกธนาคาร'}
                </label>
                <div className="bankAndLogo">
                  <select
                    name='bank'
                    id='bank-selection'
                    value={formState.bankCode || ''}
                    onChange={(e) => _onChangeForm(e, 'bankCode')}
                  >
                    <option value=''>{formState.bankName ? formState.bankName : 'กรุณาเลือกธนาคาร'}</option>
                    {_map(mapBankCodeToBankName, (bankName, bankCode) => {
                      return <option value={bankCode}>{bankName}</option>
                    })}
                  </select>
                  {formState.bankCode && formState.bankCode !== '' ?
                    <img src={require(`../../assets/bank-logo/${formState.bankCode}.png`)}
                         alt='bank-user-logo'/> : null}
                </div>
              </div>
              <div className='form__item'>
                <label className='form__item_accountName' htmlFor='account-name'>
                  ชื่อบัญชี
                </label>
                <input
                  type='text'
                  name='account-name'
                  id='account-name'
                  placeholder='ชื่อ นามสกุล หน้าสมุดบัญชี'
                  value={formState.accountName || ''}
                  onChange={(e) => _onChangeForm(e, 'accountName')}
                />
              </div>
            </div>
            <div className='form__setWrapper'>
              <div className='form__item'>
                <label className='form__item_accountNumber' htmlFor='account-number'>
                  เลขบัญชี
                </label>
                <input
                  type='text'
                  name='account-number'
                  id='account-number'
                  placeholder='ระบุ เฉพาะตัวเลข'
                  value={formState.accountNumber || ''}
                  onChange={(e) => _onChangeForm(e, 'accountNumber')}
                />
              </div>
              <div className='form__item'>
                <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                  ยืนยันเลขบัญชี
                </label>
                <input
                  type='text'
                  name='account-number-confirm'
                  id='account-number-confirm'
                  placeholder='ยืนยันเลขบัญชีอีกครั้ง'
                  value={formState.accountNumberConfirm || ''}
                  onChange={(e) => _onChangeForm(e, 'accountNumberConfirm')}
                />
              </div>
            </div>
            <hr className='divider'/>
            {isLoading ? (
              <div className='loading-gif'>
                <img className='loading-indicator-gif ' src={require('../../assets/loading.gif')} alt='loading'/>
              </div>
            ) : isFormChange ? (
              <ButtonGroup
                onClickFirstButton={_onClickFirstButton}
                onClickSecondButton={_onClickSecondButton}
                withBorder
                firstText='ยกเลิก'
                secondText={playerBankAccount ? 'แก้ไขบัญชี' : 'เพิ่มบัญชี'}
              />
            ) : null}

            {playerBankAccount ? (
              <div className='p-2'>
                <p>เมื่อทำการแก้ไขบัญชี ต้องทำการติดต่อแอดมินด้วยปุ่ม "ติดต่อแอดมิน"</p>
              </div>
            ) : null}
          </form>
        </BoxWhite>
      ) : (
        <BoxWhite containerClass='newAccount__form'>
          <form className='form__wrapper'>
            <div className='form__setWrapper'>
              <div className='form__item'>
                <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                  กรุณากรอกเบอร์โทรศัพท์มือถือของท่าน
                </label>
                <Input onChange={_handleChangeForm} type='tel' id='tel' value={formData.tel}/>
              </div>
            </div>
            <hr className='divider'/>
            {isLoading ? (
              <div className='loading-gif'>
                <img className='loading-indicator-gif ' src={require('../../assets/loading.gif')} alt='loading'/>
              </div>
            ) : (
              <Button onButtonClick={handleSubmit} wrapperClassName='profile__submit' text='บันทึก'/>
            )}
          </form>
        </BoxWhite>
      )}
      <Modal
        isOpen={contactModal}
        onRequestClose={() => {
          setContactModal(false)
        }}
        style={customStyles}
        contentLabel='Contact Modal'
      >
        <BoxWhite withShine={false} withFlare={false} containerClass='contactModal__box-wrapper'>
          <Button
            wrapperClassName='contactModal__title-wrapper'
            buttonClassName='contactModal__title-button'
            text='ติดต่อแอดมิน'
            buttonBackground='#5D4C7F'
            shouldNotShowCursor
          />
          <span className='contactModal__close' onClick={() => setContactModal(false)}>
            X
          </span>
          <div className='contactModal__text-wrapper'>
            <div className='contactModal__bottom-wrapper'>
              <div className='contactModal__contact contactModal__contact-line'>
                <p>ติดต่อทางไลน์</p>
                <a href='#'>
                  <img style={{ width: '50px', height: '50px' }} src={lineIcon} alt='line'/>
                </a>
              </div>
              {/*<div className='contactModal__contact contactModal__contact-call'>*/}
              {/*  <p>ติดต่อผ่านโทรศัพท์</p>*/}
              {/*  <a href='tel:0626609051'>*/}
              {/*    <img src={phoneIcon} alt='phone' />*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
          <hr className='contactModal__divider'/>
          <Button
            onButtonClick={() => setContactModal(false)}
            wrapperClassName='contactModal__close-wrapper'
            buttonClassName='contactModal__close-button'
            text='ปิด'
            buttonBackground='#d80016'
          />
        </BoxWhite>
      </Modal>
    </section>
  )
}

export default AddBankAccount
