import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import get from 'lodash/get'
import _ from 'lodash'

const useGetPayRate = (lottoNumber, lottoGroup) => {
  const [fetchPayRate, setFetchPayRate] = useState({})
  const [highestPayRate, setHighestPayRate] = useState({})
  // const [isLoading, setIsLoading] = useState(false)

  const requestPayRate = () => {
    const path = `/pay-rate/lotto/${lottoNumber}`

    // setIsLoading(true)
    API.get('purchase', path)
      .then((res) => {
        // console.log('payRate fetch')
        const data = get(res, 'detail', [])
        setFetchPayRate(data)
        const highest = {}
        // console.log(lottoGroup)
        if (lottoGroup === 'หวยไทย' || lottoGroup === 'หวยยี่กี') {
          highest['3A'] = 900
          highest['3R'] = 150
          highest['2A'] = 100
          highest['2B'] = 100
          highest['SA'] = 3.3
          highest['SB'] = 4.3
          highest['3F'] = 450
          highest['3B'] = 450
        } else {
          highest['3A'] = 900
          highest['3R'] = 150
          highest['2A'] = 95
          highest['2B'] = 95
          highest['SA'] = 3.3
          highest['SB'] = 4.3
          highest['3F'] = 450
          highest['3B'] = 450
        }
       
        setHighestPayRate(highest)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      // .finally(() => {
      //   setIsLoading(false)
      // })
  }

  useEffect(() => {
    requestPayRate()
  }, [])
  return { fetchPayRate, highestPayRate }
}

export default useGetPayRate