import React, { useEffect, useState } from 'react'

import ResultHistoryContainer from './ResultHistoryContainer'
import loadingGif from '../../assets/loading.gif'

import './ResultHistory.scss'

function Home() {
  //const [posts, setPosts] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true)
    }
    return () => {}
  }, [isLoaded])

  return (
    <div className='home page'>
      <div className='news container mx-auto'>
        {isLoaded ? (
          <ResultHistoryContainer />
        ) : (
          <div className='loader loading-gif'>
            <img className='loading-indicator-gif ' src={loadingGif} alt='loading' />
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
