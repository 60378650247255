import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import './Navigator.scss'
import * as Icons from './icons'
import homeIcon from './assets/home.png'
import homeIconActive from './assets/home-active.png'
import lottoIcon from './assets/lotto.png'
import lottoIconActive from './assets/lotto-active.png'
import hotIcon from './assets/hot.png'
import hotIconActive from './assets/hot-active.png'
import resultIcon from './assets/result.png'
import resultIconActive from './assets/result-active.png'
import walletIcon from './assets/wallet.png'
import walletIconActive from './assets/wallet-active.png'

function Navigator() {
  const [isCollpase, setIsCollapse] = useState(true)

  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]

  return (
    <div className='navigator container-fluid'>
      <div className='wrapper'>
        <ul className='main-navigator'>
          <li>
            <NavLink tabIndex={-1} activeClassName='active' to='/home'>
              <img src={currentLocation === 'home' ? homeIconActive : homeIcon} alt='home' />
              <div style={currentLocation === 'home' ? { color: 'green', fontWeight: 'bold' } : null}>หน้าแรก</div>
            </NavLink>
          </li>
          <li>
            <NavLink tabIndex={-1} activeClassName='active' to='/hotpick'>
              <img src={currentLocation === 'hotpick' ? lottoIconActive : lottoIcon} alt='lotto' />
              <div style={currentLocation === 'hotpick' ? { color: 'green', fontWeight: 'bold' } : null}>เลขเด็ด</div>
            </NavLink>
          </li>
          <li>
            <NavLink tabIndex={-1} activeClassName='active' to='/lottos'>
              <img src={currentLocation === 'lottos' ? hotIconActive : hotIcon} style={{ width: '50px' }} alt='lotto' />
              <div style={currentLocation === 'lottos' ? { color: 'green', fontWeight: 'bold' } : null}>แทงหวย</div>
              {/* style={{ width: '50px' }} */}
            </NavLink>
          </li>
          <li>
            <NavLink tabIndex={-1} activeClassName='active' to='/receipts'>
              <img src={currentLocation === 'receipts' ? resultIconActive : resultIcon} alt='check result' />
              <div style={currentLocation === 'receipts' ? { color: 'green', fontWeight: 'bold' } : null}>ตรวจผล</div>
            </NavLink>
          </li>
          <li>
            <NavLink tabIndex={-1} activeClassName='active' to='/deposit'>
              <img src={currentLocation === 'deposit' ? walletIconActive : walletIcon} alt='check result' />
              <div style={currentLocation === 'deposit' ? { color: 'green', fontWeight: 'bold' } : null}>กระเป๋าเงิน</div>
            </NavLink>
          </li>
          {/* <li>
            <div
              className='no-bg navigator-sub-menu-btn'
              onClick={(e) => {
                e.preventDefault()
                setIsCollapse(!isCollpase)
              }}
            >
              <Icons.Dash />
            </div>
          </li> */}
        </ul>
        {/* <ul className={`sub-navigator ${!isCollpase ? 'active' : ''}`}>
          <div
            className='sub-navigator_close-btn'
            onClick={(e) => {
              e.preventDefault()
              setIsCollapse(!isCollpase)
            }}
          >
            <Icons.Close />
          </div>
          <li>
            <Link to='/profile' onClick={() => setIsCollapse(!isCollpase)}>
              ข้อมูลส่วนตัว
            </Link>
          </li> */}
        {/* <li>*/}
        {/*  <Link to='/invite-friend' onClick={() => setIsCollapse(!isCollpase)}>*/}
        {/*    โค้ดชวนเพื่อน*/}
        {/*  </Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to='/affiliate' onClick={() => setIsCollapse(!isCollpase)}>*/}
        {/*    รายได้ชวนเพื่อน*/}
        {/*  </Link>*/}
        {/*</li> */}
        {/* <li>
            <Link to='/transaction-list' onClick={() => setIsCollapse(!isCollpase)}>
              สถานะฝากเงิน
            </Link>
          </li>
          <li>
            <Link to='/result-history' onClick={() => setIsCollapse(!isCollpase)}>
              ผลรางวัลย้อนหลัง
            </Link>
          </li>
          <li>
            <Link to='/rules' onClick={() => setIsCollapse(!isCollpase)}>
              กฎกติกา
            </Link>
          </li> */}
        {/*<li>*/}
        {/*  <Link to='/tutorial' onClick={() => setIsCollapse(!isCollpase)}>*/}
        {/*    วิธีใช้งาน*/}
        {/*  </Link>*/}
        {/*</li>*/}
        {/* </ul> */}
      </div>
    </div>
  )
}

export default Navigator
