/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Combinatorics from 'js-combinatorics'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import _, { isNumber, set } from 'lodash'
import Slider from 'react-input-slider';

import { toggleCart, removeLastest } from '../../../redux/actions/cart'

export default function KeyPad(props) {
  const [manualInputValue, setManualInputValue] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [keyPad, setKeyPad] = useState(0)
  const [winType, setWinType] = useState('3')
  const [isDuplicated, setIsDuplicated] = useState(false)
  const [winNumber, setWinNumber] = useState([])
  const [isFourClose, setIsFourClose] = useState(false)

  const dispatch = useDispatch()
  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])

  const cartItemsGroupByType = _.groupBy(cartItems, 'type')

  const typeList = props.typeList
  const lottoNumber = props.lottoNumber
  const lottoGroup = props.lottoGroup
  const currentDigit = props.currentDigit
  const isPermutation = props.isPermutation
  const setIsPermutation = props.setIsPermutation
  const isManualInput = props.isManualInput
  const manualInputOption = props.manualInputOption
  const fetchPayRate = props.fetchPayRate
  const tricker = props.tricker

  const [fourDigit, setFourDigit] = useState({ firstTwo: 0, lastTwo: 0 });

  const removeDups = (array) => {
    let unique = {}
    array.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true
      }
    })
    return Object.keys(unique)
  }

  const isAlreadyExists = (number, singleType) => {
    return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
  }

  const generatePermutation = (number, singleType) => {
    const arr = []
    if (singleType === '3R') {
      arr.push(number)
    }
    const refactornumber = number.replace(/,/g, '').toString()
    const result = singleType === '3R' ? arr : Combinatorics.permutation(refactornumber.split('')).toArray()
    const unique = removeDups(result)
    let allChecked = false
    // console.log(singleType, 'singleType')
    // console.log(number, 'number')
    // console.log(refactornumber, 'refactorNumber')
    // console.log(result, 'result')
    // console.log(unique, 'unique')
    for (let i = 0; i < unique.length; i++) {
      let newNumber = unique[i].replace(/,/g, '').toString()
      if (!isAlreadyExists(newNumber, singleType)) {
        selectNumber(newNumber, singleType)
      } else {
        allChecked = true
      }
    }
    if (allChecked) {
      for (let i = 0; i < unique.length; i++) {
        let newNumber = unique[i].replace(/,/g, '').toString()
        selectNumber(newNumber, singleType)
      }
    }
  }

  const selectNumber = (number, singleType) => {
    if (lottoGroup !== 'เบอร์รถ') {
      if (fetchPayRate[singleType]) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 1,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    } else {
      const duplicate = cartItems.find(obj => obj.detail === number)
      if (fetchPayRate[singleType] && !duplicate) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 399,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    }
  }

  const clearInput = () => {
    if (keyPad === 1) {
      setIsLoading(true)
      const box = document.getElementById('manual-input-indicator')
      box.classList.add('animate')
      setTimeout(() => {
        box.classList.remove('animate')
        setManualInputValue([])
        setIsLoading(false)
      }, 100)
    }
  }

  const generate10lotto = (num, model, type) => {
    num = num.toString()
    switch (model) {
      case 'nineteen':
        if (typeList.join('') === '2A2B' || typeList.join('') === '2B2A') {
          {
            let target = []
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            _.uniqBy(target).forEach((item) => {
              // console.log(item, 'items')
              selectNumber(item, '2A')
            })
          }
          {
            let target = []
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            _.uniqBy(target).forEach((item) => {
              selectNumber(item, '2B')
            })
          }
        }
        if (typeList.join('') === '2A') {
          {
            let target = []
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            _.uniqBy(target).forEach((item) => {
              // console.log(item, 'items')
              selectNumber(item, '2A')
            })
          }
        }
        if (typeList.join('') === '2B') {
          {
            let target = []
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            _.uniqBy(target).forEach((item) => {
              selectNumber(item, '2B')
            })
          }
        }
        // {
        //   let target = []
        //   for (let i = 0; i < 10; i++) {
        //     let front = num.concat(i)
        //     let back = i.toString().concat(num)
        //     target.push(front)
        //     target.push(back)
        //   }
        //   _.uniqBy(target).forEach((item) => {
        //     if (!isAlreadyExists(item, type)) {
        //       selectNumber(item, type)
        //     } else {
        //       _.uniqBy(target).forEach((item) => {
        //         selectNumber(item, type)
        //       })
        //     }
        //   })
        // }
        break
      case 'prefix':
        for (let i = 0; i < 10; i++) {
          selectNumber(num.concat(i), type)
        }
        break
      case 'suffix':
        for (let i = 0; i < 10; i++) {
          selectNumber(i.toString().concat(num), type)
        }
        break
      default:
        console.log('default model')
    }
  }

  const handleSelectNumber = () => {
    if (!isLoading) {
      if (manualInputValue.length > 0) {
        if (currentDigit === '1') {
          if (manualInputValue.length === 1) {
            // if (!isPermutation) {
            if (manualInputOption !== null) {
              if (manualInputOption === 'nineteen') {
                generate10lotto(manualInputValue.toString(), manualInputOption)
                clearInput()
              } else {
                typeList.forEach((type) => {
                  generate10lotto(manualInputValue.toString(), manualInputOption, type)
                })
                clearInput()
              }
            } else {
              typeList.forEach((type) => {
                selectNumber(manualInputValue.join(''), type)
              })
              clearInput()
            }
            // } else {
            //   typeList.forEach((type) => {
            //     generatePermutation(manualInputValue.toString(), type)
            //   })
            //   clearInput()
            // }
          }
        }
        if (currentDigit === '2') {
          if (manualInputValue.length === 2) {
            if (!isPermutation) {
              typeList.forEach((type) => {
                selectNumber(manualInputValue.join(''), type)
              })
              clearInput()
            } else {
              typeList.forEach((type) => {
                generatePermutation(manualInputValue.toString(), type)
              })
              clearInput()
            }
          }
        }
        if (currentDigit === '3') {
          if (manualInputValue.length === 3) {
            if (!isPermutation) {
              // console.log(isPermutation)
              typeList.forEach((type) => {
                selectNumber(manualInputValue.join(''), type)
              })
              clearInput()
            } else {
              typeList.forEach((type) => {
                // console.log(manualInputValue.toString())
                generatePermutation(manualInputValue.toString(), type)
              })
              clearInput()
            }
          }
        }
        if (currentDigit === '4') {
          if (manualInputValue.length === 4) {
            if (!isPermutation) {
              // console.log(isPermutation)
              typeList.forEach((type) => {
                selectNumber(manualInputValue.join(''), type)
              })
              clearInput()
            } else {
              typeList.forEach((type) => {
                // console.log(manualInputValue.toString())
                generatePermutation(manualInputValue.toString(), type)
              })
              clearInput()
            }
          }
        }
      }
    }
  }

  // console.log(inputBox, 'inputBox')'

  const handleSelectFour = () => {
    if (!isLoading) {
      if (fourDigit.firstTwo > 0 && fourDigit.lastTwo > 0) {
        const numberValue = `${(fourDigit.firstTwo - 1).toString().padStart(2, '0')}${(fourDigit.lastTwo - 1).toString().padStart(2, '0')}`
        console.log(numberValue)
        if (!isPermutation) {
          // console.log(isPermutation)
          selectNumber(numberValue, '4A')
          clearInput()
        } else {
          generatePermutation(numberValue, '4A')
          clearInput()
        }
      }
    }
  }

  const NumberIndicator = () => {
    if (fetchPayRate['3A']) {
      let numberBox = []
      for (let index = 0; index < currentDigit; index++) {
        numberBox.push(
          <div className='number__input' key={index}>
            {manualInputValue[index] ? manualInputValue[index] : '-'}
          </div>
        )
      }
      return numberBox
    } else {
      return 'โปรดรอสักครู่'
    }
  }

  const isClosed = (number) => {
    const closed = fetchPayRate['4A'] ? fetchPayRate['4A'][number] === 1 : false
    console.log(closed, 'closed')
    setIsFourClose(closed)
    return closed
  }

  const onChangeFourDigit = (event) => {
    console.log(event, 'eventtt')
    setFourDigit((prevState) => ({ ...prevState, firstTwo: event.x ? event.x : prevState.firstTwo, lastTwo: event.y ? event.y : prevState.lastTwo }))
  }

  const NumberDrag = () => {
    if (fetchPayRate['4A']) {
      if (fourDigit.firstTwo > 0 && fourDigit.lastTwo > 0) {
        const numberValue = `${(fourDigit.firstTwo - 1).toString().padStart(2, '0')}${(fourDigit.lastTwo - 1).toString().padStart(2, '0')}`
        return (<div className={`number__input ${isClosed(numberValue) ? 'text-red-900' : ''
          }`} style={{ padding: '0 80px' }}>
          {numberValue}
        </div>)
      } else {
        return 'เลื่อนได้เลย'
      }
    } else {
      return 'โปรดรอสักครู่'
    }
  }


  const WinIndicator = () => {
    if (fetchPayRate['3A']) {
      return (
        <div className='win'>
          <div>{`วินเลข ${winType} ตัว`}</div>
          <span>
            {isDuplicated
              ? winType === '3'
                ? 'รวมเลขหามตอง'
                : 'รวมเลขเบิ้ล'
              : winType === '3'
                ? 'ไม่รวมหามตอง'
                : 'ไม่รวมเลขเบิ้ล'}{' '}
            {isPermutation ? 'รวมกลับตัวเลข' : 'ไม่กลับเลข'}
          </span>
        </div>
      )
    } else {
      return 'โปรดรอสักครู่'
    }
  }

  // const handleWin = () => {
  //   let sortWin = winNumber.sort()
  //   const typeTwoSequence = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  //   const typeThreeSequence = [0, 0, 1, 3, 6, 10, 15, 21, 28, 36]
  //   let firstWin = []
  //   let secondWin = []
  //   if (isDuplicated) {
  //     console.log('test')
  //   } else {
  //     for (let i = 0; i < sortWin.length; i++) {
  //       console.log(sortWin, 'sortWin')
  //       const firstDigits = sortWin[i].repeat(
  //         winType === '3' ? typeThreeSequence[sortWin.length - i - 1] : typeTwoSequence[sortWin.length - i - 1]
  //       )
  //       firstWin.push(firstDigits)
  //     }
  //     firstWin = firstWin.join('')
  //     console.log(firstWin, 'firstWin')
  //     let secondSortWin = [...sortWin]
  //     secondSortWin.shift()
  //     console.log(firstWin.length, 'firstWIn')
  //     for (let j = 0; j++; ) {
  //       for (let i = 0; i < secondSortWin.length; i++) {
  //         console.log(secondSortWin[i + j], 'secondSortWin[i + j]')
  //         const secondDigits = secondSortWin[i + j].repeat(
  //           winType === '3'
  //             ? typeTwoSequence[secondSortWin.length - i - j]
  //             : typeTwoSequence[secondSortWin.length - i - j] //need edit
  //         )
  //         secondWin.push(secondDigits)
  //         secondWin = secondWin.join('')
  //       }
  //       if (secondWin.length === firstWin.length) {
  //         break
  //       }
  //     }
  //     secondWin = secondWin.join('')
  //     console.log(secondWin, 'secondWin')
  //   }
  // }

  const handleWin = () => {
    const result = []
    result.length = +winType
    function combine(input, len, start) {
      if (len === 0) {
        if (!isPermutation) {
          typeList.forEach((type) => {
            if (result.join('').length === +winType) {
              selectNumber(result.join(''), type)
            }
          })
        } else {
          typeList.forEach((type) => {
            if (result.join('').length === +winType) {
              generatePermutation(result.join(''), type)
            }
          })
        }
        return
      }
      for (let i = start; i <= input.length - len; i++) {
        result[result.length - len] = input[i]
        combine(input, len - 1, i + 1)
      }
    }
    function combineDuplicated(input, len, start) {
      if (len === 0) {
        if (!isPermutation) {
          typeList.forEach((type) => {
            if (result.join('').length === +winType) {
              selectNumber(result.join(''), type)
            }
          })
        } else {
          typeList.forEach((type) => {
            if (result.join('').length === +winType) {
              generatePermutation(result.join(''), type)
            }
          })
        }
        return
      }
      for (let i = start; i <= input.length; i++) {
        result[result.length - len] = input[i]
        combineDuplicated(input, len - 1, i)
      }
    }
    const array = winNumber.sort()
    if (!isDuplicated) {
      combine(array, result.length, 0)
    } else {
      combineDuplicated(array, result.length, 0)
    }
  }

  const handleWinInput = (num, e = null) => {
    const numString = num.toString()
    if (e !== null) {
      e.preventDefault()
    }
    setWinNumber((prevState) =>
      _.includes(prevState, numString) ? prevState.filter((item) => item !== numString) : [...prevState, numString]
    )
  }

  // const mapThaiTypeList = {
  //   '2A': 'สองตัวบน',
  //   '2B': 'สองตัวล่าง',
  //   '3A': 'สามตัวบน',
  //   '3R': 'สามตัวโต๊ด',
  //   '3F': 'สามตัวหน้า',
  //   '3B': 'สามตัวล่าง',
  //   SA: 'วิ่งบน',
  //   SB: 'วิ่งล่าง',
  //   nineteen: 'รูด 19 ประตู',
  //   prefix: 'รูดหน้า',
  //   suffix: 'รูดหลัง',
  // }

  // const InputIndicator = () => {
  //   if (fetchPayRate['3A']) {
  //     let typeInput = []
  //     for (let index = 0; index < typeList.length; index++) {
  //       typeInput.push(
  //         <span className={'type' + typeList[index]} key={index}>
  //           {mapThaiTypeList[typeList[index]]}
  //         </span>
  //       )
  //     }
  //     if (typeList.join('') === '2A' || typeList.join('') === '2B' || typeList.join('') === '2A2B') {
  //       if (manualInputOption) {
  //         typeInput.push(<span className={manualInputOption}>{mapThaiTypeList[manualInputOption]}</span>)
  //       } else {
  //         typeInput.concat(<span className={manualInputOption}>{mapThaiTypeList[manualInputOption]}</span>)
  //       }
  //     } else {
  //       typeInput.concat(<span className={manualInputOption}>{manualInputOption}</span>)
  //     }
  //     if (isPermutation) {
  //       typeInput.push(<span className='permutation'>กลับตัวเลข</span>)
  //     } else {
  //       typeInput.concat(<span className='permutation'>กลับตัวเลข</span>)
  //     }
  //     return typeInput
  //   } else {
  //     return 'กำลังโหลด'
  //   }
  // }
  // const _onChangeNumber = (e, currentDigit) => {
  //   console.log(currentDigit, 'currentDigits')
  //   const value = e.target.value
  //   let newValue = []
  //   const validateNumber = value.replace(/[^0-9]/g, '')
  //   console.log(validateNumber, 'validatNumber')
  //   for (let index = 0; index < validateNumber.length; index++) {
  //     newValue.push(
  //       <span className={currentDigit} key={index}>
  //         {validateNumber[index]}
  //       </span>
  //     )
  //   }
  //   console.log(newValue, 'newValue')
  //   setInputBox(newValue)
  //   // if (inputName === 'bankCode') {
  //   //   setFormState({ ...formState, [inputName]: value, bankName: mapBankCodeToBankName[value] })
  //   //   return
  //   // }
  //
  //   // if (inputName === 'accountNumber' || inputName === 'accountNumberConfirm') {
  //   //   const newValue = value.replace(/[^0-9]/g, '')
  //   //   setFormState({ ...formState, [inputName]: newValue })
  //   //   return
  //   // }
  //   //
  //   // setFormState({ ...formState, [inputName]: value })
  // }
  const handleNumpad = (num, e = null) => {
    const numString = num.toString()
    if (e !== null) {
      e.preventDefault()
    }
    setManualInputValue((state) => [...state, numString])
  }

  const handlePaste = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '')
    if (newValue.length >= +currentDigit) {
      if (newValue.length === +currentDigit) {
        setManualInputValue([...newValue])
        event.target.value = ''
      } else {
        const groupNumber = (newValue + ' ').match(new RegExp('.{1,' + currentDigit + '}', 'g'))
        const nineteenFilter = currentDigit === '1' ? groupNumber.length * 19 : 0
        if (groupNumber.length > 300 || nineteenFilter > 300) {
          Swal.fire({
            icon: 'error',
            title: 'ท่านวางตัวเลขมากเกินไปครับ',
            showConfirmButton: false,
            timer: 1000,
          })
          event.target.value = ''
        } else {
          const lastGroup = groupNumber.pop()
          for (let i = 0; i < groupNumber.length; i++) {
            setTimeout(() => {
              setManualInputValue([...groupNumber[i]])
            }, 1)
          }
          event.target.value = lastGroup.replace(/ /g, '')
        }
      }
    } else {
      event.target.value = newValue
    }
  }

  // const handleInput = (num, e = null) => {
  //   console.log('e handleInput', e)
  //   const numString = num.toString()
  //   if (e !== null) {
  //     e.preventDefault()
  //   }
  //   setInputBox((state) => [...state, numString])
  // }

  // const handleInput = (e) => {
  //   // setIsFormChange(true)
  //   // setIsFormChange(true)
  //   const value = e.target.value
  //
  //   const newValue = value.replace(/[^0-9]/g, '')
  //   setInputBox({ ...inputTags, newValue })
  // }

  // const handleDelete = (i) => {
  //   // setIsFormChange(true)
  //   const { tags } = inputTags
  //   setInputTags({
  //     tags: tags.filter((tag, index) => index !== i),
  //   })
  // }

  // const handleAddition = (tag) => {
  //   setInputTags({ ...inputTags, tag })
  // }

  const buyWithKeyboard = (e) => {
    // console.log(e)
    const acceptNumKey = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
    const acceptActionDelete = ['Backspace', 'Delete']
    if (_.includes(acceptNumKey, e.key)) {
      handleNumpad(e.key)
    }
    if (_.includes(acceptActionDelete, e.key)) {
      setManualInputValue((state) => (state.length > 0 ? [...state.slice(0, -1)] : [...state]))
    }
  }

  // const inputWithKeyboard = (e) => {
  //   console.log(e)
  //   const value = e.target.value
  //   const newValue = value.replace(/[^0-9]/g, '')
  //   handleInput(newValue)
  //   // const acceptNumKey = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  //   // const acceptActionDelete = ['Backspace', 'Delete']
  //   // // if (_.includes(acceptNumKey, e.key)) {
  //   //   handleNumpad(e.key)
  //   // }
  //   // if (_.includes(acceptActionDelete, e.key)) {
  //   //   setManualInputValue((state) => (state.length > 0 ? [...state.slice(0, -1)] : [...state]))
  //   // }
  // }

  const handleRandomNumber = (digits) => {
    const randomDigits = Array.from({ length: +digits }, () => Math.floor(Math.random() * 10))
    setManualInputValue(randomDigits)
  }

  // const handleClearCart = () => {
  //   if (cartCount > 0) {
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'แจ้งเตือน',
  //       text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
  //       showCancelButton: true,
  //       confirmButtonColor: '#7dc351',
  //       cancelButtonColor: '#C0C0C0',
  //       confirmButtonText: 'ลบเลข',
  //       cancelButtonText: 'ยกเลิก',
  //     }).then((result) => {
  //       if (result.value) {
  //         dispatch(clearCart())
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'ล้างรายการทั้งหมดสำเร็จ',
  //           showConfirmButton: false,
  //           timer: 1000,
  //         })
  //       }
  //     })
  //   }
  // }

  useEffect(() => {
    // console.log(typeList, isPermutation)
    setManualInputValue([])
    if (!_.includes(typeList, '2A') && !_.includes(typeList, '2B')) {
      setWinType('3')
    } else {
      setWinType('2')
    }
    if ((_.includes(typeList, 'SA') || _.includes(typeList, 'SB')) && keyPad === 3) {
      setKeyPad(1)
    }
  }, [typeList, isPermutation])

  useEffect(() => {
    setWinNumber([])
    setIsDuplicated(false)
    setIsPermutation(false)
  }, [winType])

  useEffect(() => {
    if (keyPad === 0) {
      setKeyPad(1)
    }
    if (keyPad === 1) {
      setKeyPad(3)
    }
    if (keyPad === 3) {
      setKeyPad(2)
    }
    if (keyPad === 2) {
      setKeyPad(1)
    }
  }, [tricker])

  useEffect(() => {
    if (keyPad === 1) {
      if (manualInputValue.length.toString() === currentDigit) {
        handleSelectNumber()
      }
      document.addEventListener('keydown', buyWithKeyboard)
      return () => {
        document.removeEventListener('keydown', buyWithKeyboard)
      }
    } else if (keyPad === 2) {
      handleSelectNumber()
    }
  }, [manualInputValue, isManualInput, keyPad])

  return (
    <div className='buy-lotto__btn-manual-input'>
      {lottoGroup !== 'เบอร์รถ' ? (
        <div>
          <div>
            <div
              onClick={() => {
                setKeyPad(1)
                setManualInputValue([])
              }}
              className={`${keyPad === 1 ? 'press' : ''}`}
            >
              กดตัวเลข
            </div>
            <div
              onClick={() => setKeyPad(3)}
              className={`${!_.includes(typeList, 'SA') && !_.includes(typeList, 'SB') ? '' : 'hidden'} ${keyPad === 3 ? 'press' : ''}`}
            >
              จับเลขวิน
            </div>
            <div onClick={() => setKeyPad(2)} className={`${keyPad === 2 ? 'press' : ''}`}>วางตัวเลข</div>
            {/*<div></div>*/}
            {/*<div>ระบุตัวเลข</div>*/}
            {/*<div></div>*/}
          </div>
          {
            keyPad === 1 ? (
              <div className='text-4xl'>
                <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
                  <NumberIndicator />
                </div>
              </div>
            ) : keyPad === 2 ? (
              <input
                className='paste__input'
                autoFocus="autofocus"
                onKeyUp={(event) => handlePaste(event)}
                onKeyDown={(e) => e.code === 'Tab' ? setKeyPad(1) : null}
                name='input-box'
                id='input-box'
                placeholder='เลือกการแทงและวางตัวเลขได้เลยครับ'
              />
            ) : (
              <div className='text-4xl'>
                <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
                  <WinIndicator />
                </div>
              </div>
            )
            //   (
            //   <div className='text-4xl'>
            //     <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
            //       <span>
            //         <InputIndicator />
            //       </span>
            //     </div>
            //   </div>
            // )
          }
        </div>) : (
        <div>
          <div>
            <div
              onClick={() => {
                setKeyPad(1)
                setManualInputValue([])
              }}
              className={`${keyPad === 1 ? 'press' : ''}`}
            >
              กดตัวเลข
            </div>
            <div
              onClick={() => {
                setKeyPad(4)
                setManualInputValue([])
              }}
              className={`${keyPad === 4 ? 'press' : ''}`}
            >
              เลื่อนเลือกเลข
            </div>
            {/*<div></div>*/}
            {/*<div>ระบุตัวเลข</div>*/}
            {/*<div></div>*/}
          </div>
          {
            keyPad === 1 ? (
              <div className='text-4xl'>
                <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
                  <NumberIndicator />
                </div>
              </div>
            ) : keyPad === 2 ? (
              <input
                className='paste__input'
                autoFocus="autofocus"
                onKeyUp={(event) => handlePaste(event)}
                onKeyDown={(e) => e.code === 'Tab' ? setKeyPad(1) : null}
                name='input-box'
                id='input-box'
                placeholder='เลือกการแทงและวางตัวเลขได้เลยครับ'
              />
            ) : keyPad === 3 ? (
              <div className='text-4xl'>
                <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
                  <WinIndicator />
                </div>
              </div>
            ) : (
              <div className='text-4xl'>
                <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
                  <NumberDrag />
                </div>
              </div>
            )
            //   (
            //   <div className='text-4xl'>
            //     <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
            //       <span>
            //         <InputIndicator />
            //       </span>
            //     </div>
            //   </div>
            // )
          }
        </div>
      )}
      {
        keyPad === 1 || keyPad === 3 ? (
          <div>
            <div>
              <div>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '1') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(1, event) : (event) => handleWinInput(1, event)}
                >
                  1
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '2') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(2, event) : (event) => handleWinInput(2, event)}
                >
                  2
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '3') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(3, event) : (event) => handleWinInput(3, event)}
                >
                  3
                </button>
              </div>
              <div>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '4') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(4, event) : (event) => handleWinInput(4, event)}
                >
                  4
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '5') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(5, event) : (event) => handleWinInput(5, event)}
                >
                  5
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '6') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(6, event) : (event) => handleWinInput(6, event)}
                >
                  6
                </button>
              </div>
              <div>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '7') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(7, event) : (event) => handleWinInput(7, event)}
                >
                  7
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '8') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(8, event) : (event) => handleWinInput(8, event)}
                >
                  8
                </button>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '9') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(9, event) : (event) => handleWinInput(9, event)}
                >
                  9
                </button>
              </div>
              <div>
                <button
                  className={`btn-bevel ${_.includes(winNumber, '0') && keyPad === 3 ? 'active' : ''}`}
                  onClick={keyPad === 1 ? (event) => handleNumpad(0, event) : (event) => handleWinInput(0, event)}
                >
                  0
                </button>
              </div>
            </div>
            {keyPad === 1 ? (
              <div>
                <button
                  className='btn-bevel'
                  onClick={() =>
                    setManualInputValue((state) => (state.length > 0 ? [...state.slice(0, -1)] : [...state]))
                  }
                >
                  <svg
                    width='24'
                    height='24'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='#ffffff'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  >
                    <path d='M7 5h17v16h-17l-7-7.972 7-8.028zm7 6.586l-2.586-2.586-1.414 1.414 2.586 2.586-2.586 2.586 1.414 1.414 2.586-2.586 2.586 2.586 1.414-1.414-2.586-2.586 2.586-2.586-1.414-1.414-2.586 2.586z' />
                  </svg>
                </button>
                <button className='btn-bevel' onClick={() => dispatch(removeLastest())}>
                  ยกเลิกล่าสุด
                </button>
                <button className='btn-bevel' onClick={() => handleRandomNumber(currentDigit)}>
                  สุ่มเลข
                </button>
              </div>
            ) : (
              <div>
                <button className='btn-bevel' onClick={() => setWinNumber([])}>
                  <svg
                    width='24'
                    height='24'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='#ffffff'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  >
                    <path d='M7 5h17v16h-17l-7-7.972 7-8.028zm7 6.586l-2.586-2.586-1.414 1.414 2.586 2.586-2.586 2.586 1.414 1.414 2.586-2.586 2.586 2.586 1.414-1.414-2.586-2.586 2.586-2.586-1.414-1.414-2.586 2.586z' />
                  </svg>
                </button>
                <button
                  className={`btn-bevel permutation ${isDuplicated ? 'active' : ''}`}
                  onClick={() => setIsDuplicated(!isDuplicated)}
                >
                  {winType === '3' ? 'รวมหามตอง' : 'รวมเบิ้ล'}
                </button>
                <button
                  className={`btn-bevel permutation ${isPermutation ? 'active' : ''}`}
                  onClick={() => setIsPermutation(!isPermutation)}
                >
                  รวมกลับเลข
                </button>
                <button
                  className={`btn-bevel win-submit ${winNumber.length < +winType && !isDuplicated ? 'disabled' : winNumber.length < 1 ? 'disabled' : ''
                    }`}
                  onClick={() => handleWin()}
                >
                  แทงเลขวิน
                </button>
              </div>
            )}
          </div>
        ) : null
        // <div>
        //   <div>
        //     <div>
        //       <TagsInput currentDigit={currentDigit} tags={[]} />
        //       {/*<textarea*/}
        //       {/*  type='text'*/}
        //       {/*  name='input-box'*/}
        //       {/*  id='input-box'*/}
        //       {/*  placeholder='ระบุ เฉพาะตัวเลข'*/}
        //       {/*  value={inputBox}*/}
        //       {/*  onChange={(e) => handleInput(e)}*/}
        //       {/*/>*/}
        //     </div>
        //     {/*<div>*/}
        //     {/*  <button className='btn-bevel' onClick={() => setInputBox('')}>*/}
        //     {/*    ส่ง*/}
        //     {/*  </button>*/}
        //     {/*</div>*/}
        //   </div>
        // </div>
      }
      {
        keyPad === 4 ? (
          <div>
            <div>
              <Slider
                axis="xy"
                x={fourDigit.firstTwo}
                y={fourDigit.lastTwo}
                xmin={0}
                xmax={100}
                ymin={0}
                ymax={100}
                onChange={(x) => { onChangeFourDigit(x) }}
                styles={{
                  track: {
                    backgroundColor: '#c8b785'
                  },
                  thumb: {
                    backgroundColor: '#ffffff',
                    border: '4px ridge #8c7850',
                    width: '25px',
                    height: '25px'
                  },
                  disabled: {
                    opacity: 0.5
                  }
                }}
              />
              <Slider
                axis="x"
                x={fourDigit.firstTwo}
                onChange={({ x }) => onChangeFourDigit({x: x})}
                styles={{
                  track: {
                    backgroundColor: '#c8b785',
                    marginTop: '10px'
                  },
                  active: {
                    backgroundColor: '#c8b785'
                  },
                  thumb: {
                    backgroundColor: '#ffffff',
                    border: '4px ridge #8c7850',
                    width: '25px',
                    height: '25px'
                  },
                  disabled: {
                    opacity: 0.5
                  }
                }
              }/>
              <Slider
                axis="y"
                y={fourDigit.lastTwo}
                onChange={({ y }) => onChangeFourDigit({y: y})}
                styles={{
                  track: {
                    position: 'relative',
                    right: '-100%',
                    transform: 'translateY(calc(-100% - 20px))',
                    backgroundColor: '#c8b785',
                    whiteSpace: 'nowrap !important',
                    marginLeft: '10px'
                  },
                  active: {
                    backgroundColor: '#c8b785'
                  },
                  thumb: {
                    backgroundColor: '#ffffff',
                    border: '4px ridge #8c7850',
                    width: '25px',
                    height: '25px'
                  },
                  disabled: {
                    opacity: 0.5
                  }
                }
              }/>
            </div>
          </div>
        ) : null
      }
      {keyPad === 4 ? (
        <div className='four-submit-wrapper'>
          <button
            className={`btn-bevel four-submit ${isFourClose || !(fourDigit.firstTwo > 0 && fourDigit.lastTwo > 0) ? 'car-disabled' : ''
              }`}
            onClick={() => handleSelectFour()}
          >
            เลือกเลขนี้
          </button>
        </div>
      ) : null
      }
    </div>
  )
}

KeyPad.propTypes = {
  lottoNumber: PropTypes.string,
  currentDigit: PropTypes.string,
  fetchPayRate: PropTypes.object,
}
