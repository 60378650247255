import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import queryString from 'query-string'
import moment from 'moment'
import _ from 'lodash'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { getAuth } from '../../redux/selectors/auth'
import Button from '../../components/Button/Button'
import Box from '../../components/Box/Box'
import { getReceiptStatusText, getReceiptStatusBackgroundColor } from '../../utils/receiptUtil'
import { colorCode } from '../../utils/color'
import { STOCK_LIST } from '../../utils/stockList'
import yodtong from '../../assets/flags/yodtong.png'
// import shine from '../../assets/shine.png'

// import 'react-dates/initialize'
// import 'react-dates/lib/css/_datepicker.css'
import './AllReceipt.scss'

const checkFinishReceipt = (receipt) => {
  if (
    receipt.status === 'created' ||
    receipt.status === 'cancelled' ||
    receipt.status === 'error' ||
    receipt.status === 'rejected' ||
    receipt.status === 'timeout'
  ) {
    return
  }
  return receipt.status === 'rewarded'
}

const checkPrizedReceipt = (receipt) => {
  if (
    receipt.status === 'created' ||
    receipt.status === 'cancelled' ||
    receipt.status === 'error' ||
    receipt.status === 'rejected' ||
    receipt.status === 'timeout'
  ) {
    return
  }
  return receipt.status === 'rewarded' && receipt.prizeAmount > 0
}

function AllReceipt({ queryObject = {}, match, location, history }) {
  const auth = useSelector(getAuth)
  // const { receipts } = useSelector(getReceipts)
  const [allReceipts, setAllReceipts] = useState([])
  const [filteredReceipts, setFilteredReceipts] = useState([])
  const [allPurchasesAmount, setAllPurchasesAmount] = useState(0)
  // const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))
  const [date, setDate] = useState(new Date())
  // const [startDateFocus, setStartDateFocus] = useState(false)
  const [dateFocus, setDateFocus] = useState(false)
  const [isDate, setIsDate] = useState(false)
  const [receiptType, setReceiptType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [receiptsPage, setReceiptsPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(true)
  const [selectLotto, setSelectLotto] = useState('')
  const [lottoOptions, setLottoOptions] = useState([])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true)
    }
  }

  useEffect(() => {
    if (isBottom && !isLastPage && !isLoading) {
      setReceiptsPage(receiptsPage + 1)
    }
  }, [isBottom])

  useEffect(() => {
    addItems()
  }, [receiptsPage])

  const addItems = () => {
    if (allReceipts.length !== 0) {
      fetchReceiptData()
    }
  }

  const fetchLottoOptions = () => {
    console.log(date, 'date')
    const drawDate = moment(new Date(date)).format('DD/MM/YY')
    console.log(drawDate, 'drawDate')
    /////need fix API
    API.get('lotto', `/results?drawDateStr=${drawDate}&limit=1000`)
      .then((res) => {
        // const data = res.detail
        const data = res.detail.filter((obj) => !obj.lottoName.includes('ยี่กียอดธง') && !obj.lottoName.includes('นักษัตร') )
        const lottoList = data.map((obj) => {
          const list = {}
          list.lottoName = obj.lottoName
          list.lottoNumber = obj.lottoNumber
          return list
        })
        // console.log(lottoList, 'lottoList')
        const allLottos = [
          { lottoName: 'หวยทั้งหมด', lottoNumber: '' },
          { lottoName: 'ยี่กียอดธง', lottoNumber: '99999999' },
          { lottoName: 'สิบสองนักษัตร', lottoNumber: '88888888' },
        ]
        const option = allLottos.concat(lottoList)
        // console.log(option, 'lottoOptions')
        setLottoOptions(option)
      })
      .catch((err) => console.error(err))
  }

  const fetchReceiptData = () => {
    let path = ''
    if (isDate) {
      const dateFormatted = moment(new Date(date)).format('YYYY-MM-DD')
      const endDateFormatted = moment(new Date(date)).add(1, 'days').format('YYYY-MM-DD')
      if (selectLotto === '99999999' || selectLotto === '88888888') {
        path = `/receipts?${queryString.stringify({
          startDate: dateFormatted,
          endDate: endDateFormatted,
        })}&limit=20&page=${receiptsPage}`
      } else {
        path = `/receipts?${queryString.stringify({
          startDate: dateFormatted,
          endDate: endDateFormatted,
        })}&limit=20&refId=${selectLotto}&page=${receiptsPage}`
      }
    } else {
      if (selectLotto === '99999999' || selectLotto === '88888888') {
        path = `/receipts?limit=20&page=${receiptsPage}`
      } else {
        path = `/receipts?limit=20&refId=${selectLotto}&page=${receiptsPage}`
      }
    }
    setIsLoading(true)
    if (selectLotto === '') {
      fetchLottoOptions()
    }
    API.get('purchase', path)
      .then((res) => {
        // let sortedReceipts = []
        let sortedReceipts = _.get(res, 'detail', [])
        if (selectLotto === '99999999') {
          sortedReceipts = sortedReceipts.filter((obj) => obj.receiptDetail.lottoName.includes('ยี่กียอดธง'))
        }
        if (selectLotto === '88888888') {
          sortedReceipts = sortedReceipts.filter((obj) => obj.receiptDetail.lottoName.includes('นักษัตร'))
        }
        const lastPage = _.get(res, 'currentPage') === _.get(res, 'totalPages')
        setIsLastPage(lastPage)
        if (receiptsPage === 1) {
          setAllReceipts(sortedReceipts)
        }
        if (receiptsPage > 1) {
          setAllReceipts(allReceipts.concat(sortedReceipts))
        }
        // const purchaseAmount = _.sumBy(sortedReceipts, function (receipt) {
        //   return receipt.purchaseAmount
        // })
        // setAllPurchasesAmount(purchaseAmount)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
        setIsBottom(false)
      })
  }

  // useEffect(() => {
  //   if (auth.username) {
  //     fetchReceiptData()
  //   }
  // }, [auth.username])

  useEffect(() => {
    if (auth.username) {
      setReceiptsPage(1)
      setReceiptType('')
      if (receiptsPage === 1 && receiptType === '') {
        fetchReceiptData()
      }
    }
  }, [date, auth.username, selectLotto])

  useEffect(() => {
    let filteredReceipts = []
    if (receiptType === '') {
      filteredReceipts = allReceipts
    } else if (receiptType === 'notFinished') {
      filteredReceipts = allReceipts.filter((receipt) => receipt.status === 'confirm')
    } else if (receiptType === 'isFinished') {
      filteredReceipts = allReceipts.filter((receipt) => checkFinishReceipt(receipt))
    } else if (receiptType === 'prized') {
      filteredReceipts = allReceipts.filter((receipt) => checkPrizedReceipt(receipt))
    } else if (receiptType === 'cancelled') {
      filteredReceipts = allReceipts.filter(
        (receipt) =>
          receipt.status === 'cancelled' ||
          receipt.status === 'error' ||
          receipt.status === 'rejected' ||
          receipt.status === 'timeout'
      )
    }
    setFilteredReceipts(filteredReceipts)
    // if (selectLotto !== '' && selectLotto !== 'หวยทั้งหมด') {
    //   console.log('select')
    //   const filterLottoName = filteredReceipts.filter((obj) => obj.receiptDetail.lottoName === selectLotto)
    //   setFilteredReceipts(filterLottoName)
    // } else {
    //   console.log('selectAll')
    //   setFilteredReceipts(filteredReceipts)
    // }
  }, [allReceipts, receiptType])

  const _onClickFilterButton = (e, status) => {
    e.preventDefault()
    if (receiptType === status) {
      setReceiptType('')
    } else {
      setReceiptType(status)
    }
  }
  const _onSelectLotto = (e) => {
    const value = e.target.value
    console.log(value, 'value')
    setSelectLotto(value)
  }

  return (
    <div className='allReceipts page-top p-4 flex flex-col items-center mx-auto'>
      {/* SECTION 1: HEADER */}
      <Box containerClass='allReceipts__buttonBox mt-5'>โพยหวย</Box>
      <div className='transactionList__date' style={{ marginTop: '0px' }}>
        {/*<div className='transactionList__date-wrapper' style={{ marginRight: '5px' }}>*/}
        {/*  <p style={{ color: 'black' }}>วันเริ่มต้น</p>*/}
        {/*  <SingleDatePicker*/}
        {/*    date={startDate}*/}
        {/*    onDateChange={(date) => {*/}
        {/*      setStartDate(date)*/}
        {/*    }}*/}
        {/*    focused={startDateFocus}*/}
        {/*    onFocusChange={({ focused }) => setStartDateFocus(focused)}*/}
        {/*    id='startDate'*/}
        {/*    numberOfMonths={1}*/}
        {/*    noBorder={true}*/}
        {/*    readOnly*/}
        {/*    displayFormat={'DD/MM/YYYY'}*/}
        {/*    isOutsideRange={() => false}*/}
        {/*  />*/}
        {/*</div>*/}
        {isDate ? (
          <div className='transactionList__date-wrapper' style={{ margin: '5px' }}>
            <p className='mt-1' style={{ color: 'black' }}>
              วันที่
            </p>
            <DatePicker
              selected={date}
              onSelect={(date) => {
                setDate(date)
              }}
              onChange={() => {
                setSelectLotto('')
              }}
              focused={dateFocus}
              onFocusChange={({ focused }) => setDateFocus(focused)}
              id='date'
              dateFormat="dd/MM/yyyy"
            />
            {/* <SingleDatePicker
              date={date}
              onDateChange={(date) => {
                setDate(moment(date))
                setSelectLotto('')
              }}
              focused={dateFocus}
              onFocusChange={({ focused }) => setDateFocus(focused)}
              id='date'
              numberOfMonths={1}
              noBorder={true}
              readOnly
              displayFormat={'DD/MM/YYYY'}
              isOutsideRange={() => false}
            /> */}
          </div>
        ) : (
          <div className='transactionList__date-wrapper mt-8'>
            <Button
              text='กดเพื่อเลือกวัน'
              wrapperClassName='DateButton'
              // buttonBackground='#666666'
              onClick={() => {
                setIsDate(true)
              }}
            />
          </div>
        )}
      </div>
      <div className='transactionList__date' style={{ marginTop: '0px' }}>
        {/*<div className='transactionList__date-wrapper' style={{ marginRight: '5px' }}>*/}
        {/*  <p style={{ color: 'black' }}>วันเริ่มต้น</p>*/}
        {/*  <SingleDatePicker*/}
        {/*    date={startDate}*/}
        {/*    onDateChange={(date) => {*/}
        {/*      setStartDate(date)*/}
        {/*    }}*/}
        {/*    focused={startDateFocus}*/}
        {/*    onFocusChange={({ focused }) => setStartDateFocus(focused)}*/}
        {/*    id='startDate'*/}
        {/*    numberOfMonths={1}*/}
        {/*    noBorder={true}*/}
        {/*    readOnly*/}
        {/*    displayFormat={'DD/MM/YYYY'}*/}
        {/*    isOutsideRange={() => false}*/}
        {/*  />*/}
        {/*</div>*/}
        {isDate ? (
          <div className='transactionList__select' style={{ transform: 'translateY(-15px)', margin: '5px' }}>
            <select value={selectLotto || 0} onChange={(e) => _onSelectLotto(e)}>
              {lottoOptions?.map((item) => (
                <option key={item.lottoNumber} value={item.lottoNumber}>
                  {item.lottoName}
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </div>

      {/* SECTION 2: FIRST BOX */}
      <Box containerClass='allReceipts__box'>
        <Box containerClass='allReceipts__buttonTopic'>ยอดแทง</Box>
        <section className='allReceipts__todayResultBox'>
          <div className='allReceipts__todayResultBox-paid shine-box-base w-1/2 h-20 text-center ml-1'>
            <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยอดรวม</div>
            <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: 'blue' }}>
              {Math.round((allPurchasesAmount + Number.EPSILON) * 100) / 100}
            </div>
          </div>
          <div className='allReceipts__todayResultBox-paid2 shine-box-base w-1/2 h-20 text-center'>
            <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ออกผลแล้ว</div>
            <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: '#8be67c' }}>
              {_.countBy(allReceipts, (receipt) => checkFinishReceipt(receipt)).true || 0}
            </div>
          </div>
          <div className='allReceipts__todayResultBox-paid3 shine-box-base w-1/2 h-20 text-center mr-1'>
            <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยังไม่ออกผล</div>
            <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: '  #FF0000' }}>
              {_.countBy(allReceipts, (receipt) => checkFinishReceipt(receipt)).false || 0}
            </div>
          </div>
        </section>
        {/*<span className='allReceipts__note'>**เลื่อนลงจนสุดเพื่อดูเพิ่มค่ะ**</span>*/}
      </Box>

      {/* SECTION 2: RECEIPTS*/}
      <Box containerClass='allReceipts__box'>
        <Box containerClass='allReceipts__buttonTopic'>หวยทั้งหมด</Box>

        {/* SECTION 2.1: Filter Groups */}
        <div className='allReceipts__filterGroup flex p-1 filter-groups' style={{ marginTop: '10px' }}>
          <div className='allReceipts__filterGroup-button mb-1'>
            {[
              {
                text: <p>โพยถูกรางวัล</p>,
                backgroundColor: '#315A85',
                status: 'prized',
              },
              {
                text: (
                  <p>
                    โพยหวย<span>ที่ยังไม่ออกผล</span>
                  </p>
                ),
                backgroundColor: '#CC9884',
                status: 'notFinished',
              },
              {
                text: (
                  <p>
                    โพยหวย<span>ที่ออกผลแล้ว</span>
                  </p>
                ),
                backgroundColor: '#005731',
                status: 'isFinished',
              },
              {
                text: 'รายการไม่สำเร็จ',
                backgroundColor: '#961922',
                status: 'cancelled',
              },
            ].map(({ text, backgroundColor, status }, index) => (
              // Split 4 when > tablet, Split 2 when mobile
              <Button
                key={`receipt-${index}`}
                text={text}
                wrapperClassName='allReceipts__receiptsButtonWrapper'
                buttonClassName={`allReceipts__receiptsButtonWrapper-element allReceipts__receiptsButtonWrapper-element-${index} `}
                buttonBackground={backgroundColor}
                onClick={(e) => _onClickFilterButton(e, status)}
                isActive={receiptType === status}
              />
            ))}
          </div>
        </div>
        {/* SECTION 2.2: Receipts listing */}
        <div className='receipts-listing'>
          {filteredReceipts.map((receipt, index) => (
            <Box
              withShine={false}
              withFlare={false}
              containerClass='allReceipts__selectedLotto'
              key={`receipt-${index}`}
            >
              <div className='allReceipts__selectedLotto-firstLine'>
                {/*<img className='allReceipts__selectedLotto-firstLine-shine ' src={shine} alt='shine' />*/}
                <img
                  className='allReceipts__selectedLotto-firstLine-flag '
                  src={
                    receipt.receiptDetail.lottoName.substring(0, 5) === 'ยี่กี'
                      ? yodtong
                      : STOCK_LIST.find((o) => o.title === receipt.receiptDetail.lottoName)?.src || yodtong
                  }
                  alt='flags'
                />
                <span className='allReceipts__selectedLotto-firstLine-name'>{receipt.receiptDetail.lottoName}</span>
                <div
                  className='allReceipts__selectedLotto-firstLine-status'
                  style={
                    receipt.status === 'rewarded' && receipt.prizeAmount > 0
                      ? {
                          color: colorCode.lottoYellow,
                          border: `2px solid ${colorCode.lottoYellow}`,
                        }
                      : {
                          color: getReceiptStatusBackgroundColor(receipt.status),
                          border: `2px solid ${getReceiptStatusBackgroundColor(receipt.status)}`,
                        }
                  }
                >
                  {receipt.status === 'rewarded' && receipt.prizeAmount > 0
                    ? 'ถูกรางวัล'
                    : getReceiptStatusText(receipt.status)}
                </div>
                <br />
                <span className='allReceipts__selectedLotto-firstLine-index'>{`โพยเลขที่ #${receipt.receiptNumber}`}</span>
              </div>
              <div className='allReceipts__selectedLotto-secondLine allReceipts__selectedLotto-withSeparator'>
                <div className='allReceipts__selectedLotto-secondLine-left'>
                  <div className='allReceipts__selectedLotto-secondLine-left-pay'>
                    <span>ยอดแทง</span>
                    <span style={{ fontFamily: 'DESG7 Classic', color: colorCode.lottoBlue }} className='font-medium'>
                      {Math.round((receipt.purchaseAmount + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                  {/*<span>หวย{_.get(receipt, 'receiptDetail.lottoName', '')}</span>*/}
                  {/*<span>ประกาศผล {fetchDrawTime(receipt.refId) || 'กำลังโหลด'} </span>*/}
                </div>
                <div className='allReceipts__selectedLotto-secondLine-right'>
                  <div className='allReceipts__selectedLotto-secondLine-right-pay'>
                    <span>ยอดถูก</span>
                    <span
                      style={{
                        fontFamily: 'DESG7 Classic',
                        color: receipt.prizeAmount > 0 ? colorCode.lottoYellow : '',
                      }}
                      className='font-medium'
                    >
                      {Math.round((_.get(receipt, 'prizeAmount', '') + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                </div>
              </div>
              <div className='allReceipts__selectedLotto-thirdLine'>
                <div className='allReceipts__selectedLotto-thirdLine-left'>
                  <span className='allReceipts__selectedLotto-thirdLine-left-calendar'>
                    {moment(receipt.createdDate).subtract(7, 'hours').add(10, 'seconds').format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
                <Button
                  text='ดูรายละเอียด'
                  wrapperClassName='allReceipts__selectedLotto-thirdLine-right'
                  buttonClassName='allReceipts__selectedLotto-thirdLine-right-button'
                  buttonBackground='#666666'
                  onClick={() => {
                    history.push(`/receipts/${receipt.receiptNumber}`)
                  }}
                />
              </div>
              {receipt.receiptDetail.remark ? (
                <div className='allReceipts__selectedLotto-thirdLine' style={{ borderTop: '2px solid #efefef' }}>
                  <div className='allReceipts__selectedLotto-thirdLine-left bold text-xl'>
                    บันทึก: {receipt.receiptDetail.remark}
                  </div>
                </div>
              ) : null}
            </Box>
          ))}
          {isLoading && (
            <div className='loading-bottom-gif' style={{ marginTop: '80px' }}>
              <img className='loading-indicator-gif ' src={require('../../assets/loading.gif')} alt='loading' />
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}

export default React.memo(AllReceipt)
