export const STOCK_LIST = [
  { title: 'ฮานอย พิเศษ', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย เที่ยง', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม พิเศษ', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม VIP', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย VIP', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย HD', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย TV', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย กาชาด', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย EXTRA', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ลาว EXTRA', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ฮานอย รอบดึก', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../assets/lotto-flags/vietnam-flag.png') },
  { title: 'มาเลเซีย', src: require('../assets/lotto-flags/malaysia-flag.png') },
  { title: 'ลาว พัฒนา', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สตาร์', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวสตาร์ VIP', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว จำปาศักดิ์', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว HD', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดาว', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว TV', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว VIP', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว กาชาด', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดิจิตอล', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว เวียงจันทร์', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สามัคคี', src: require('../assets/lotto-flags/laos-flag.png') },
  { title: 'ดาวโจนส์ VIP', src: require('../assets/lotto-flags/usa-flag.png') },
  { title: 'ดาวโจนส์', src: require('../assets/lotto-flags/usa-flag.png') },
  { title: 'นิเคอิ รอบเช้า', src: require('../assets/lotto-flags/japan-flag.png') },
  { title: 'ไทย รอบเช้า', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไทย รอบเช้าตลาด', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'จีน รอบเช้า', src: require('../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง รอบเช้า', src: require('../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทยเที่ยง', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไต้หวัน', src: require('../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ รอบบ่าย', src: require('../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี', src: require('../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน รอบบ่าย', src: require('../assets/lotto-flags/china-flag.png') },
  { title: 'ไทย รอบบ่าย', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'ฮั่งเส็ง รอบบ่าย', src: require('../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทย รอบบ่ายตลาด', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'สิงคโปร์', src: require('../assets/lotto-flags/singapore-flag.png') },
  { title: 'ไทย เย็น', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'อินเดีย', src: require('../assets/lotto-flags/india-flag.png') },
  { title: 'อียิปต์', src: require('../assets/lotto-flags/egypt-flag.png') },
  { title: 'ไทยกลางคืน', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'รัสเซีย', src: require('../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ', src: require('../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน', src: require('../assets/lotto-flags/german-flag.png') },
  { title: 'หวยรัฐบาลไทย', src: require('../assets/lotto-flags/thailand-flag.png') },
  { title: 'หวยออมสิน', src: require('../assets/lotto-flags/bank-gsb.png') },
  { title: 'หวยธกส.', src: require('../assets/lotto-flags/tks.png') },
  { title: 'นิเคอิ(เช้า) VIP', src: require('../assets/lotto-flags/japan-flag.png') },
  { title: 'จีน(เช้า) VIP', src: require('../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(เช้า) VIP', src: require('../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไต้หวัน VIP', src: require('../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ(บ่าย) VIP', src: require('../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี VIP', src: require('../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน(บ่าย) VIP', src: require('../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(บ่าย) VIP', src: require('../assets/lotto-flags/hongkong-flag.png') },
  { title: 'สิงคโปร์ VIP', src: require('../assets/lotto-flags/singapore-flag.png') },
  { title: 'รัสเซีย VIP', src: require('../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ VIP', src: require('../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน VIP', src: require('../assets/lotto-flags/german-flag.png') },
  { title: 'ยี่กียอดธง ', src: require('../assets/lotto-flags/yodtong.png') },
  { title: 'รถฟอร์จูนเนอร์', src: require('../assets/fortunerIcon.jpg') },
]
