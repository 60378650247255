import React, { useEffect, useState, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import './resultTable.css'
import Marquee from "react-fast-marquee";
// import {
//   Animator,
//   ScrollContainer,
//   ScrollPage,
//   batch,
//   Fade,
//   FadeIn,
//   Move,
//   MoveIn,
//   MoveOut,
//   Sticky,
//   StickyIn,
//   ZoomIn,
// } from 'react-scroll-motion'
import AliceCarousel from 'react-alice-carousel'
// import Marquee from 'react-double-marquee'
// import useAnimateNumber from 'use-animate-number'

import { faColumns } from '@fortawesome/free-solid-svg-icons'

import { getCognitoProvider } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
// import { Calendar } from '../../components/Calendar/Calendar'
import Post from './Post'
import PostTable from './PostTable'
import Box from '../../components/Box/Box'
import BoxWhite from '../../components/Box/BoxWhite'
import Login from '../../components/Login/Login'
import SignUp from '../../components/SignUp/SignUp'
// import { COUNTRY_LIST } from '../../utils/countryList'
import { STOCK_LIST } from '../../utils/stockList'
import thailand from '../../assets/flags/thailand.png'
import gsb from '../../assets/flags/gsb.png'
import tks from '../../assets/flags/tks.png'
import yodtong from '../../assets/flags/yodtong.png'
// import login from '../../assets/login.png'
// import register from '../../assets/register.png'
// import { composePhoneNumber } from '../../utils/phoneNumber'
import 'react-alice-carousel/lib/alice-carousel.css'
import { FacebookIcon } from '../../components/Icon/Facebook'
import { LineIcon } from '../../components/Icon/Line'
// import mobile01 from '../../assets/pc01.jpg'
// // import pc01 from '../../assets/bar01.jpg'
import believeRich from '../../assets/believe-rich.png'
import facebook from '../../assets/facebook.png'
import galleryHome from '../../assets/gallery-home.png'
// import gsb from '../../assets/flags/gsb.png'
// import tks from '../../assets/flags/tks.png'
import hotNumber from '../../assets/hot-number.png'
import live from '../../assets/live.png'
import promotion from '../../assets/promotion.png'
import login from '../../assets/login.png'
import register from '../../assets/register.png'
import paatookFlag from '../../assets/flags/paatook-flag.png'
import storyHome from '../../assets/story-home.png'
import youtube from '../../assets/youtube.png'
// import mobile02 from '../../assets/mobile02.jpg'
// import pc02 from '../../assets/bar02.jpg'
// import mobile03 from '../../assets/mobile03.jpg'
// import pc03 from '../../assets/bar03.jpg'
// import mobile04 from '../../assets/mobile04.jpg'
// import pc04 from '../../assets/bar04.jpg'
// import mobile05 from '../../assets/mobile05.jpg'
// import congrat from '../../assets/congrats.jpg'
// import songkarn1 from '../../assets/songkarn1.jpg'
// import songkarn2 from '../../assets/songkarn2.jpg'
import congrat from '../../assets/banner/congrat.jpg'
import newPayRate from '../../assets/banner/newPayRate.jpg'
import maxRate from '../../assets/banner/maxRate.jpg'
import payRate from '../../assets/yodtongGirl/one.jpg'
import congratHorizon from '../../assets/banner/congratHorizon.jpg'
import ykRateHorizon from '../../assets/banner/ykRateHorizon.jpg'
import newPayRateHorizon from '../../assets/banner/newPayRateHorizon.jpg'
import maxRateHorizon from '../../assets/banner/maxRateHorizon.jpg'
import payRateHorizon from '../../assets/banner/payRateHorizon.jpg'
// import fortunerBanner from '../../assets/banner/fortunerBanner.jpg'
import agent from '../../assets/banner/agent.jpg'
import agentHorizon from '../../assets/banner/agentHorizon.jpg'
// import iconmonstrArrow65 from '../../assets/svg/iconmonstr-arrow-65.svg'
import { composePhoneNumber } from '../../utils/phoneNumber'

// const imagesMobile = [congrat, songkarn1, songkarn2, mobile01, mobile05]
const imagesMobile = [congrat, agent, newPayRate, maxRate, payRate]
const imagesPc = [congratHorizon, agentHorizon, newPayRateHorizon, maxRateHorizon, payRateHorizon]

const YEEKEE_ROUND = _.range(287).map((num) => num + 1)

const SlideshowMobile = () => {
  return (
    <div className='home mobile' style={{ marginBottom: '10px' }}>
      <div className='home__marquee'>
        <Marquee speed={60} delay={1} direction='left'>
          &nbsp;&nbsp; ยินดีต้อนรับสู่ยอดธงยอดเว็บครับ อัตราจ่ายสูงที่สุดในประเทศ ทั้งยังถอนไวใน 1 วิ อบอุ่นเป็นกันเอง ความสุขของท่านคือความสุขของเราครับ &nbsp;&nbsp;
        </Marquee>
      </div>
      <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={1500} mouseTracking>
        {imagesMobile.map((each, index) => (
          <img key={index} className='home__sliderimg' src={each} alt='slide'/>
        ))}
      </AliceCarousel>
    </div>
  )
}

const SlideshowPc = () => {
  return (
    <div className='home desktop' style={{ marginBottom: '10px', margin: 'auto' }}>
      {/*<div className='home__marquee'>*/}
      {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
      {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
      {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
      {/*    เว็บดีๆ จงบอกเพื่อน*/}
      {/*  </Marquee>*/}
      {/*</div>*/}
      <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={1500} mouseTracking>
        {imagesPc.map((each, index) => (
          <img key={index} className='home__sliderimg' src={each} alt='slide'/>
        ))}
      </AliceCarousel>
    </div>
  )
}

export default function HomeContainer() {
  const dispatch = useDispatch()
  const auth = useSelector(getAuth)
  const [countryLottos, setCountryLottos] = useState([])
  const [countryRewarded, setCountryRewarded] = useState([])
  const [stockLottos, setStockLottos] = useState([])
  const [stockRewarded, setStockRewarded] = useState([])
  const [dailyLottos, setDailyLottos] = useState([])
  const [dailyRewarded, setDailyRewarded] = useState([])
  const [vipLottos, setVipLottos] = useState([])
  const [vipRewarded, setVipRewarded] = useState([])
  const [posts, setPosts] = useState([])
  const [yeekeePosts, setYeekeePosts] = useState([])
  const [mobileCountry, setMobileCountry] = useState({})
  const [mobileStock, setMobileStock] = useState({})
  const [mobileDaily, setMobileDaily] = useState({})
  const [mobileVip, setMobileVip] = useState({})
  // const time = moment(new Date()).format('HH mm')
  // const [currentYeekee, setCurrentYeekee] = useState(1)
  // // const currentYeekee = Math.floor((parseInt(time.split(' ')[0]) * 60 + parseInt(time.split(' ')[1]) - 1) / 5)
  // const [mobileYeekeeNumber, setMobileYeekeeNumber] = useState(1)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  const [confirmPassword, setConfirmPassword] = useState(false)
  const history = useHistory()
  const cognitoProvider = { provider: getCognitoProvider() }
  // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn())
  // const FadeUp = batch(Fade(), Move(), Sticky())
  // console.log(currentYeekee, 'currentYeekee')
  // const [show, setShow] = useState(false)


  const dateNow = new Date().toLocaleDateString('th-TH', {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  })

  const dateToday = (
    <span
      style={{
        float: 'right',
        color: '#A98E70',
        background: '#231F20',
        fontSize: '20px',
        marginTop: '3px',
        borderRadius: '10px',
      }}
    >
      &nbsp;&nbsp;{dateNow}&nbsp;&nbsp;
    </span>
  )

  useEffect(() => {
    const date = moment(new Date()).format('DD/MM/YY')
    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยหุ้น`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        setStockLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยรายวัน`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        // console.log(data, 'data')
        setDailyLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยวีไอพี`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        setVipLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยต่างประเทศ`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        // console.log(res, 'detail')
        setCountryLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยไทย&limit=10`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            // if (lotto.lottoName !== 'หวยรัฐบาลไทย' && lotto.lottoName !== 'หวยออมสิน' && lotto.lottoName !== 'หวยธกส.') {
            if (lotto.lottoName === 'หวยรัฐบาลไทย') {
              newLotto.result.lotto6 = lotto.results['6S']
              newLotto.result.lotto3f = `${lotto.results['3F1']} ${lotto.results['3F2']}`
              newLotto.result.lotto3b = `${lotto.results['3B1']} ${lotto.results['3B2']}`
            }
            if (lotto.lottoName === 'หวยธกส.') {
              newLotto.result.lotto6 = lotto.results['6S']
            }
            if (lotto.lottoName === 'หวยออมสิน') {
              newLotto.result.lotto6 = `${lotto.results['6S1']} ${lotto.results['6S2']}`
            }
            // console.log(lotto.results, lotto.results['3A'])
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยยี่กี&limit=300`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          setYeekeePosts(data.reverse())
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยหุ้น&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const stockReward = data.map((obj) => obj.lottoName)
          setStockRewarded(stockReward)
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยรายวัน&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const dailyReward = data.map((obj) => obj.lottoName)
          setDailyRewarded(dailyReward)
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยวีไอพี&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const vipReward = data.map((obj) => obj.lottoName)
          setVipRewarded(vipReward)
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยต่างประเทศ&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const countryReward = data.map((obj) => obj.lottoName)
          setCountryRewarded(countryReward)
        }
      })
      .catch((err) => console.error(err))
  }, [])

  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    userConfirmPassword: '',
  })

  const handleLoginFacebook = async () => {
    await Auth.federatedSignIn({ provider: 'Facebook' })
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
        history.replace('/lottos')
      })
  }

  const handleLoginLine = async () => {
    await Auth.federatedSignIn(cognitoProvider)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
        history.replace('/lottos')
      })
  }

  const handleLogin = async (e, type) => {
    e.preventDefault()
    // console.log(window.location.hash)
    if (type === 'facebook') {
      await Auth.federatedSignIn({ provider: 'Facebook' }).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else if (type === 'line') {
      await Auth.federatedSignIn(cognitoProvider).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else {
      // if (newUser.username.length !== 10 || newUser.password.length < 8) {
      //   return
      // }

      try {
        // const newUsername = newUser.username
        const newUsername = composePhoneNumber('+66', newUser.username)
        await Auth.signIn(newUsername, newUser.password).then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
            console.log(requiredAttributes)
            const newPassword = newUser.password
            Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword, // the new password
            )
              .then((res) => {
                // at this time the user is logged in if no MFA required
                console.log(res)
              })
              .catch((e) => {
                console.log(e)
              })
          }
          console.log(user, 'user signIn')
          dispatch(setAuth({ user }))
        })
        history.replace('/lottos')
        // console.log(testUser, 'testUserrrrr Home Container')
        history.replace('/callback')
      } catch (e) {
        console.log(e, 'e message')
        if (e.message === 'Incorrect username or password.') {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          })
        } else if (e.message === 'User is not confirmed.') {
          setShowModal({ isShow: true, currentModal: 'verify' })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: e.message || '',
          })
        }
      }
    }
  }

  // ;<ScrollContainer>
  //   <ScrollPage page={0}>
  //     <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
  //       <span style={{ fontSize: '3em' }}>Let't me show you scroll animation 😀</span>
  //     </Animator>
  //   </ScrollPage>
  //   <ScrollPage page={1}>
  //     <Animator animation={ZoomInScrollOut}>
  //       <span style={{ fontSize: '3em' }}>I'm FadeUpScrollOut ✨</span>
  //     </Animator>
  //   </ScrollPage>
  //   <ScrollPage page={2}>
  //     <Animator animation={FadeUp}>
  //       <span style={{ fontSize: '3em' }}>I'm FadeUp ⛅️</span>
  //     </Animator>
  //   </ScrollPage>
  //   <ScrollPage page={3}>
  //     <div>
  //       <span style={{ fontSize: '3em' }}>
  //         <Animator animation={MoveIn(-1000, 0)}>Hello Guys 👋🏻</Animator>
  //         <Animator animation={MoveIn(1000, 0)}>Nice to meet you 🙋🏻‍♀️</Animator>- I'm Seonghyeok -
  //         <Animator animation={MoveOut(1000, 0)}>Good bye ✋🏻</Animator>
  //         <Animator animation={MoveOut(-1000, 0)}>See you 💛</Animator>
  //       </span>
  //     </div>
  //   </ScrollPage>
  //   <ScrollPage page={4}>
  //     <Animator animation={batch(Fade(), Sticky())}>
  //       <span style={{ fontSize: '3em' }}>Done</span>
  //       <span style={{ fontSize: '3em' }}>There's FadeAnimation, MoveAnimation, StickyAnimation, ZoomAnimation</span>
  //     </Animator>
  //   </ScrollPage>
  // </ScrollContainer>

  return (
    <>
      {_.isNull(auth.username) ? (
        <div className='home__banking-action mt-20'>
          <div className='rounded-full'>
            <button
              className='btn-bevel text-xl font-bold text-white rounded-full __register'
              onClick={() => {
                setShowModal({ isShow: true, currentModal: 'register' })
                setConfirmPassword(false)
              }}
            >
              {/*<img src={register} alt='register' />*/}
              <span>&nbsp;สมัครสมาชิก</span>
            </button>
            <button
              onClick={() => setShowModal({ isShow: true, currentModal: 'login' })}
              className='btn-bevel text-xl font-bold text-white rounded-full __login'
            >
              {/*<img src={login} alt='register' />*/}
              <span>&nbsp;เข้าสู่ระบบ</span>
            </button>
          </div>
        </div>
      ) : (
        // <div className='login btn-bevel text-xl font-bold mt-20 mb-3 sticky'>
        //   <div className='facebook'>
        //     <button
        //       onClick={handleLoginFacebook}
        //       className='btn-bevel text-xl py-1 px-5 font-bold'
        //       style={{ height: '150%' }}
        //     >
        //       <FacebookIcon />
        //       <span>เข้าสู่ระบบด้วยเฟสบุ๊ค</span>
        //     </button>
        //   </div>
        //   <div className='line'>
        //     <button
        //       onClick={handleLoginLine}
        //       className='btn-bevel text-xl py-1 px-5 font-bold'
        //       style={{ height: '150%' }}
        //     >
        //       <LineIcon />
        //       <span>เข้าสู่ระบบด้วยไลน์</span>
        //     </button>
        //   </div>
        // </div>
        <div className='home__banking-action mt-20'>
          <div>
            <Link to='/deposit'>
              <button className='px-10 py-2 btn-bevel green'>
                <span>+ ฝากเงิน</span>
              </button>
            </Link>
          </div>
          <div>
            <Link to='/withdraw'>
              <button className='px-10 py-2 btn-bevel red'>
                <span>- ถอนเงิน</span>
              </button>
            </Link>
          </div>
        </div>
      )}
      <SlideshowMobile/>
      <SlideshowPc/>

      {/*<div className='home__lucky-icon'>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <a href='https://www.facebook.com/patook24' target='_blank' rel='noopener noreferrer'>*/}
      {/*      <img*/}
      {/*        className='home__lucky-icon-wrapper-image'*/}
      {/*        style={{ transform: 'scale(1.3)' }}*/}
      {/*        src={hotNumber}*/}
      {/*        alt='เลขเด็ด'*/}
      {/*      />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>เลขเด็ด</span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <a href='https://www.facebook.com/ศรัทธาพารวย-101424405061306' target='_blank' rel='noopener noreferrer'>*/}
      {/*      <img*/}
      {/*        className='home__lucky-icon-wrapper-image'*/}
      {/*        style={{ transform: 'translateY(5px)' }}*/}
      {/*        src={believeRich}*/}
      {/*        alt='ศรัทธาพารวย'*/}
      {/*      />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>ศรัทธาพารวย</span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <a href='https://www.facebook.com/ป้าตุ๊ก-Live-110574874199240' target='_blank' rel='noopener noreferrer'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={live} alt='ผลหวยสด' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>ผลหวยสด</span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <a href='https://www.facebook.com/หวยป้าตุ๊ก-104134134878328' target='_blank' rel='noopener noreferrer'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={facebook} alt='facebook' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>เฟสหลัก</span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <Link to='/promotion'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={promotion} alt='โปรโมชั่น' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>โปรโมชั่น</span>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <Link to='/story'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={storyHome} alt='เรื่องราว' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>เรื่องราว</span>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <Link to='/gallery'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={galleryHome} alt='คลังรูป' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>คลังรูป</span>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='home__lucky-icon-wrapper'>*/}
      {/*    <a href=' https://youtube.com/channel/UCQOu_D2IMOtsVfyARhAoLVw' target='_blank' rel='noopener noreferrer'>*/}
      {/*      <img className='home__lucky-icon-wrapper-image' src={youtube} alt='youtube' />*/}
      {/*      <div className='home__lucky-icon-wrapper-label'>*/}
      {/*        <span>ยูทูป</span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className='munkmee'>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value === 10 ? 0 : value}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 1 === -1 ? 9 : value - 1}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 2 === -2 ? 8 : value - 2 === -1 ? 9 : value - 2}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 3 === -3 ? 7 : value - 3 === -2 ? 8 : value - 3 === -1 ? 9 : value - 3}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>*/}
      {/*        {value - 4 === -4 ? 6 : value - 4 === -3 ? 7 : value - 4 === -2 ? 8 : value - 4 === -1 ? 9 : value - 4}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* MOBILE YEEKEE*/}
      <div className='md:hidden mt-10'>
        <BoxWhite>
          <Box containerClass='allReceipts__buttonBox home__lotto__result__header '>ยี่กียอดธง {dateToday}</Box>
          <div>
            <table className='home_table'>
              <thead>
              <tr>
                <th>#</th>
                <th>หุ้น</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
              </thead>
              <tbody>
              {yeekeePosts.slice(0, 10).map((data) => (
                <tr>
                  <td>{data.lottoName.split(' ')[1]}</td>
                  <td>{data.results['3A']}</td>
                  <td>{data.results['2A']}</td>
                  <td>{data.results['2B']}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </BoxWhite>
      </div>
      {/* MOBILE STOCK*/}
      <div className='md:hidden mt-3'>
        <BoxWhite>
          <Box containerClass='allReceipts__buttonBox home__lotto__result__header '>ผลหวยหุ้น {dateToday}</Box>
          {/* <div className='home__lotto__mobile-stock-item-wrapper'> */}
          <div>
            <table className='home_table'>
              <thead>
              <tr>
                <th>#</th>
                <th>หุ้น</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
              </thead>
            </table>

            {STOCK_LIST.filter(function(el) {
              return stockLottos.concat(stockRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              const lookupItem = posts.find((post) => post.title === item.title) || item
              lookupItem['src'] = item.src
              return <PostTable {...lookupItem} key={index}/>
            })}
          </div>

          {/* <PostTable {...mobileStock} key='mobile-stock-single' /> */}
        </BoxWhite>
      </div>
      {/* MOBILE DAILY*/}
      <div className='md:hidden mt-3'>
        <BoxWhite>
          <Box containerClass='allReceipts__buttonBox home__lotto__result__header '>ผลหวยรายวัน {dateToday}</Box>
          {/*<div className='home__lotto__mobile-stock-item-wrapper'>*/}
          {/*  {STOCK_LIST.filter(function (el) {*/}
          {/*    return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0*/}
          {/*  }).map((item, index) => {*/}
          {/*    const lookupItem = posts.find((post) => post.title === item.title)*/}
          {/*      ? { ...posts.find((post) => post.title === item.title), src: item.src }*/}
          {/*      : item*/}
          {/*    const normalButton = lookupItem.title === mobileDaily.title ? 'normalButton' : ''*/}
          {/*    const rewardedButton = lookupItem.result ? ' rewardedButton' : ' whiteGrey'*/}
          {/*    const buttonClass = normalButton + rewardedButton*/}
          {/*    return (*/}
          {/*      <button*/}
          {/*        key={'mobile-stock-' + index + lookupItem.title}*/}
          {/*        className={`${buttonClass} home__lotto__mobile-stock-item-button`}*/}
          {/*        onClick={() => {*/}
          {/*          setMobileDaily(lookupItem)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        /!*<img className='inline-block' src={lookupItem.src} alt={lookupItem.title} />*!/*/}
          {/*        <span>{lookupItem.title}</span>*/}
          {/*      </button>*/}
          {/*    )*/}
          {/*  })}*/}
          {/*</div>*/}
          {/*<Post {...mobileDaily} key='mobile-stock-single' />*/}
          <div>
            <table className='home_table'>
              <thead>
              <tr>
                <th>#</th>
                <th>หุ้น</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
              </thead>
            </table>

            {STOCK_LIST.filter(function(el) {
              return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              const lookupItem = posts.find((post) => post.title === item.title) || item
              lookupItem['src'] = item.src
              return <PostTable {...lookupItem} key={index}/>
            })}
          </div>
        </BoxWhite>
      </div>
      {/* MOBILE VIP*/}
      <div className='md:hidden mt-3'>
        <BoxWhite>
          <Box containerClass='allReceipts__buttonBox home__lotto__result__header '>ผลหวยวีไอพี {dateToday}</Box>
          {/*<div className='home__lotto__mobile-stock-item-wrapper'>*/}
          {/*  {STOCK_LIST.filter(function (el) {*/}
          {/*    return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0*/}
          {/*  }).map((item, index) => {*/}
          {/*    const lookupItem = posts.find((post) => post.title === item.title)*/}
          {/*      ? { ...posts.find((post) => post.title === item.title), src: item.src }*/}
          {/*      : item*/}
          {/*    const normalButton = lookupItem.title === mobileVip.title ? 'normalButton' : ''*/}
          {/*    const rewardedButton = lookupItem.result ? ' rewardedButton' : ' whiteGrey'*/}
          {/*    const buttonClass = normalButton + rewardedButton*/}
          {/*    return (*/}
          {/*      <button*/}
          {/*        key={'mobile-stock-' + index + lookupItem.title}*/}
          {/*        className={`${buttonClass} home__lotto__mobile-stock-item-button`}*/}
          {/*        onClick={() => {*/}
          {/*          setMobileVip(lookupItem)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        /!*<img className='inline-block' src={lookupItem.src} alt={lookupItem.title} />*!/*/}
          {/*        <span>{lookupItem.title}</span>*/}
          {/*      </button>*/}
          {/*    )*/}
          {/*  })}*/}
          {/*</div>*/}
          {/*<Post {...mobileVip} key='mobile-stock-single' />*/}
          <div>
            <table className='home_table'>
              <thead>
              <tr>
                <th>#</th>
                <th>หุ้น</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
              </thead>
            </table>

            {STOCK_LIST.filter(function(el) {
              return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              const lookupItem = posts.find((post) => post.title === item.title) || item
              lookupItem['src'] = item.src
              return <PostTable {...lookupItem} key={index}/>
            })}
          </div>
        </BoxWhite>
      </div>
      {/* MOBILE COUNTRY*/}
      <div className='md:hidden mt-3'>
        <BoxWhite>
          <Box containerClass='allReceipts__buttonBox home__lotto__result__header '>ผลหวยต่างประเทศ {dateToday}</Box>
          <div>
            <table className='home_table'>
              <thead>
              <tr>
                <th>#</th>
                <th>หุ้น</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
              </thead>
            </table>

            {STOCK_LIST.filter(function(el) {
              return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              const lookupItem = posts.find((post) => post.title === item.title) || item
              lookupItem['src'] = item.src
              return <PostTable {...lookupItem} key={index}/>
            })}
          </div>
        </BoxWhite>
      </div>

      {/* DESKTOP YEEKEE*/}
      <div className='hidden md:flex home__lotto-suggestion mt-4'>
        <Box containerClass='px-0 my-8 '>
          <div className='flex flex-wrap home__lotto__mobile-stock-item-wrapper home__lotto-suggestion'>
            <Box containerClass='allReceipts__buttonBox home__lotto__result__header'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ยี่กียอดธง{' '}
              {dateToday}
            </Box>
            {/*{STOCK_LIST.filter(function (el) {*/}
            {/*  return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0*/}
            {/*}).map((item, index) => {*/}
            {/*  const lookupItem = posts.find((post) => post.title === item.title) || item*/}
            {/*  lookupItem['src'] = item.src*/}
            {/*  return <Post {...lookupItem} key={index} />*/}
            {/*})}*/}
            <div>
              <table className='home_table'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>หุ้น</th>
                  <th>3 ตัวบน</th>
                  <th>2 ตัวล่าง</th>
                </tr>
                </thead>
                <tbody>
                {yeekeePosts.slice(0, 10).map((data) => (
                  <tr>
                    <td>{data.lottoName.split(' ')[1]}</td>
                    <td>{data.results['3A']}</td>
                    <td>{data.results['2A']}</td>
                    <td>{data.results['2B']}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </div>

      {/* DESKTOP STOCK*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        {/* <h2 className='text-4xl text-bold text-white'>หวยหุ้น</h2> */}
        <Box containerClass='px-0 my-8 '>
          <div className='flex flex-wrap home__lotto__mobile-stock-item-wrapper home__lotto-suggestion'>
            <Box containerClass='allReceipts__buttonBox home__lotto__result__header'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผลหวยหุ้น{' '}
              {dateToday}
            </Box>
            <div>
              <table className='home_table'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>หุ้น</th>
                  <th>3 ตัวบน</th>
                  <th>2 ตัวล่าง</th>
                </tr>
                </thead>
              </table>

              {STOCK_LIST.filter(function(el) {
                return stockLottos.concat(stockRewarded).indexOf(el.title) >= 0
              }).map((item, index) => {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index}/>
              })}
            </div>
          </div>
        </Box>
      </div>
      {/* DESKTOP DAILY*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        {/* <h2 className='text-4xl text-bold text-white'>หวยหุ้น</h2> */}
        <Box containerClass='px-0 my-8 '>
          <div className='flex flex-wrap home__lotto__mobile-stock-item-wrapper home__lotto-suggestion'>
            <Box containerClass='allReceipts__buttonBox home__lotto__result__header'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผลหวยรายวัน{' '}
              {dateToday}
            </Box>
            {/*{STOCK_LIST.filter(function (el) {*/}
            {/*  return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0*/}
            {/*}).map((item, index) => {*/}
            {/*  const lookupItem = posts.find((post) => post.title === item.title) || item*/}
            {/*  lookupItem['src'] = item.src*/}
            {/*  return <Post {...lookupItem} key={index} />*/}
            {/*})}*/}
            <div>
              <table className='home_table'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>หุ้น</th>
                  <th>3 ตัวบน</th>
                  <th>2 ตัวล่าง</th>
                </tr>
                </thead>
              </table>

              {STOCK_LIST.filter(function(el) {
                return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0
              }).map((item, index) => {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index}/>
              })}
            </div>
          </div>
        </Box>
      </div>
      {/* DESKTOP VIP*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        {/* <h2 className='text-4xl text-bold text-white'>หวยหุ้น</h2> */}
        <Box containerClass='px-0 my-8 '>
          <div className='flex flex-wrap home__lotto__mobile-stock-item-wrapper home__lotto-suggestion'>
            <Box containerClass='allReceipts__buttonBox home__lotto__result__header'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผลหวยวีไอพี{' '}
              {dateToday}
            </Box>
            {/*{STOCK_LIST.filter(function (el) {*/}
            {/*  return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0*/}
            {/*}).map((item, index) => {*/}
            {/*  const lookupItem = posts.find((post) => post.title === item.title) || item*/}
            {/*  lookupItem['src'] = item.src*/}
            {/*  return <Post {...lookupItem} key={index} />*/}
            {/*})}*/}
            <div>
              <table className='home_table'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>หุ้น</th>
                  <th>3 ตัวบน</th>
                  <th>2 ตัวล่าง</th>
                </tr>
                </thead>
              </table>

              {STOCK_LIST.filter(function(el) {
                return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0
              }).map((item, index) => {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index}/>
              })}
            </div>
          </div>
        </Box>
      </div>
      {/* DESKTOP COUNTRY*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        <Box containerClass='px-0 my-8 '>
          <div className='flex flex-wrap home__lotto__mobile-stock-item-wrapper home__lotto-suggestion'>
            <Box containerClass='allReceipts__buttonBox home__lotto__result__header'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผลหวยต่างประเทศ{' '}
              {dateToday}
            </Box>
            {/*{STOCK_LIST.filter(function (el) {*/}
            {/*  return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0*/}
            {/*}).map((item, index) => {*/}
            {/*  const lookupItem = posts.find((post) => post.title === item.title) || item*/}
            {/*  lookupItem['src'] = item.src*/}
            {/*  return <Post {...lookupItem} key={index} />*/}
            {/*})}*/}
            <div>
              <table className='home_table'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>หุ้น</th>
                  <th>3 ตัวบน</th>
                  <th>2 ตัวล่าง</th>
                </tr>
                </thead>
              </table>

              {STOCK_LIST.filter(function(el) {
                return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0
              }).map((item, index) => {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index}/>
              })}
            </div>
          </div>
        </Box>
      </div>

      {!auth.username || showModal.currentModal === 'verify' ? (
        <>
          <Login
            showModal={showModal}
            setShowModal={setShowModal}
            handleLogin={handleLogin}
            setNewUser={setNewUser}
            newUser={newUser}
          />
          <SignUp
            showModal={showModal}
            setShowModal={setShowModal}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            handleLogin={handleLogin}
            setNewUser={setNewUser}
            newUser={newUser}
          />
        </>
      ) : null}
    </>
  )
}
