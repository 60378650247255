import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { API } from 'aws-amplify'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import _get from 'lodash/get'
import moment from 'moment'
import ModalImage from 'react-modal-image'
import ZodiacModal from './ZodiacModal'

// CHIP
import {
  fiveChip,
  tenChip,
  twentyChip,
  fiftyChip,
  hundredChip,
  fiveHundredChip,
  anyChip,
} from '../../utils/zodiac_util.js'
import rulebook_img from '../../assets/angalong/element/rule-book.png'
import zodiacRule from '../../assets/zodiac/element/zodiacRule.jpg'

import './Zodiac.scss'
// import homeIconActive from '../../components/navigator/assets/home-active.png'
// import homeIcon from '../../components/navigator/assets/dong-home.png'
import { clearCart, updateAmount } from '../../redux/actions/cart'
import { getWallet } from '../../redux/selectors/wallet'
import { setWalletCredit } from '../../redux/actions/wallet'
import { getAuth } from '../../redux/selectors/auth'
import { setReceipt } from '../../redux/actions/receipts'

const Zodiac = () => {
  const [currentChip, setCurrentChip] = useState(5)
  const [activeCard, setActiveCard] = useState([1, 1, 1])
  const [rewardCard, setRewardCard] = useState([0, 0, 0])
  const [previousBuy, setPreviousBuy] = useState([])
  const [isUndo, setIsUndo] = useState(false)
  const [isBuy, setIsBuy] = useState(false)
  const [buyHistory, setBuyHistory] = useState([])
  const [buyOne, setBuyOne] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 })
  const [lastBuyOne, setLastBuyOne] = useState({})
  // const [second, setSecond] = useState(0)
  const [isAuto, setIsAuto] = useState(false)
  const [isMultiply, setIsMultiply] = useState(false)
  const [createReceipt, setCreateReceipt] = useState({})
  const [buyZodiac, setBuyZodiac] = useState({})
  const [walletValidate, setWalletValidate] = useState(null)
  const [lotto, setLotto] = useState({})
  const [isBuying, setIsBuying] = useState(false)
  const [diffTime, setDiffTime] = useState('--:--:--')
  const [isBlock, setIsBlock] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [rewardZodiac, setRewardZodiac] = useState(['97', '98', '99'])
  // const [isAnotherRound, setIsAnotherRound] = useState(0)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })

  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0)
  const sumBuyOne = sumValues(buyOne)
  const purchaseAmount = sumBuyOne

  let history = useHistory()
  const dispatch = useDispatch()
  const walletCredit = useSelector((state) => _.get(state, 'wallet.credit', null))
  const auth = useSelector(getAuth)

  const disableKeyBoard = () => {
    document.onkeydown = (e) => {
      console.log('test')
      return false
    }
  }

  disableKeyBoard()

  const handleBuyZodiac = (type, amount) => {
    setIsUndo(false)
    setIsBuy(true)
    // console.log(type)
    if (type < 13) {
      setBuyOne((prevState) => ({
        ...prevState,
        [type]: prevState[type] + amount >= 500 ? 500 : prevState[type] + amount,
      }))
    }

    // const newPriceArray = priceArray.map((obj) => {
    //   if (obj.type === type && obj.detail === detail && obj.stamp === stamp) {
    //     obj.amount = amount
    //     dispatch(updateAmount({ purchaseItem: obj }))
    //   }
    //   return obj
    // })
    // setIsPriceChange(true)
    // setPriceArray(newPriceArray)
    // console.log('newPriceArray >>', newPriceArray)
  }

  const possibleChip = [0, 5, 10, 20, 50, 100, 500]

  // console.log(buyHistory)

  const handleChip = (buy, type) => {
    // setIsAllSamePrice(false)

    if (buy === 'one') {
      if (buyOne[type] === 5) {
        return fiveChip
      }
      if (buyOne[type] === 10) {
        return tenChip
      }
      if (buyOne[type] === 20) {
        return twentyChip
      }
      if (buyOne[type] === 50) {
        return fiftyChip
      }
      if (buyOne[type] === 100) {
        return hundredChip
      }
      if (buyOne[type] === 500) {
        return fiveHundredChip
      }
      return anyChip
    }

    if (buy === 'current') {
      if (currentChip === 5) {
        return fiveChip
      }
      if (currentChip === 10) {
        return tenChip
      }
      if (currentChip === 20) {
        return twentyChip
      }
      if (currentChip === 50) {
        return fiftyChip
      }
      if (currentChip === 100) {
        return hundredChip
      }
      if (currentChip === 500) {
        return fiveHundredChip
      }
      return null
    }
  }

  const resetButton = () => {
    setIsBuy(false)
    setIsUndo(true)
    setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 })
    setBuyHistory([
      { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 },
    ])
    setPreviousBuy([])
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const undoButton = () => {
    if (buyHistory.length > 1) {
      setIsUndo(true)
      if (isMultiply) {
        const lastOneBuy = previousBuy.findLast((element) => Object.keys(element).length === 12)
        // console.log(lastOneBuy, 'newObj')
        setBuyOne(lastOneBuy)
        setBuyHistory((prevState) => prevState.slice(0, -1))
        setPreviousBuy((prevState) => prevState.slice(0, -1))
      } else {
        const lastTypeBuy = Object.keys(buyHistory[buyHistory.length - 1]).length
        // console.log(lastTypeBuy, 'lastTysdfgsdfgsdfgsdfgpeBuy')
        if (lastTypeBuy === 12) {
          // const removeBuy = buyHistory.splice(-1)
          // console.log('remove last buyOne')
          const lastBuy = previousBuy.findLast((element) => Object.keys(element).length === 12)
          // console.log(lastBuy, 'newObj')
          setBuyOne(lastBuy)
          setBuyHistory((prevState) => prevState.slice(0, -1))
          setPreviousBuy((prevState) => prevState.slice(0, -1))
        }
      }
    }
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const multiplyButton = () => {
    setIsUndo(false)
    setIsBuy(true)
    setIsMultiply(true)
    const buyOneType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    for (let i = 0; i < buyOneType.length; i++) {
      setBuyOne((prevState) => ({
        ...prevState,
        [buyOneType[i]]: prevState[buyOneType[i]] * 2 >= 500 ? 500 : prevState[buyOneType[i]] * 2,
      }))
    }
  }

  const luckyButton = () => {
    setIsUndo(false)
    setIsBuy(true)
    const buyOneType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const random = Math.floor(Math.random() * buyOneType.length)
    setBuyOne((prevState) => ({
      ...prevState,
      [buyOneType[random]]: prevState[buyOneType[random]] + currentChip,
    }))
  }

  useEffect(() => {
    if (!isUndo) {
      setPreviousBuy(buyHistory)
      setBuyHistory((prevState) => [...prevState, buyOne])
    }
  }, [buyOne])


  // console.log(buyHistory, 'buyHistory')
  // console.log(previousBuy, 'previousBuy')

  useEffect(() => {
    const minuteNow = moment().minute()
    const secondNow = moment().second() % 60
    // console.log(minuteNow, 'minuteNow')
    // console.log(secondNow, 'secondNow')
    const isEnterAtZero = secondNow === 0 && diffTime !== '59:59' && diffTime && '59:58' && diffTime !== '59:57'
    if ((minuteNow + 1) % 3 === 0 && !isEnterAtZero) {
      const newSecond = moment().second() % 60
      console.log('close')
      setIsClose(true)

      let countdownToReward = 0

      const countdownToOpen = 60 - newSecond
      // console.log(newSecond, countdownToOpen, 'second moment in isBlock')

      if (countdownToOpen > 40) {
        countdownToReward = countdownToOpen - 40
      }

    // console.log(countdownToReward, 'countdownToReward')

      if (countdownToReward > 0 && lotto.drawTimeStr) {
        setTimeout(() => {
          API.get('lotto', `/results?status=rewarded&lottoGroup=สิบสองนักษัตร&limit=1`)
            .then((res) => {
              // console.log(res.detail[0], 'reward lottos')
              const rewardedLotto = res.detail[0]
              if (rewardedLotto.lottoName === lotto.lottoName) {
                const resultZodiac = rewardedLotto.results
                // console.log(resultZodiac, 'result Zodiac')

                const fifteen = rewardedLotto.results['6S'].substr(0,2)
                const twenty = rewardedLotto.results['6S'].substr(2,4)
                const realReward = rewardedLotto.results['2B']

                // console.log(fifteen, twenty, realReward, 'reward Zodiac')

                setRewardZodiac([fifteen, twenty])

                setTimeout(() => {
                  setRewardZodiac([fifteen, twenty, realReward])
                }, 20000)
              }
              // if (res.detail.length > 0) {
              //   const data = res.detail[0]
              //   setLotto(data)
              // }
            })
            .catch((err) => console.error(err))
        }, countdownToReward * 1000)
      }


      setTimeout(() => {
        API.get('lotto', `/lottos?status=open&lottoGroup=สิบสองนักษัตร&limit=1`)
          .then((res) => {
            // console.log(res.detail, 'res result from countdown')
            if (res.detail.length > 0) {
              const data = res.detail[0]
              setLotto(data)
            }
          })
          .catch((err) => console.error(err))
        setIsBlock(false)
        setIsClose(false)
        setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 })
        setRewardZodiac([])
      }, countdownToOpen * 1000)
    } else {
      if (!lotto.drawTimeStr) {
        console.log('open')
        setIsClose(false)
        API.get('lotto', `/lottos?status=open&lottoGroup=สิบสองนักษัตร&limit=1`)
          .then((res) => {
            // console.log(res.detail, 'res result from first fetch')
            if (res.detail.length > 0) {
              const data = res.detail[0]
              setLotto(data)
            }
          })
          .catch((err) => console.error(err))
      }
    }
  }, [isBlock])

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const secondNow = new Date().getMinutes() * 60 + new Date().getSeconds()
  //     setSecond(secondNow) // <-- Change this line!
  //   }, 1000)
  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [])


  useEffect(() => {
    if (lotto.drawDateStr) {
      const newDrawDateStr = lotto.drawDateStr.replace(/\//g, '-')
      const drawDateTime = moment(newDrawDateStr + ' ' + lotto.drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
      const realDrawTimeMoment = moment(drawDateTime).subtract(lotto.closeMinutesBeforeDraw, 'minutes')
      let withOutHours = ''

      // console.log('isRun')


      const differ = realDrawTimeMoment.diff(moment())
      let myInterval
      const createInverval = (drawTimeMoment) => {
        const updateTime = (drawTimeMoment) => {
          const differ = drawTimeMoment.diff(moment())
          withOutHours = moment.utc(differ).format('mm:ss')


          setDiffTime(withOutHours)


          if (differ < 0 || differ === 0) {
            clearInterval(myInterval)
          }
        }

        myInterval = setInterval(() => {
          updateTime(drawTimeMoment)
        }, 1000)
      }
      if (differ < 0 || differ > 120000) {
        setIsBlock(true)
      } else {
        createInverval(realDrawTimeMoment)
      }
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [lotto])

  useEffect(() => {
    if (diffTime === '59:59' || diffTime === '59:58' || diffTime === '59:57') {
      // console.log(moment(), 'diffTime SET isBlock')
      setIsBlock(true)
    }
  }, [diffTime])


  // console.log(second, 'second')
  // console.log(lastBuyOne, lastBuyPair, 'lasdfalsdkfjal;ksdjfas')

  const onClickConfirmPurchase = () => {
    if (!auth.username) {
      // Not login, so cannot purchase
      Swal.fire({
        icon: 'error',
        // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
        // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
        title: 'คุณยังไม่ได้เข้าสู่ระบบ',
        text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'เข้าสู่ระบบ',
      })
      return
    }
    setIsBuying(true)
    setWalletValidate(null)
    setCreateReceipt({})
    setBuyZodiac({})
    // const walletBalance = fetchWallet()
    // console.log(walletBalance, purchaseAmount)
    // if (walletBalance >= purchaseAmount) {
    const getLottoData = async () => {
      const response = await API.get('lotto', '/lottos?lottoGroup=สิบสองนักษัตร&status=open&limit=1')
      console.log(response, 'getLottoDataResponce')
      if (response.detail.length > 0) {
        const data = response.detail || null
        if (!data || _.isEmpty(data)) {
          return Swal.fire({
            icon: 'error',
            title: `เกิดข้อผิดพลาด`,
            text: 'โปรดกลับสู่หน้าหลัก',
            showCancelButton: true,
            confirmButtonColor: '#B6964E',
            cancelButtonColor: '#C0C0C0',
            confirmButtonText: 'สู่ 12 นักษัตร',
          }).then((result) => {
            if (result.value) {
              setIsBuying(false)
              history.push('/zodiac')
            }
          })
        } else {
          setCreateReceipt({
            allBuy: buyOne,
            lottoData: data[0],
          })
        }
      } else {
        return Swal.fire({
          icon: 'error',
          title: `เกิดข้อผิดพลาด`,
          text: 'โปรดกลับสู่หน้าหลัก',
          confirmButtonColor: '#B6964E',
          confirmButtonText: 'สู่ 12 นักษัตร',
        }).then(() => {
          // console.log(result)
          setIsBuying(false)
          history.push('/zodiac')
        })
      }
    }
    getLottoData().catch(console.error)
  }

  // console.log(diffTime)

  const purchaseZodiac = async () => {
    const lottoData = createReceipt.lottoData

    if (_.isEmpty(lottoData)) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'โปรดลองใหม่อีกครั้ง',
      })
      setIsBuying(false)
      return
    }

    // setIsPurchasing(true) // To disable the "ส่งโพย" button while processing
    // console.log(lottoData, 'lottoData')

    const lottoDetail = {
      refId: lottoData.lottoNumber,
      lottoGroup: lottoData.lottoGroup,
      lottoName: lottoData.lottoName,
      drawDateStr: lottoData.drawDateStr,
    }

    const purchaseZodiacAmount = Math.round((purchaseAmount + Number.EPSILON) * 100) / 100

    // console.log(sumPurchase, 'sumPurchase')
    // console.log(purchaseAmount, 'purchaseAmount2')
    // console.log(purchaseItems, 'purchaseItems2')

    let purchaseItems = []
    // console.log(createReceipt, 'create Receipt')
    const purchaseAllAlone = createReceipt.allBuy

    for (const property in purchaseAllAlone) {
      if (purchaseAllAlone[property] > 0) {
        purchaseItems.push(
          {
            type: '2B',
            detail: property.padStart(2, '0'),
            amount: purchaseAllAlone[property] * 0.4,
            payRate: 25,
          },
          {
            type: '3B',
            detail: property.padStart(3, '0'),
            amount: purchaseAllAlone[property] * 0.2,
            payRate: 25,
          },
          {
            type: '2A',
            detail: property.padStart(2, '0'),
            amount: purchaseAllAlone[property] * 0.2,
            payRate: 50,
          },
          {
            type: '3F',
            detail: property.padStart(3, '0'),
            amount: purchaseAllAlone[property] * 0.2,
            payRate: 100,
          },
        )
      }
    }

    // console.log(purchaseItems, 'purchaseItems')

    const data = {
      playerUsername: auth.username, // This is Hash name, not human friendly name
      playerName: auth.username,
      receiptType: 'lotto',
      refId: lottoDetail.refId, // Earth See here
      // refId: '0', // Earth See here
      receiptDetail: {
        lottoGroup: lottoDetail.lottoGroup,
        lottoName: lottoDetail.lottoName,
        drawDateStr: lottoDetail.drawDateStr,
        remark: '',
      },
      purchaseItems,
      purchaseZodiacAmount,
      timeout: 120,
    }

    if (data.purchaseAmount > 6000) {
      setIsBuying(false)
      Swal.fire({
        icon: 'error',
        // title: 'กรุณาทำรายการใหม่อีกครั้ง',
        title: 'กรุณาทำรายการใหม่อีกครั้ง',

        // html: 'แทงขั้นสูงสุดต่อครั้ง 100,000.00 </br> แทงขั้นสูงสุดต่อเลข 2,000.00',
        html: 'แทงขั้นสูงสุดต่อครั้ง 6,000 </br> แทงขั้นสูงสุดต่อเลข 500',

        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'ทำรายการใหม่',
        confirmButtonText: 'ทำรายการใหม่',
      })
    } else if (purchaseZodiacAmount > walletCredit) {
      setIsBuying(false)
      Swal.fire({
        icon: 'error',
        // title: 'เงินในกระเป๋าไม่พอ',
        title: 'เงินในกระเป๋าไม่พอ',

        // confirmButtonText: 'ฝากเงิน',
        confirmButtonText: 'Deposit',
      }).then((result) => {
        setIsBuying(false)
        if (result.value) {
          history.push('/deposit')
        }
      })
    } else {
      // About to buy zodiac !!
      const createBuyZodiac = await API.post('purchase', '/receipts', { body: data })
      if (createBuyZodiac.code === 0) {
        const setReceipt = _.get(createBuyZodiac, 'detail', {})
        // console.log(setReceipt, 'setReceipt')
        setBuyZodiac(setReceipt)
      } else {
        setIsBuying(false)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'การซื้อไม่สำเร็จ',
        })
      }

      // console.log(data, 'data to buy')

    }
  }

  useEffect(() => {
    if (!_.isEmpty(createReceipt)) {
      // console.log(createReceipt, 'createReceipt')
      purchaseZodiac().catch(console.error)
    }
  }, [createReceipt])

  useEffect(() => {
    if (!_.isEmpty(buyZodiac)) {
      // console.log(buyZodiac, 'buyZodiac')
      if (!buyZodiac.receiptNumber || !buyZodiac.receiptDetail.lottoName) {
        setIsBuying(false)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
        })
      } else {
        const fetchWallet = async () => {
          if (auth.username) {
            const walletFetch = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (walletFetch.code === 0) {
              const credit = _get(walletFetch, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                }),
              )
              setWalletValidate(credit)
            } else {
              setIsBuying(false)
              Swal.fire({
                icon: 'error',
                // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
                // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
                title: 'คุณยังไม่ได้เข้าสู่ระบบ',
                text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'เข้าสู่ระบบ',
              })
            }
          }
        }
        fetchWallet().catch(console.error)
      }
    }
  }, [buyZodiac])

  useEffect(() => {
    if (walletValidate > 0) {
      // console.log(walletValidate, 'walletValidate')
      // console.log(buyZodiac, 'buyZodiac in walletValidate')
      const buyZodiacAmount = buyZodiac.purchaseAmount
      if (walletValidate >= buyZodiacAmount) {
        const confirmReceipt = async () => {
          const receiptNumber = buyZodiac.receiptNumber
          const receiptPurchaseAmount = buyZodiac.purchaseAmount
          const lottoName = buyZodiac.receiptDetail.lottoName
          const confirmZodiac = await API.post('purchase', `/receipts/${receiptNumber}/confirm`)
          if (confirmZodiac.code === 0) {
            // Success
            setIsBuying(false)
            Swal.fire({
              // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/thank-st.png`,
              title: 'ซื้อสำเร็จ',
              text: `${lottoName} ทั้งหมด ${numeral(receiptPurchaseAmount).format('0,0')} บาทครับ`,
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              confirmButtonText: 'แทงต่อ',
              cancelButtonColor: ' #A9A9A9',
              cancelButtonText: 'ตรวจสอบโพย',
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`/zodiac`)
              } else {
                history.push(`/receipts/${receiptNumber}`)
              }
            })
          } else {
            setIsBuying(false)
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด',
              text: confirmZodiac.detail || 'Please contact admin',
            })
          }
        }
        const refreshWallet = async () => {
          if (auth.username) {
            const responseWallet = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (responseWallet.code === 0) {
              const credit = _get(responseWallet, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                }),
              )
            }
          } else {
            // Not login, so cannot purchase
            setIsBuying(false)
            Swal.fire({
              icon: 'error',
              // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
              // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
              title: 'คุณยังไม่ได้เข้าสู่ระบบ',
              text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              // confirmButtonText: 'เข้าสู่ระบบ',
            })
          }
        }
        confirmReceipt().catch(console.error)
        refreshWallet().catch(console.error)
      } else {
        setIsBuying(false)
        Swal.fire({
          icon: 'error',
          // title: 'ยอดเงินของคุณไม่เพียงพอ',
          title: 'ยอดเงินของคุณไม่เพียงพอ',
          // confirmButtonText: 'ฝากเงิน',
          confirmButtonText: 'ฝากเงิน',
          //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
          //     '0,0.00'
          //   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
          // }).then((result) => {
          //   if (result.value) {
          //     history.push('/deposit')
          //   }
          text: `ยอดเงินที่คงเหลือได้ (${numeral(walletValidate).format('0,0.00')} บาท), ยอดเงินที่จะเล่น ${buyZodiacAmount}`,
        }).then((result) => {
          if (result.value) {
            history.push('/deposit')
          }
        })
      }
    }
  }, [walletValidate])

  // console.log(second, 'second')
  // console.log(buyOne, 'buyOne')

  // const onTest = () => {
  //   const one = ~~(Math.random() * 6) + 1
  //   const two = ~~(Math.random() * 6) + 1
  //   const three = ~~(Math.random() * 6) + 1
  //
  //   setActiveCard(() => ([one, two, three]))
  // }

  // console.log(activeCard, 'activeCard')
  const allStar = ['ชวด', 'ฉลู', 'ขาล', 'เถาะ', 'มะโรง', 'มะเส็ง', 'มะเมีย', 'มะแม', 'วอก', 'ระกา', 'จอ', 'กุน']

  // console.log(moment().second(), 'momentadsfasdfasdf')

  const active = allStar[((moment().second() % 12) - 1) < 0 ? 11 : (moment().second() % 12) - 1]

  // console.log(active, (second % 12) - 1, 'active')

  const zodiacDice = (order) => {
    // const currentDice = place === 'first' ? activeCard[0] : place === 'second' ? activeCard[1] : activeCard[2]
    // console.log(second, currentDice)
    const orderNumber = (allStar.indexOf(order) + 1).toString().padStart(2, '0')

    return (
      <div
        className={`${rewardZodiac[2] === orderNumber ? 'price' : ''}`}
        style={{position: 'relative'}}
      >
         <span
           className={`fifteen-multiple ${(rewardZodiac[0] !== rewardZodiac[1] && rewardZodiac[0] === orderNumber) ? '' : 'no-chip'
           }`}
         >
              X15
            </span>

        <span
          className={`fifteen-multiple ${(rewardZodiac[0] !== rewardZodiac[1] && rewardZodiac[1] === orderNumber) ? '' : 'no-chip'
          }`}
        >
              X20
            </span>
        <span
          className={`fifteen-multiple ${(rewardZodiac[0] === rewardZodiac[1] && rewardZodiac[0] === orderNumber) ? '' : 'no-chip'
          }`}
        >
              X30
            </span>
        {rewardZodiac[2] === orderNumber && isBlock && lotto.lottoNumber  ? <img className='active super-glow'
          src={require(`../../assets/zodiac/star/${order}.jpg`)} alt='zodiac-star-active'/> : active !== order || isBlock || !lotto.lottoNumber ? <img className='normal' src={require(`../../assets/zodiac/star/${order}.png`)}
                                   alt='zodiac-star'/> :
        <img className='active glow'
             src={require(`../../assets/zodiac/star/${order}.jpg`)} alt='zodiac-star-active'/> }
      </div>
    )
  }

  const zodiacBoard = (order) => {
    const orderNumber = (allStar.indexOf(order) + 1).toString().padStart(2, '0')
    // console.log(order, orderNumber)
    return (
      <div
        className={`box1-inner ${rewardZodiac[2] === orderNumber ? 'price' : ''}`}
        onClick={() => handleBuyZodiac(+orderNumber, currentChip)}
      >
        <img src={require(`../../assets/zodiac/star/${order}.png`)} alt='zodiac-board'/>
        <img
          src={rewardZodiac[2] === orderNumber ? anyChip : handleChip('one', +orderNumber)}
          className={`top-side-chip-zodiac ${buyOne[+orderNumber] > 0 ? '' : 'no-chip'}`}
          alt='top-side-chip-zodiac'
        />
        {/*<span*/}
        {/*  className={`span-side-chip-zodiac ${!_.includes(possibleChip, buyOne[+orderNumber]) && buyOne[+orderNumber] > 0 ? '' : 'no-chip'*/}
        {/*  }`}*/}
        {/*>*/}
        <span
          className={`span-side-chip-zodiac ${buyOne[+orderNumber] > 0 && (!_.includes(possibleChip, buyOne[+orderNumber])  || rewardZodiac[2] === orderNumber) ? '' : 'no-chip'
          }`}
        >
              {rewardZodiac[2] === orderNumber ? buyOne[+orderNumber] * 10 : buyOne[+orderNumber]}
            </span>
      </div>
    )
  }
  return (
    <div className='zodiac mt-20'>
      <div className='display'>
        <div className="status">
          <div className='countdown'>
            <div className='__timer text-2xl' style={{ border: 'none', color: 'yellow' }}>{isBlock || isClose ? 'ปิดรับ' : diffTime}</div>

            {/*<img*/}
            {/*  className='home__lotto-title-refresh'*/}
            {/*  onClick={() => window.location.reload(false)}*/}
            {/*  src={require('../../assets/refresh.png')}*/}
            {/*  alt='refresh'*/}
            {/*/>*/}
          </div>
          <div className='credit'>
            <img src={anyChip} alt='anychip'></img>
            <div>
              <span className='px-2'>
                {purchaseAmount > 0
                  ? numeral(Math.floor((walletCredit - purchaseAmount) * 100) / 100).format('0,0')
                  : numeral(Math.floor(walletCredit * 100) / 100).format('0,0')}
              </span>
            </div>
          </div>
          <div onClick={() => setShowModal({ isShow: true, currentModal: 'สถิตินักษัตร' })}>
            <img src={rulebook_img} className='rule-book' alt='rule book'></img>
            {/* <ModalImage
              className='rule-book'
              small={rulebook_img}
              large={zodiacRule}
              alt="zodiac-rule"
            /> */}
          </div>
        </div>
        <div className='zodiac-display'>
          <div className='first-zodiacs'>
            {zodiacDice('ชวด')}
            {zodiacDice('ฉลู')}
            {zodiacDice('ขาล')}
            {zodiacDice('เถาะ')}
            {zodiacDice('มะโรง')}
            {zodiacDice('มะเส็ง')}
          </div>
          <div className='second-zodiacs'>
            {zodiacDice('มะเมีย')}
            {zodiacDice('มะแม')}
            {zodiacDice('วอก')}
            {zodiacDice('ระกา')}
            {zodiacDice('จอ')}
            {zodiacDice('กุน')}
          </div>
        </div>
      </div>

      {/*  BOARD 1 */}

      <div className={`board-new ${lotto.status !== 'open' ? 'stop' : ''}`}>
        <div className='box1'>
          {zodiacBoard('ชวด')}
          {zodiacBoard('ฉลู')}
          {zodiacBoard('ขาล')}
          {zodiacBoard('เถาะ')}
          {zodiacBoard('มะโรง')}
          {zodiacBoard('มะเส็ง')}
        </div>
        <div className='box1'>
          {zodiacBoard('มะเมีย')}
          {zodiacBoard('มะแม')}
          {zodiacBoard('วอก')}
          {zodiacBoard('ระกา')}
          {zodiacBoard('จอ')}
          {zodiacBoard('กุน')}
        </div>
      </div>
      {/* CHIP BOARD */}
      <div className='chip-board'>
        <img
          src={fiveChip}
          className={`five-chip ${currentChip === 5 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(5)
          }}
          alt='five-chip'
        />
        <img
          src={tenChip}
          className={`ten-chip ${currentChip === 10 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(10)
          }}
          alt='ten-chip'
        />
        <img
          src={twentyChip}
          className={`twenty-chip ${currentChip === 20 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(20)
          }}
          alt='twenty-chip'
        />
        <img
          src={fiftyChip}
          className={`fifty-chip ${currentChip === 50 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(50)
          }}
          alt='fifty-chip'
        />
        <img
          src={hundredChip}
          className={`hundred-chip ${currentChip === 100 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(100)
          }}
          alt='hundred-chip'
        />
        <img
          src={fiveHundredChip}
          className={`five-hundred-chip ${currentChip === 500 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(500)
          }}
          alt='five-hundred-chip'
        />
      </div>

      {/* CONTRAL PANEL */}
      <div className='control-board-zodiac'>
        <button className={!isBuy || lotto.status !== 'open' ? 'inactive' : ''} onClick={() => resetButton()}>
          ล้าง
        </button>
        <button
          className={buyHistory.length < 2 || lotto.status !== 'open' ? 'inactive' : ''}
          onClick={() => undoButton()}
        >
          ย้อน
        </button>
        <button className={lotto.status !== 'open' ? 'inactive' : ''} onClick={() => luckyButton()}>
          สุ่ม
        </button>
        <button className={!isBuy || lotto.status !== 'open' ? 'inactive' : ''} onClick={() => multiplyButton()}>
          X2
        </button>
      </div>
      <div className='buy-board'>
        <button className='name'>
          {/*{lotto.lottoName || `เปิดรอบใน (${60 - moment().second() % 60}) วิ`}*/}
          {lotto.lottoName || 'กรุุณารอรอบถัดไป'}
        </button>
        <button
          className={!isBuy || lotto.status !== 'open' || isBuying || isBlock ? 'inactive' : ''}
          onClick={() => onClickConfirmPurchase()}
        >
          {isBuying ? 'กำลังซื้อ' : 'ยืนยันการซื้อ'}
        </button>
      </div>

      <div className='history'>{/*<button onClick={onTest}>Test</button>*/}</div>
      {showModal.currentModal === 'สถิตินักษัตร' ? (
        <ZodiacModal showModal={showModal} setShowModal={setShowModal} />
      ) : null}
    </div>
  )
}

export default Zodiac
