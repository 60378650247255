import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Auth } from 'aws-amplify'
import _get from 'lodash/get'
import _mapKeys from 'lodash/mapKeys'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates'
import Swal from 'sweetalert2'

import Input from '../../components/Form/Input'
import BoxWhite from '../../components/Box/BoxWhite'
import Button from '../../components/Button/Button'
// import { composePhoneNumber } from '../../utils/phoneNumber'
import './Profile.scss'

const mapToCognitoAttributes = {
  username: 'preferred_username',
  firstName: 'given_name',
  lastName: 'family_name',
  dobDate: 'birthdate',
  // tel: 'phone_number',
  // email: 'email',
}

const Profile = () => {
  const [fetchedUserData, setFetchedUserData] = useState({})
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    // email: '',
    // tel: '',
  })
  const [dobDate, setDobDate] = useState(moment())
  const [dobDateFocus, setDobDateFocus] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setFetchedUserData(user)
    }

    fetchUserData()
  }, [])

  useEffect(() => {
    setFormData({
      username: _get(fetchedUserData, 'attributes.preferred_username', ''),
      password: _get(fetchedUserData, 'attributes.password', ''),
      firstName: _get(fetchedUserData, 'attributes.given_name', ''),
      lastName: _get(fetchedUserData, 'attributes.family_name', ''),
      // email: _get(fetchedUserData, 'attributes.email', ''),
      // tel: _get(fetchedUserData, 'attributes.phone_number', ''),
    })

    const dob = moment(_get(fetchedUserData, 'attributes.birthdate'))
    setDobDate(dob)
  }, [fetchedUserData])

  const _handleChangeForm = ({ target }) => {
    const profileData = { ...formData }
    profileData[target.name] = target.value
    setFormData(profileData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const payload = _mapKeys(formData, (value, key) => {
        if (key === 'password') {
          return null
        }
        return mapToCognitoAttributes[key] || key
      })
      Object.keys(payload).forEach((key) => {
        if (key === null || key === 'null' || typeof key === 'undefined') {
          delete payload[key]
        }
      })

      payload.birthdate = moment(dobDate).format('YYYY-MM-DD')
      // payload.phone_number = composePhoneNumber('+66', payload.phone_number)
      const finalPayload = _omitBy(payload, _isNil)

      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, finalPayload)

      Swal.fire({
        icon: 'success',
        title: 'อัพเดทข้อมูลสำเร็จ',
      })
    } catch (error) {
      console.error('error profile handleSubmit>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  const handleImageChange = () => {
    //
  }

  return (
    <BoxWhite containerClass='profile__wrapper mt-20 text-black'>
      <h2 className='profile__title'>ตั้งค่าบัญชีผู้ใช้</h2>
      {/*<p className='profile__title-small'>จัดการโปรไฟล์</p>*/}
      {/*<img onClick={handleImageChange} className='profile__image' src={require('./images/profile-test.png')} />*/}
      <form>
        <Input
          onChange={_handleChangeForm}
          id='username'
          value={formData.username}
          label='ชื่อผู้ใช้ username'
          containerClassName='profile__username'
        />
        <div className='profile__section-name'>
          <Input onChange={_handleChangeForm} id='firstName' value={formData.firstName} label='ชื่อจริง' />
          <Input onChange={_handleChangeForm} id='lastName' value={formData.lastName} label='นามสกุล' />
        </div>
        <div className='profile__section-info'>
          <div className='profile__birthDate'>
            <label htmlFor='startDate'>วัน/เดือน/ปี เกิด</label>
            <SingleDatePicker
              date={dobDate}
              onDateChange={(date) => {
                setDobDate(date)
              }}
              focused={dobDateFocus}
              onFocusChange={({ focused }) => setDobDateFocus(focused)}
              id='startDate'
              numberOfMonths={1}
              noBorder={true}
              displayFormat={'DD/MM/YYYY'}
              // isOutsideRange={() => false}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
            />
          </div>
          {/*<Input type='email' id='email' value={formData.email} label='อีเมล' />*/}
          {/*<Input type='tel' id='tel' value={formData.tel} label='เบอร์โทรศัพท์' />*/}
        </div>
        {/* <hr className='profile__divider' />
        <p className='profile__title-small'>จัดการรหัสผ่าน</p>
        <Input type='password' id='password' label='รหัสผ่านเดิม' onChange={_handleChangeForm} />
        <Input type='password' id='password' label='รหัสผ่านใหม่' onChange={_handleChangeForm} />
        <Input type='password' id='password' label='ยืนยันรหัสผ่านใหม่' onChange={_handleChangeForm} />
        <hr className='profile__divider' /> */}
        {/*asdf*/}
        <Button
          onButtonClick={handleSubmit}
          wrapperClassName='profile__submit'
          buttonBackground='#c6c95b'
          text='บันทึก'
        />
      </form>
    </BoxWhite>
  )
}

export default Profile
