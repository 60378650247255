import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Swal from 'sweetalert2'
import Combinatorics from 'js-combinatorics'
import queryString from 'query-string'
import './BuyLotto.scss'
import threeAKey from '../../assets/shortcut/3A.png'
import threeRKey from '../../assets/shortcut/3R.png'
import threeFKey from '../../assets/shortcut/3F.png'
import threeBKey from '../../assets/shortcut/3B.png'
import twoAKey from '../../assets/shortcut/2A.png'
import twoBKey from '../../assets/shortcut/2B.png'
import SAKey from '../../assets/shortcut/SA.png'
import SBKey from '../../assets/shortcut/SB.png'
import Slider from 'react-input-slider';
// import nineteenKey from '../../assets/shortcut/nineteen.png'
// import doubleKey from '../../assets/shortcut/double.png'
// import prefixKey from '../../assets/shortcut/prefix.png'
// import suffixKey from '../../assets/shortcut/suffix.png'

import useGetPayRate from './components/useGetPayRate'
import KeyPad from './components/KeyPad'
import CartDisplay from './components/CartDisplay'
import NumberInput from './components/NumberInput'
import CheckoutModal from './components/CheckoutModal'
import ConfirmCheckoutModal from './components/ConfirmCheckoutModal'
import StatisticsModal from './components/StatisticsModal'
import Timer from './components/Timer'
import { clearCart, toggleCart } from '../../redux/actions/cart'
// import RulesModal from './components/RulesModal'
import ReceiptModal from './components/ReceiptModal'
import PayRateModal from './components/PayRateModal'

function BuyLotto({ match, location }) {
  // console.log('BuyLotto ======')
  const dispatch = useDispatch()
  let history = useHistory()
  const { lottoNumber } = match.params
  const fastbuy = location.search
  const fastbuyQuery = queryString.parse(fastbuy)

  const [lotto, setLotto] = useState({})
  const lottoGroup = lotto.lottoGroup
  const { fetchPayRate, highestPayRate } = useGetPayRate(lottoNumber, lottoGroup)
  // console.log({ fetchPayRate, highestPayRate }, 'object')
  
  // console.log(fastbuyQuery)
  const [isLoading, setIsLoading] = useState(true)
  const [currentNumRange, setCurrentNumRange] = useState('000')
  const [isPermutation, setIsPermutation] = useState(false)
  const [currentDigit, setCurrentDigit] = useState('3') // 2 OR 3 DIGITS
  const [currentType, setCurrentType] = useState(['3A']) // (A)BOVE, (B)ELOW, (S)TRAIGHT(A)BOVE, (S)TRAIGHT(B)ELOW
  const [isManualInput, setIsManualInput] = useState(true) // IS MANUAL?
  const [manualInputOption, setManualInputOption] = useState(null)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' }) // IS MODAL SHOWING ? & WHICH MODAL
  const [showYeekeeModal, setShowYeekeeModal] = useState({ isShow: false, currentModal: '' })
  const [filter, setFilter] = useState('') // SEARCH FILTER
  const [isAgentSubmit, setIsAgentSubmit] = useState(false)
  const [customerTable, setCustomerTable] = useState('')
  const [tricker, setTricker] = useState(false)
  const [firstTwoNum, setFirstTwoNum] = useState(0);
  const [lastTwoNum, setLastTwoNum] = useState(0);
  // const [firstTwoNumDrag, setFirstTwoNumDrag] = useState(0);
  // const [lastTwoNumDrag, setLastTwoNumDrag] = useState(0);
  // const [watchCurrentDigit, setWatchCurrentDigit] = useState(true)
  // const [isFocus, setIsFocus] = useState(false)
  // const setFocus = () => {
  //   const page = document.getElementsByClassName('buy-lotto')[0]
  //   if (!isFocus && page) {
  //     if (showModal.isShow || showYeekeeModal.isShow || showRulesModal.isShow) {
  //       setIsFocus(true)
  //       page.classList.add('blur-me')
  //       document.body.classList.add('block-scroll')
  //     }
  //   } else {
  //     setIsFocus(false)
  //     page.classList.remove('blur-me')
  //     document.body.classList.remove('block-scroll')
  //   }
  // }
  //
  const highestPayRateYk = {'3A': 1000, '3R': 150, '2A': 100, '2B': 100, 'SA': 3.3, 'SB': 4.3}

  window.onkeydown = function (e) {
    if (e.code === 'Space') {
      return !(e.code === 'Space' && e.target === document.body)
    }
    if (e.code === 'Escape') {
      return !(e.code === 'Escape' && e.target === document.body)
    }
    if (e.code === 'Tab') {
      return !(e.code === 'Tab' && e.target === document.body)
    }
  }

  const ManualInputOptions = ({ typeList, lottoNumber, isPermutation }) => {
    const [activeSubmenu, setActiveSubmenu] = useState(null)

    const selectNumber = (number, singleType) => {
      if (fetchPayRate[singleType]) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 1,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    }

    const handleOption = (digit, option) => {
      if (manualInputOption === null || manualInputOption !== option) {
        setCurrentDigit('1')
        setManualInputOption(option)
      } else if (manualInputOption === option) {
        setCurrentDigit('2')
        setManualInputOption(null)
      } else {
        setCurrentDigit('2')
        setManualInputOption(null)
      }
    }

    const autoSelectDoubleLottos = () => {
      const target = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectLowLottos = () => {
      typeList.forEach((type) => {
        for (let index = 0; index < 50; index++) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectHighLottos = () => {
      typeList.forEach((type) => {
        for (let index = 50; index < 100; index++) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectOddLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1; index < 100; index += 2) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectEvenLottos = () => {
      typeList.forEach((type) => {
        for (let index = 0; index < 100; index += 2) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectOddThreeLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1001; index < 2000; index += 2) {
          selectNumber(index.toString().substring(1).padStart(2, '0'), type)
        }
      })
    }

    const autoSelectEvenThreeLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1000; index < 2000; index += 2) {
          selectNumber(index.toString().substring(1).padStart(2, '0'), type)
        }
      })
    }

    const autoSelectSibling = () => {
      const target = [
        '01',
        '10',
        '12',
        '21',
        '23',
        '32',
        '34',
        '43',
        '45',
        '54',
        '56',
        '65',
        '67',
        '76',
        '78',
        '87',
        '89',
        '98',
        '90',
        '09',
      ]
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectSiblingOdd = () => {
      const target = ['13', '31', '35', '53', '57', '75', '79', '97', '91', '19']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectSiblingEven = () => {
      const target = ['02', '20', '24', '42', '46', '64', '68', '86', '08', '80']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }
    // console.log(showModal.isShow)
    document.body.onkeyup = function (e) {
      console.log(e, 'e')
      console.log(showModal.isShow)
      if (showModal.isShow === false && showModal.currentModal === '') {
        if (e.code === 'Space') {
          // console.log(e, 'e')
          setIsPermutation(!isPermutation)
        }
        if (e.code === 'KeyQ') {
          // 3 ตัวบน
          if (currentDigit !== '3') {
            setCurrentType(['3A'])
            setCurrentDigit('3')
          } else {
            setCurrentDigit('3')
            handleType('3A')
          }
        }
        if (e.code === 'KeyW') {
          // 3 ตัวโต๊ด
          if (currentDigit !== '3') {
            setCurrentType(['3R'])
            setCurrentDigit('3')
          } else {
            setCurrentDigit('3')
            handleType('3R')
          }
        }
        if (e.code === 'KeyE') {
          // 3 ตัวหน้า
          if (_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย') {
            if (currentDigit !== '3') {
              setCurrentType(['3F'])
              setCurrentDigit('3')
            } else {
              setCurrentDigit('3')
              handleType('3F')
            }
          }
        }
        if (e.code === 'KeyR') {
          // 3 ตัวล่าง
          if (_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย') {
            console.log('helo')
            if (currentDigit !== '3') {
              setCurrentType(['3B'])
              setCurrentDigit('3')
            } else {
              setCurrentDigit('3')
              handleType('3B')
            }
          }
        }
        if (e.code === 'KeyA') {
          // 2 ตัวบน
          if (currentDigit !== '2') {
            setCurrentType(['2A'])
            setCurrentDigit('2')
          } else {
            setCurrentDigit('2')
            handleType('2A')
          }
        }
        if (e.code === 'KeyS') {
          // 2 ตัวล่าง
          if (currentDigit !== '2') {
            setCurrentType(['2B'])
            setCurrentDigit('2')
          } else {
            setCurrentDigit('2')
            handleType('2B')
          }
        }
        if (e.code === 'KeyD') {
          // 19 ประตู
          if (_.includes(currentType, '2A') || _.includes(currentType, '2B')) {
            handleOption('1', 'nineteen')
          }
        }
        if (e.code === 'KeyF') {
          // เลขเบิ้ล
          if (_.includes(currentType, '2A') || _.includes(currentType, '2B')) {
            autoSelectDoubleLottos()
          }
        }
        if (e.code === 'KeyG') {
          // รูดหน้า
          if (_.includes(currentType, '2A') || _.includes(currentType, '2B')) {
            handleOption('1', 'prefix')
          }
        }
        if (e.code === 'KeyH') {
          // รูดหลัง
          if (_.includes(currentType, '2A') || _.includes(currentType, '2B')) {
            handleOption('1', 'suffix')
          }
        }
        if (e.code === 'KeyZ') {
          // วิ่งบน
          if (currentDigit !== '1') {
            setCurrentType(['SA'])
            setCurrentDigit('1')
          } else {
            setCurrentDigit('1')
            handleType('SA')
          }
        }
        if (e.code === 'KeyX') {
          // วิ่งล่าง
          if (currentDigit !== '1') {
            setCurrentType(['SB'])
            setCurrentDigit('1')
          } else {
            setCurrentDigit('1')
            handleType('SB')
          }
        }
        if (e.code === 'KeyC') {
        }
        if (e.code === 'KeyV') {
        }
        if (e.code === 'Enter') {
          setShowModal((prevState) => ({ ...prevState, isShow: true, currentModal: 'ใส่ราคา' }))
        }
        if (e.code === 'NumpadEnter') {
          setShowModal((prevState) => ({ ...prevState, isShow: true, currentModal: 'ใส่ราคา' }))
        }
        if (e.code === 'Escape') {
          if (cartCounter > 0) {
            Swal.fire({
              icon: 'info',
              title: 'แจ้งเตือน',
              text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              cancelButtonColor: ' #A9A9A9',
              confirmButtonText: 'ลบเลข',
              cancelButtonText: 'ยกเลิก',
            }).then((result) => {
              if (result.value) {
                dispatch(clearCart())
                Swal.fire({
                  icon: 'success',
                  title: 'ล้างรายการทั้งหมดสำเร็จ',
                  showConfirmButton: false,
                  timer: 1000,
                }).then(() => {
                  setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
                })
              }
            })
          }
        }
        if (e.code === 'Tab') {
          setTricker(!tricker)
        }
      }
      if (showModal.isShow === true && showModal.currentModal === 'ใส่ราคา') {
        if (e.code === 'Enter') {
          setShowModal((prevState) => ({ ...prevState, isShow: true, currentModal: 'confirm' }))
        }
        if (e.code === 'NumpadEnter') {
          setShowModal((prevState) => ({ ...prevState, isShow: true, currentModal: 'confirm' }))
        }
      }
      e.preventDefault()
    }

    // console.log(manualInputOption, 'manualInputOption')
    // console.log(currentDigit, 'currentDigit')
    // console.log(currentType, 'currentType')

    return _.includes(currentType, '2A') || _.includes(currentType, '2B') ? (
      <div className='__btn-group row-btn-group make-gap text-sm'>
        {/*<img src={nineteenKey} className='keyboard' alt='keyboard'  />*/}
        <button
          className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'nineteen' ? 'active' : ''}`}
          onClick={() => handleOption('1', 'nineteen')}
        >
          <span>19 ประตู</span>
        </button>
        {/*<img src={doubleKey} className='keyboard' alt='keyboard'  />*/}
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectDoubleLottos()}>
          <span>เลขเบิ้ล</span>
        </button>
        {/*<img src={prefixKey} className='keyboard' alt='keyboard'  />*/}
        <button
          className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'prefix' ? 'active' : ''}`}
          onClick={() => handleOption('1', 'prefix')}
        >
          <span>รูดหน้า</span>
        </button>
        {/*<img src={suffixKey} className='keyboard' alt='keyboard'  />*/}
        <button
          className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'suffix' ? 'active' : ''}`}
          onClick={() => handleOption('1', 'suffix')}
        >
          <span>รูดหลัง</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectLowLottos()}>
          <span>2 ตัวต่ำ</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectHighLottos()}>
          <span>2 ตัวสูง</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectOddLottos()}>
          <span>2 ตัวคี่</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectEvenLottos()}>
          <span>2 ตัวคู่</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectSiblingOdd()}>
          <span>พี่น้องคี่</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectSiblingEven()}>
          <span>พี่น้องคู่</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectSibling()}>
          <span style={{ zIndex: 0 }}>เลขพี่น้อง</span>
        </button>
      </div>
    ) : _.includes(currentType, '3A') && !_.includes(currentType, '3R') && lottoGroup !== 'เบอร์รถ' ? (
      <div className='__btn-group row-btn-group make-gap text-sm'>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectOddThreeLottos()}>
          <span>3 ตัวคี่</span>
        </button>
        <button className={`btn-bevel bg-dark border-on-active`} onClick={() => autoSelectEvenThreeLottos()}>
          <span>3 ตัวคู่</span>
        </button>
      </div>
    ) : null
  }

  useEffect(() => {
    // if (currentDigit === '4') {
    //   dispatch(clearCart())
    // }
    if (manualInputOption !== null && currentDigit !== '1') {
      // if (currentDigit === '1' && !_.includes(currentType, 'SA') && !_.includes(currentType, 'SB')) {
      setManualInputOption(null)
    }
    // }
    if (_.includes(currentType, 'SA') || _.includes(currentType, 'SB')) {
      setManualInputOption(null)
    }
  }, [currentDigit])

  useEffect(() => {
    if (lottoGroup === 'เบอร์รถ') {
      console.log(cartItems, 'cart')
      dispatch(clearCart())
      setCurrentDigit('4')
      setCurrentType(['4A'])
    } else {
      if (cartItems.length > 0) {
        console.log(cartItems, 'cart')
        const findFourDigits = cartItems.find(obj => obj.type === '4A')
        console.log(findFourDigits)
        if (findFourDigits) {
          dispatch(clearCart())
        }
      }
    }
  }, [lotto])

  // console.log(watchCurrentDigit, 'setWatchCurrentDigit')

  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])
  const cartCounter = cartItems.length || 0


  const cartItemsGroupByType = _.groupBy(cartItems, 'type')

  const lottoType = { '4': ['4A, 4R'], '3': ['3A', '3R'], '2': ['2A', '2B'], '1': ['SA', 'SB'] }[currentDigit]
  const lottoThailandType = { '3': ['3A', '3R', '3F', '3B'], '2': ['2A', '2B'], '1': ['SA', 'SB'] }[currentDigit]
  const lottoOptionTitle = {
    '4A': '4 ตัวตรง',
    '4R': '4 ตัวโต๊ด',
    '2A': '2 ตัวบน',
    '2B': '2 ตัวล่าง',
    '3A': '3 ตัวบน',
    '3R': '3 ตัวโต๊ด',
    '3F': '3 ตัวหน้า',
    '3B': '3 ตัวล่าง',
    SA: 'วิ่งบน',
    SB: 'วิ่งล่าง',
  }

  const handleFilter = (event) => {
    event.preventDefault()
    setFilter(event.target.value)
  }

  const handleType = (type) => {
    let tempTypeArray = [...currentType]
    let incomingType = type

    if (_.includes(tempTypeArray, incomingType)) {
      _.remove(tempTypeArray, function (type) {
        return type === incomingType
      })
    } else {
      if (_.includes(tempTypeArray, '2A') || _.includes(tempTypeArray, '2B')) {
        if (_.includes(type, 'SA') || _.includes(type, 'SB')) {
          _.remove(tempTypeArray, function (type) {
            return _.includes(type, '2A') || _.includes(type, '2B')
          })
          setManualInputOption(null)
        }
      }
      tempTypeArray.push(incomingType)
    }
    setCurrentType(tempTypeArray)
  }

  const Header = () => {
    return (
      <section className='__header container mx-auto'>
        <div className='buy-lotto__lotto-number text-xl btn-bevel'>
          <h1>{_.get(lotto, 'lottoName', '...')}</h1>
          <div>ผลออก {_.get(lotto, 'drawTimeStr', '...').slice(0, 5)}</div>
        </div>
        <div className='buy-lotto__timer text-xl btn-bevel'>
          {/*<div className='buy-lotto__free allReceipts__todayResultBox-paid shine-box-base w-1/2 h-10 text-center ml-1'>*/}
          {/*  <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยอดรวม</div>*/}
          {/*  <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: 'blue' }}>*/}
          {/*    0.00*/}
          {/*  </div>*/}
          {/*</div>*/}
          <Timer
            drawDateStr={lotto.drawDateStr}
            drawTimeStr={lotto.drawTimeStr}
            closeMinutesBeforeDraw={lotto.closeMinutesBeforeDraw}
          />
          {/*<div className='buy-lotto__pro allReceipts__todayResultBox-paid shine-box-base w-1/2 h-10 text-center ml-1'>*/}
          {/*  <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยอดรวม</div>*/}
          {/*  <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: 'blue' }}>*/}
          {/*  0.00*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        {/*<div className='__btn-group'>*/}
        {/*  <button className='text-xl'>กติกา</button>*/}
        {/*  <button className='btn-bevel text-xl'>*/}
        {/*    <span>ยิงเลข</span>*/}
        {/*  </button>*/}
        {/*</div>*/}
      </section>
    )
  }

  const BuyLottoLeft = () => {
    return (
      <section className='buy-lotto__left'>
        {lottoGroup === 'เบอร์รถ' ? (
          <div className={`buy-lotto__box ${isPermutation ? 'permutation' : ''}`}>
            <div className='text-xl text-white text-center' style={{ display: 'none' }}></div>
            <div className='text-xl text-white text-center' style={{ display: 'none' }}></div>
            <div className='text-xl text-white text-center'>ทั้งหมด</div>
            <div className='buy-lotto__selected'>
              <div className='__cart-counter text-xl' style={{ color: '#8d7f53' }}>
                {cartCounter} รายการ
              </div>
              <CartDisplay />
            </div>
          </div>
        ) : (
          <div className={`buy-lotto__box ${isPermutation ? 'permutation' : ''}`}>
            <div
              className='text-xl bg-darken btn-bevel'
              onClick={() => setShowModal({ isShow: true, currentModal: 'ดึงโพย' })}
            >
              <span style={{ color: '#ffffff' }}>ดึงโพย</span>
            </div>
            {lotto.lottoName === 'หวยรัฐบาลไทย' ? (
              <div
                className='text-xl bg-darken btn-bevel'
                onClick={() => setShowModal({ isShow: true, currentModal: 'เลขอั้น' })}
              >
                <span style={{ color: '#ffffff' }}>ดูเลขอั้น</span>
              </div>
            ) : (
              <div
                className='text-xl bg-darken btn-bevel'
                onClick={() => setShowModal({ isShow: true, currentModal: 'ดูสถิติ' })}
              >
                <span style={{ color: '#ffffff' }}>ดูสถิติ</span>
              </div>
            )}

            <div className='text-xl text-white text-center'>ทั้งหมด</div>
            <div className='buy-lotto__selected'>
              <div className='__cart-counter text-xl' style={{ color: '#8d7f53' }}>
                {cartCounter} รายการ
              </div>
              <CartDisplay />
            </div>
          </div>
        )
        }
      </section >
    )
  }

  const BuyLottoRight = () => {
    const clearSelection = () => {
      setCurrentType([])
      setCurrentNumRange('000')
    }

    const DigitOptions = () => {
      return (
        <>
          <button
            className={`bg-dark btn-bevel border-on-active ${currentDigit === '3' ? 'active' : ''}`}
            onClick={() => {
              setCurrentDigit('3')
              clearSelection()
              setCurrentType(['3A'])
            }}
          >
            <span>3 ตัว</span>
          </button>
          <button
            className={`bg-dark btn-bevel border-on-active ${currentDigit === '2' ? 'active' : ''}`}
            onClick={() => {
              setCurrentDigit('2')
              clearSelection()
              setCurrentType(['2A', '2B'])
            }}
          >
            <span>2 ตัว</span>
          </button>
          <button
            className={`bg-dark btn-bevel border-on-active ${currentDigit !== '1'
              ? ''
              : !_.includes(currentType, '2A') && !_.includes(currentType, '2B')
                ? 'active'
                : ''
              }`}
            onClick={() => {
              setCurrentDigit('1')
              clearSelection()
              setCurrentType(['SA', 'SB'])
            }}
          >
            <span>เลขวิ่ง</span>
          </button>
        </>
      )
    }
    // console.log(currentDigit, 'cadfafaesfs')
    const TypeOptions = () => {
      let options = []
      lottoType.forEach((item, index) => {
        options.push(
          <button
            style={lottoGroup !== 'เบอร์รถ' ? {} : { display: 'none' }}
            key={index}
            className={`btn-black btn-bevel ${_.includes(currentType, item) ? 'active' : ''}`}
            onClick={() => handleType(item)}
          >
            <span className='left'>{lottoOptionTitle[item]}</span>
            {lottoGroup === 'หวยยี่กี' ? <span className='right'>{highestPayRateYk[item] || 'xx'}</span> :
              <span className='right'>{highestPayRate[item] || 'xx'}</span>}
          </button>,
        )
        // }
      })
      return options
    }

    const TypeThailandOptions = () => {
      let options = []
      lottoThailandType.forEach((item, index) => {
        options.push(
          <button
            key={index}
            className={`btn-black btn-bevel ${_.includes(currentType, item) ? 'active' : ''}`}
            onClick={() => handleType(item)}
          >
            <span className='left'>{lottoOptionTitle[item]}</span>
            <span className='right'>{highestPayRate[item] || 'xx'}</span>
          </button>,
        )
        // }
      })
      return options
    }

    const TwoDigitSubmenu = () => {
      let nineteen = []
      let sp = []
      let ss = []

      const selectNumber = (number, singleType) => {
        if (fetchPayRate[singleType]) {
          dispatch(
            toggleCart({
              lottoNumber,
              purchaseItem: {
                type: singleType,
                detail: number,
                amount: 1,
                payRate:
                  fetchPayRate[singleType][number] === undefined
                    ? fetchPayRate[singleType].default
                    : fetchPayRate[singleType][number],
                isNumInput: true,
              },
            }),
          )
        }
      }

      const isAlreadyExists = (number, singleType) => {
        return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
      }

      const generate10lotto = (num, model, typeList) => {
        num = num.toString()
        typeList.forEach((type) => {
          switch (model) {
            case 'nineteen': {
              let target = []
              for (let i = 0; i < 10; i++) {
                let front = num.concat(i)
                let back = i.toString().concat(num)
                target.push(front)
                target.push(back)
              }
              _.uniqBy(target).forEach((item) => {
                if (!isAlreadyExists(item, type)) {
                  selectNumber(item, type)
                } else {
                  _.uniqBy(target).forEach((item) => {
                    selectNumber(item, type)
                  })
                }
              })
            }
              break
            case 'prefix':
              for (let i = 0; i < 10; i++) {
                selectNumber(num.concat(i), type)
              }
              break
            case 'suffix':
              for (let i = 0; i < 10; i++) {
                selectNumber(i.toString().concat(num), type)
              }
              break
            default:
              console.log('default model')
          }
        })
      }

      const isStraightActive = (number, currentType, straightType) => {
        let target = []
        const num = number.toString()
        switch (straightType) {
          case 'nineteen':
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            break
          case 'prefix':
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              target.push(front)
            }
            break
          case 'suffix':
            for (let i = 0; i < 10; i++) {
              let back = i.toString().concat(num)
              target.push(back)
            }
            break
        }
        let included = false
        currentType.forEach((type) => {
          if (_.difference(_.uniqBy(target), _.map(cartItemsGroupByType[type], 'detail')).length === 0) {
            included = true
          }
        })
        return included
      }

      for (let index = 0; index < 10; index++) {
        nineteen.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${isStraightActive(index, currentType, 'nineteen') ? 'active ' : ''
              }`}
            onClick={() => generate10lotto(index, 'nineteen', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      for (let index = 0; index < 10; index++) {
        sp.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${isStraightActive(index, currentType, 'prefix') ? 'active ' : ''
              }`}
            onClick={() => generate10lotto(index, 'prefix', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      for (let index = 0; index < 10; index++) {
        ss.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${isStraightActive(index, currentType, 'suffix') ? 'active ' : ''
              }`}
            onClick={() => generate10lotto(index, 'suffix', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      return (
        <>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'white', fontWeight: 'bold' }}>19 ประตู</h5>
            <div className='__btn-num-straight-group ml-0'>{nineteen}</div>
          </div>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'white', fontWeight: 'bold' }}>รูดหน้า</h5>
            <div className='__btn-num-straight-group'>{sp}</div>
          </div>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'white', fontWeight: 'bold' }}>รูดหลัง</h5>
            <div className='__btn-num-straight-group mr-0'>{ss}</div>
          </div>
        </>
      )
    }

    const NumRangeOptions = () => {
      let options = []
      for (let index = 0; index < 10; index++) {
        let numRange = index.toString().padEnd(3, '0')
        options.push(
          <button
            key={index}
            className={`__btn-num-range btn-bevel ${currentNumRange === numRange ? 'active' : ''}`}
            onClick={() => setCurrentNumRange(numRange)}
          >
            <span>{numRange}</span>
          </button>,
        )
      }
      return options
    }

    // const handleClearCart = () => {
    //   if (cartCounter > 0) {
    //     Swal.fire({
    //       icon: 'info',
    //       title: 'แจ้งเตือน',
    //       text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
    //       showCancelButton: true,
    //       confirmButtonColor: '#7dc351',
    //       cancelButtonColor: ' #A9A9A9',
    //       confirmButtonText: 'ลบเลข',
    //       cancelButtonText: 'ยกเลิก',
    //     }).then((result) => {
    //       if (result.value) {
    //         dispatch(clearCart())
    //         Swal.fire({
    //           icon: 'success',
    //           title: 'ล้างรายการทั้งหมดสำเร็จ',
    //           showConfirmButton: false,
    //           timer: 1000,
    //         })
    //       }
    //     })
    //   }
    // }
    const onChangeFirstTwo = (x) => {
      setFirstTwoNum(x)
      if (lastTwoNum > 0) {
        setLastTwoNum(0)
      }
    }

    const onChangeLastTwo = (x) => {
      setLastTwoNum(x)
      if (firstTwoNum > 0) {
        setFirstTwoNum(0)
      }
    }

    // const onDragFirstTwo = () => {
    //   console.log(firstTwoNum, '1')
    //   setTimeout(() => {
    //   }, 1000)
    //   console.log(firstTwoNum, '2')
    //   setFirstTwoNumDrag(firstTwoNum)
    //   if (lastTwoNum > 0) {
    //     setLastTwoNumDrag(0)
    //   }
    // }

    // const onDragLastTwo = () => {
    //   setTimeout(() => {
    //     }, 100)
    //   setLastTwoNumDrag(lastTwoNum)
    //   if (firstTwoNum > 0) {
    //     setFirstTwoNumDrag(0)
    //   }
    // }

    return (
      <section className='buy-lotto__right'>
        <section className='__form __loto-menu'>
          {lottoGroup !== 'เบอร์รถ' || !isManualInput ? null : <img style={{ margin: 'auto' }} src={require('../../assets/fortunerIcon.jpg')} alt='car-banner'></img>}
          <div className='__btn-group text-xl'>
            <button
              className={`bg-black btn-bevel border-on-active ${isManualInput ? 'active' : ''}`}
              style={{ padding: '6px 10px' }}
              onClick={() => setIsManualInput(!isManualInput)}
            >
              <span>กดเลขเอง</span>
            </button>
            <button
              className={`bg-black btn-bevel border-on-active ${!isManualInput ? 'active' : ''}`}
              style={{ padding: '6px 10px' }}
              onClick={() => {
                setIsManualInput(false)
                setCurrentDigit(lottoGroup !== 'เบอร์รถ' && manualInputOption ? '2' : currentDigit)
              }}
            >
              <span>เลือกจากแผง</span>
            </button>
          </div>
          {!isManualInput ? (
            <div className='__btn-group make-gap text-xl'>
              {lottoGroup !== 'เบอร์รถ' ? <DigitOptions /> : null}
            </div>
          ) : null}
        </section>

        <section className='__form'>
          {isManualInput ? (
            <>
              <div className='__btn-group make-gap text-xl'>
                <button
                  className={`btn-bevel border-on-active ${isPermutation ? 'bg-permutation active' : 'bg-permutation'}`}
                  onClick={(event) => {
                    // let tempTypeArray = currentType
                    // if (_.includes(tempTypeArray, '3R')) {
                    //   _.remove(tempTypeArray, function (type) {
                    //     return type === '3R'
                    //   })
                    // }
                    // handleEvent(7, event)
                    setIsPermutation(!isPermutation)
                  }}
                >
                  <span style={{ color: 'white' }}>**** กลับตัวเลข ****</span>
                </button>
              </div>
              {lottoGroup === 'เบอร์รถ' ? (
                null
              ) : (<div className='__btn-group manual make-gap text-xl'>
                <button
                  className={`btn-bevel btn-black ${_.includes(currentType, '3A') ? 'active' : ''}`}
                  onClick={() => {
                    if (currentDigit !== '3') {
                      setCurrentType(['3A'])
                      setCurrentDigit('3')
                    } else {
                      setCurrentDigit('3')
                      handleType('3A')
                    }
                  }}
                >
                  <img src={threeAKey} className='keyboard' alt='keyboard' />
                  <span className='left'>3 ตัวบน</span>
                  {lottoGroup === 'หวยยี่กี' ? <span className='right'>1000</span> :  <span className='right'>900</span>}

                </button>
                <button
                  className={`btn-bevel btn-black ${_.includes(currentType, '3R') ? 'active' : ''}`}
                  onClick={() => {
                    if (currentDigit !== '3') {
                      setCurrentType(['3R'])
                      setCurrentDigit('3')
                    } else {
                      setCurrentDigit('3')
                      handleType('3R')
                    }
                    // setIsPermutation(false)
                  }}
                >
                  <img src={threeRKey} className='keyboard' alt='keyboard' />
                  <span className='left'>3 ตัวโต๊ด</span>
                  <span className='right'>150</span>
                </button>
                {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                  <button
                    className={`btn-bevel btn-black  ${_.includes(currentType, '3F') ? 'active' : ''}`}
                    onClick={() => {
                      if (currentDigit !== '3') {
                        setCurrentType(['3F'])
                        setCurrentDigit('3')
                      } else {
                        setCurrentDigit('3')
                        handleType('3F')
                      }
                      // setIsPermutation(!isPermutation)
                    }}
                  >
                    <img src={threeFKey} className='keyboard' alt='keyboard' />
                    <span className='left'>3 ตัวหน้า</span>
                    <span className='right'>450</span>
                  </button>
                ) : null}
                {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                  <button
                    className={`btn-bevel btn-black  ${_.includes(currentType, '3B') ? 'active' : ''}`}
                    onClick={() => {
                      if (currentDigit !== '3') {
                        setCurrentType(['3B'])
                        setCurrentDigit('3')
                      } else {
                        setCurrentDigit('3')
                        handleType('3B')
                      }
                      // setIsPermutation(!isPermutation)
                    }}
                  >
                    <img src={threeBKey} className='keyboard' alt='keyboard' />
                    <span className='left'>3 ตัวล่าง</span>
                    <span className='right'>450</span>
                  </button>
                ) : null}
                <button
                  className={`btn-bevel btn-black  ${_.includes(currentType, '2A') ? 'active' : ''}`}
                  onClick={() => {
                    if (currentDigit !== '2') {
                      setCurrentType(['2A'])
                      setCurrentDigit('2')
                    } else {
                      setCurrentDigit('2')
                      handleType('2A')
                    }
                  }}
                >
                  <img src={twoAKey} className='keyboard' alt='keyboard' />
                  <span className='left'>2 ตัวบน</span>
                  <span className='right'>{lottoGroup === 'หวยไทย' || lottoGroup === 'หวยยี่กี' ? '100' : '95'}&nbsp;</span>
                </button>
                <button
                  className={`btn-bevel btn-black  ${_.includes(currentType, '2B') ? 'active' : ''}`}
                  onClick={() => {
                    // console.log(currentDigit)
                    if (currentDigit !== '2') {
                      setCurrentType(['2B'])
                      setCurrentDigit('2')
                    } else {
                      setCurrentDigit('2')
                      handleType('2B')
                    }
                  }}
                >
                  <img src={twoBKey} className='keyboard' alt='keyboard' />
                  <span className='left'>2 ตัวล่าง</span>
                  <span className='right'>{lottoGroup === 'หวยไทย' || lottoGroup === 'หวยยี่กี' ? '100' : '95'}&nbsp;</span>
                </button>
                <button
                  className={`btn-bevel btn-black  ${_.includes(currentType, 'SA') ? 'active' : ''}`}
                  onClick={() => {
                    if (currentDigit !== '1') {
                      setCurrentType(['SA'])
                      setCurrentDigit('1')
                    } else {
                      setCurrentDigit('1')
                      handleType('SA')
                    }
                    // setIsPermutation(!isPermutation)
                  }}
                >
                  <img src={SAKey} className='keyboard' alt='keyboard' />
                  <span className='left'>วิ่งบน&nbsp;&nbsp;&nbsp;</span>
                  <span className='right'>3.3&nbsp;</span>
                </button>
                <button
                  className={`btn-bevel btn-black ${_.includes(currentType, 'SB') ? 'active' : ''}`}
                  onClick={() => {
                    if (currentDigit !== '1') {
                      setCurrentType(['SB'])
                      setCurrentDigit('1')
                    } else {
                      setCurrentDigit('1')
                      handleType('SB')
                    }
                    // setIsPermutation(!isPermutation)
                  }}
                >
                  <img src={SBKey} className='keyboard' alt='keyboard' />
                  <span className='left'>วิ่งล่าง&nbsp;&nbsp;&nbsp;</span>
                  <span className='right'>4.3&nbsp;</span>
                </button>
              </div>)}

              <ManualInputOptions typeList={currentType} lottoNumber={lottoNumber} isPermutation={isPermutation} />
              {!showModal.isShow && !showYeekeeModal.isShow && (
                <KeyPad
                  isManualInput={isManualInput}
                  manualInputOption={manualInputOption}
                  isPermutation={isPermutation}
                  setIsPermutation={setIsPermutation}
                  tricker={tricker}
                  typeList={currentType}
                  lottoNumber={lottoNumber}
                  lottoGroup={lottoGroup}
                  currentDigit={currentDigit}
                  fetchPayRate={fetchPayRate}
                />
              )}
            </>
          ) : (
            <>
              <div className='__btn-group make-gap text-xl'>
                {/*<input*/}
                {/*  className='filter'*/}
                {/*  type='number'*/}
                {/*  placeholder='ค้นหาตัวเลข'*/}
                {/*  value={filter}*/}
                {/*  onChange={handleFilter}*/}
                {/*/>*/}
                <button
                  className={`btn-bevel border-on-active ${isPermutation ? 'bg-grey active' : 'bg-gray'}`}
                  onClick={() => {
                    // let tempTypeArray = currentType
                    // if (_.includes(tempTypeArray, '3R')) {
                    //   _.remove(tempTypeArray, function (type) {
                    //     return type === '3R'
                    //   })
                    // }
                    setIsPermutation(!isPermutation)
                  }}
                >
                  <span style={{ color: 'white' }}>**** กลับตัวเลข ****</span>
                </button>
              </div>
              {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                <div className='__btn-th-group make-gap text-xl'>
                  <TypeThailandOptions />
                </div>
              ) : (
                <div className='__btn-group make-gap text-xl'>
                  <TypeOptions />{' '}
                </div>
              )}
              <div className='__overlay make-gap '>
                {!currentType.length > 0 ? <div className='__btn-number-group-overlay'></div> : null}
                {currentDigit === '4' ? (
                  <div className='__btn-num-range-group text-xl mt-3 mb-3'>
                    <Slider
                      axis="x"
                      x={firstTwoNum}
                      xmin={0}
                      xmax={100}
                      onChange={({ x }) => { onChangeFirstTwo(x) }}
                      // onDragEnd={() => onDragFirstTwo()}
                      styles={{
                        track: {
                          backgroundColor: 'blue'
                        },
                        active: {
                          backgroundColor: 'red'
                        },
                        thumb: {
                          width: 30,
                          height: 30
                        },
                        disabled: {
                          opacity: 0.5
                        }
                      }}
                    />
                    <div style={{ margin: 'auto', color: 'white' }}>
                      <p className='px-4 py-2' style={firstTwoNum === 0 ? { display: 'none' } : {}}>สองตัวหน้า: {(firstTwoNum - 1).toString().padStart(2, '0')}</p>
                      <p className='px-4 py-2' style={firstTwoNum === 0 && lastTwoNum === 0 ? {} : { display: 'none' }}>เลื่อนเพื่อเลือกเลข</p>
                      <p className='px-4 py-2' style={lastTwoNum === 0 ? { display: 'none' } : {}}>สองตัวท้าย: {(lastTwoNum - 1).toString().padStart(2, '0')}</p>
                    </div>
                    <Slider
                      axis="x"
                      x={lastTwoNum}
                      xmin={0}
                      xmax={100}
                      onChange={({ x }) => { onChangeLastTwo(x) }}
                      // onDragEnd={() => onDragLastTwo()}
                      styles={{
                        track: {
                          backgroundColor: 'blue'
                        },
                        active: {
                          backgroundColor: 'red'
                        },
                        thumb: {
                          width: 30,
                          height: 30
                        },
                        disabled: {
                          opacity: 0.5
                        }
                      }}
                    />
                  </div>
                ) : null}
                {currentDigit === '3' ? (
                  <div className='__btn-num-range-group text-xl'>
                    <NumRangeOptions />
                  </div>
                ) : null}
                {_.includes(currentType, '2A') || _.includes(currentType, '2B') ? (
                  <div className='__btn-two-digit-sub-menu-group text-xl'>
                    <TwoDigitSubmenu />
                  </div>
                ) : null}
                <div className='__btn-number-group text-xl'>
                  <NumberInput
                    filter={filter}
                    currentDigit={currentDigit}
                    currentNumRange={currentNumRange}
                    currentType={currentType}
                    isPermutation={isPermutation}
                    lottoNumber={lottoNumber}
                    fetchPayRate={fetchPayRate}
                    highestPayRate={highestPayRate}
                    firstTwoNumDrag={firstTwoNum}
                    lastTwoNumDrag={lastTwoNum}
                    lottoGroup={lottoGroup}
                  />
                </div>
              </div>
            </>
          )}
        </section>
        <section>
          {lottoGroup !== 'เบอร์รถ' ? (
            <div className='buy-lotto__direction text-lg'>
              <div>เงื่อนไขการแทง</div>
              {currentType.map((item, index) => (
                <div className='direction_item' key={index}>
                  <ul key={lottoOptionTitle[item]}>
                    <li className='danger' key={index} style={{ color: 'white' }}>
                      {lottoOptionTitle[item]} จ่ายสูงสุด : {highestPayRate[item]}
                    </li>
                  </ul>
                  <ul>
                    <li>แทงขั้นต่ำต่อครั้ง : 1 บาท</li>
                    <li>แทงสูงสุดต่อครั้ง : 100,000 บาท</li>
                    <li>แทงสูงสุดต่อเลข : 1,000 - 10,000 บาท</li>
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <div className='buy-lotto__direction text-lg mt-5'>
              <div className='direction_item'>
                <ul>
                  <li className='danger' style={{ color: 'white' }}>
                    แตกสี่ตัวตรงจ่ายทันที : 1,300,000 บาท
                  </li>
                </ul>
                <ul>
                  <li>แทงสูงสุดต่อเลข : 399 บาท</li>
                </ul>
              </div>
            </div>
          )}
          {/*<div className='s2 text-2xl buy-lotto__btn-group-black-box'>*/}
          {/*  /!*<button className='bg-gray btn-bevel'>ดึงโพย</button>*!/*/}
          {/*  <button className='bg-gray btn-bevel' onClick={() => handleClearCart()}>*/}
          {/*    <span>ยกเลิกทั้งหมด</span>*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className='bg-dark btn-bevel'*/}
          {/*    onClick={() => setShowModal({ isShow: true, currentModal: 'ใส่ราคา' })}*/}
          {/*  >*/}
          {/*    ใส่ราคา*/}
          {/*  </button>*/}
          {/*</div>*/}
        </section>
      </section>
    )
  }

  const SticktyButton = () => {
    const handleClearCart = () => {
      if (cartCounter > 0) {
        Swal.fire({
          icon: 'info',
          title: 'แจ้งเตือน',
          text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: ' #A9A9A9',
          confirmButtonText: 'ลบเลข',
          cancelButtonText: 'ยกเลิก',
        }).then((result) => {
          if (result.value) {
            dispatch(clearCart())
            Swal.fire({
              icon: 'success',
              title: 'ล้างรายการทั้งหมดสำเร็จ',
              showConfirmButton: false,
              timer: 1000,
            })
          }
        })
      }
    }

    return (
      <div className='s2 text-2xl buy-lotto__btn-group-black-box'>
        {/*<button className='bg-gray btn-bevel'>ดึงโพย</button>*/}
        {lotto.lottoName === 'หวยรัฐบาลไทย' ? (
          <button className='btn-bevel' onClick={() => setShowModal({ isShow: true, currentModal: 'เลขอั้น' })}
            tabIndex={-1}>
            <span style={{ color: '#ffffff' }}>ดูเลขอั้น</span>
          </button>
        ) : null}
        <button className='btn-bevel' onClick={() => handleClearCart()} tabIndex={-1}>
          <span style={{ backgroud: 'grey', color: '#ffffff' }}>ยกเลิกทั้งหมด</span>
        </button>
        <button className='btn-bevel' onClick={() => setShowModal({ isShow: true, currentModal: 'ใส่ราคา' })}
          tabIndex={-1}>
          <span style={{ backgroud: 'red !important', color: '#ffffff' }}>{lottoGroup !== 'เบอร์รถ' ? 'ใส่ราคา' : 'ต่อไป'}</span>
        </button>
      </div>
    )
  }

  useEffect(() => {
    const selectNumber = (number, singleType) => {

      if (fetchPayRate[singleType]) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 1,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    }
    const removeDups = (array) => {
      let unique = {}
      array.forEach(function (i) {
        if (!unique[i]) {
          unique[i] = true
        }
      })
      return Object.keys(unique)
    }

    const isAlreadyExists = (number, singleType) => {
      return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
    }

    const generatePermutation = (number, singleType) => {
      const result = Combinatorics.permutation(number.split('')).toArray()
      const unique = removeDups(result)
      let allChecked = false
      for (let i = 0; i < unique.length; i++) {
        let newNumber = unique[i].replace(/,/g, '').toString()
        if (!isAlreadyExists(newNumber, singleType)) {
          selectNumber(newNumber, singleType)
        } else {
          allChecked = true
        }
      }
      if (allChecked) {
        for (let i = 0; i < unique.length; i++) {
          let newNumber = unique[i].replace(/,/g, '').toString()
          selectNumber(newNumber, singleType)
        }
      }
    }
    // API.get('lotto', '/lottos?lottoNumber=' + lottoNumber)
    API.get('lotto', '/lottos/' + lottoNumber)
      .then(({ detail }) => {
        // const data = _.get(detail, '0', null)
        const data = detail || null
        // console.log(detail, 'detailasdf')
        if (!data || _.isEmpty(data)) {
          return Swal.fire({
            icon: 'error',
            title: `ไม่พบข้อมูลหวย ID: ${lottoNumber}`,
            text: 'กรุณากลับเมนูหวยทั้งหมด หรือติดต่อเจ้าหน้าที่',
            showCancelButton: true,
            confirmButtonColor: '#B6964E',
            cancelButtonColor: ' #A9A9A9',
            confirmButtonText: 'ไปหน้าหวยทั้งหมด',
          }).then((result) => {
            if (result.value) {
              history.push('/lottos')
            }
          })
        } else {
          setLotto(data)
        }
      })
      .catch((err) => {
        console.error(err)
        return Swal.fire({
          icon: 'error',
          title: `เกิดข้อผิดพลาด`,
          text: 'กรุณากลับเมนูหวยทั้งหมด หรือติดต่อเจ้าหน้าที่',
          confirmButtonColor: '#B6964E',
          confirmButtonText: 'ไปหน้าหวยทั้งหมด',
        }).then((result) => {
          console.log(result)
          history.push('/lottos')
        })
      })
      .then(() => {
        if (fastbuyQuery || isLoading) {
          _.mapKeys(fastbuyQuery, function (value, key) {
            if (!value) {
              return
            }
            var items = value.split(',')
            console.log(items, 'fastbyuasdfaf')
            items.forEach((item) => {
              switch (key) {
                case 'three':
                  selectNumber(item, '3A')
                  selectNumber(item, '3R')
                  break
                case 'two':
                  generatePermutation(item, '2A')
                  generatePermutation(item, '2B')
                  break
                case 'run':
                  selectNumber(item, 'SA')
                  selectNumber(item, 'SB')
                  break
                case 'straight': {
                  let target = []
                  for (let i = 0; i < 10; i++) {
                    let front = item.concat(i)
                    let back = i.toString().concat(item)
                    target.push(front)
                    target.push(back)
                  }
                  _.uniqBy(target).forEach((item) => {
                    selectNumber(item, '2A')
                  })
                }
                  {
                    let target = []
                    for (let i = 0; i < 10; i++) {
                      let front = item.concat(i)
                      let back = i.toString().concat(item)
                      target.push(front)
                      target.push(back)
                    }
                    _.uniqBy(target).forEach((item) => {
                      selectNumber(item, '2B')
                    })
                  }
                  break
                default:
                  break
              }
            })
          })
        }
        setIsLoading(false)
      })
    return () => {
    }
  }, [fetchPayRate])

  useEffect(() => {
    if (showModal.isShow) {
      document.body.style.overflow = 'hidden'
    }

    if (!showModal.isShow) {
      document.body.style.overflow = 'unset'
      window.scrollTo(0, -10000)
    }

    return () => {
    }
  }, [showModal])

  useEffect(() => {
    window.scrollTo(0, -10000)
  }, [])

  return (
    <div id='containingDiv' className={`buy-lotto__wrapper page-lotto ${isPermutation ? 'permutation' : ''}`}>
      {showModal.currentModal === 'ใส่ราคา' ? (
        <CheckoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          lottoGroup={lottoGroup}
          type={currentType}
          currentDigit={currentDigit}
          highestPayRate={highestPayRate}
        />
      ) : null}
      {/*{lotto.lottoGroup === 'หวยยี่กี' && showYeekeeModal.currentModal === 'ยิงเลข' ? (*/}
      {/*  <YeekeeModal*/}
      {/*    showModal={showYeekeeModal}*/}
      {/*    setShowModal={setShowYeekeeModal}*/}
      {/*    lottoNumber={lottoNumber}*/}
      {/*    lottoData={lotto}*/}
      {/*  />*/}
      {/*) : null}*/}
      {lotto.lottoName === 'หวยรัฐบาลไทย' && showModal.currentModal === 'เลขอั้น' ? (
        <PayRateModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          fetchPayRate={fetchPayRate}
          highestPayRate={highestPayRate}
        />
      ) : null}
      {showModal.currentModal === 'confirm' ? (
        <ConfirmCheckoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          lottoData={lotto}
          lottoGroup={lottoGroup}
          highestPayRate={highestPayRate}
          isAgentSubmit={isAgentSubmit}
          setIsAgentSubmit={setIsAgentSubmit}
          customerTable={customerTable}
          setCustomerTable={setCustomerTable}
        />
      ) : null}
      {showModal.currentModal === 'ดูสถิติ' ? (
        <StatisticsModal showModal={showModal} setShowModal={setShowModal} lottoData={lotto} />
      ) : null}

      {/*<RulesModal showModal={showModal} setShowModal={setShowModal} lottoData={lotto} />*/}
      {showModal.currentModal === 'ดึงโพย' ? (
        <ReceiptModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoData={lotto}
          lottoNumber={lottoNumber}
          fetchPayRate={fetchPayRate}
        />
      ) : null}
      <Header />
      <div className='buy-lotto container mx-auto'>
        {BuyLottoLeft()}
        {BuyLottoRight()}
        {SticktyButton()}
      </div>
    </div>
  )
}

export default connect()(BuyLotto)