import PropTypes from 'prop-types'
import React from 'react'

import './Button.scss'

const Button = ({
  text,
  wrapperClassName,
  buttonClassName,
  buttonBackground,
  shouldNotShowCursor,
  onClick,
  onButtonClick,
  isActive,
}) => {
  const _onClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }
  return (
    <div
      onClick={onButtonClick}
      style={{ background: buttonBackground }}
      className={`singleButtonWrapper ${wrapperClassName || ''}`}
    >
      <button
        onClick={_onClick}
        style={{ cursor: shouldNotShowCursor ? 'auto' : 'pointer' }}
        className={`${buttonClassName || ''} ${isActive ? 'active' : ''}`}
      >
        {text}
      </button>
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.node,
  wrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonBackground: PropTypes.string,
  shouldNotShowCursor: PropTypes.bool,
  onClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  isActive: PropTypes.bool,
}

Button.defaultProps = {
  buttonBackground: `rgb(95, 200, 46)`,
  shouldNotShowCursor: false,
  isActive: false,
}

export default Button
