import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth, API, sectionBody } from 'aws-amplify'
import { useSelector, useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _sumBy from 'lodash/sumBy'
import _sortBy from 'lodash/sortBy'
import _reverse from 'lodash/reverse'
import _some from 'lodash/some'
import _isEqual from 'lodash/isEqual'
// import _isNumber from 'lodash/isNumber'
import Swal from 'sweetalert2'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

import { getWSEndpoint } from '../../config/cognito'
import { setWalletCredit } from '../../redux/actions/wallet'
import { getAuth } from '../../redux/selectors/auth'
// import { getWallet } from '../../redux/selectors/wallet'

import './DropdownNotification.scss'

const importantNotificationText = [
  'ฝากเงินสำเร็จแล้วครับ',
  'ฝากเงินไม่สำเร็จครับ กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่ครับ',
  'บัญชีธนาคารได้รับการอนุมัติแล้วครับ',
  'บัญชีธนาคารถูกปฏิเสธ โปรดติดต่อเจ้าหน้าที่ครับ',
  'ถอนเงินสำเร็จแล้วครับผม เฮงๆรวยๆนะครับ',
  'คำสั่งถอนเงินล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่ครับ',
  'ยกเลิกคำสั่งซื้อเรียบร้อยแล้วครับ',
  'ถูกรางวัล  \\d+  บาท!!! ยินดีด้วยครับ'
]

function DropdownNotification(props) {
  const auth = useSelector(getAuth)
  // const wallet = useSelector(getWallet)

  const [isLoading, setIsLoading] = useState(false)
  const [notiItems, setNotiItems] = useState([])
  const [needSort, setNeedSort] = useState('')
  // const storage = getStorage()
  // const wsEndpoint = getWSEndpoint()
  // const [ws, setWs] = useState({})
  // const [startTime, setStartTime] = useState()
  const dispatch = useDispatch()
  // const [isMessage, setIsMessage] = useState(false)
  const [second, setSecond] = useState(0)
  const [isFirstFetchNoti, setIsFirstFetchNoti] = useState(false)

  // console.log(second)

  let isCreatedWallet = false

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond(() => new Date().getSeconds()) // <-- Change this line!
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const getNumberOfUnreadNotifications = (noti = null) => {
    const notification = noti || notiItems
    if (!_isEmpty(notification)) {
      const unreadNoti = _sumBy(notification, (noti) => !noti.isRead)
      if (typeof props.getNumberUnreadNotifications !== undefined) {
        props.getNumberUnreadNotifications(unreadNoti)
      }
      return unreadNoti
    }
    return 0
  }

  const notiTranslation = (message) => {
    if (message !== undefined && message.includes('You got')) {
      return 'ถูกรางวัล ' + message.slice(7, -10) + ' บาท!!! ยินดีด้วยครับ'
    } else {
      const translatedNoti = {
        'Deposit transaction was approved.': 'ฝากเงินสำเร็จแล้วครับ',
        'Deposit transaction was rejected.': 'ฝากเงินไม่สำเร็จครับ กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่ครับ',
        'Your bank account was approved.': 'บัญชีธนาคารได้รับการอนุมัติแล้วครับ',
        'Your bank account was rejected.': 'บัญชีธนาคารถูกปฏิเสธ โปรดติดต่อเจ้าหน้าที่ครับ',
        'Withdraw transaction was approved.': 'ถอนเงินสำเร็จแล้วครับ เฮงๆรวยๆนะครับ',
        'Withdraw transaction was rejected.': 'คำสั่งถอนเงินล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่ครับ',
        'Your receipt was cancelled.': 'ยกเลิกคำสั่งซื้อเรียบร้อยแล้วครับ',
        'You does not confirm the receipt within time. Please purchase again.':
          'คำสั่งซื้อหมดเวลาแล้วนะครับ กรุณาสั่งซื้อใหม่ครับผม',
      }
      return translatedNoti[message]
    }
  }

  function getWalletCredit() {
    // console.log(auth, 'auth')
    API.get('playerWallet', `/players/${auth.username}/wallets/0`)
      .then((res) => {
        // console.log(res, 'res credit')
        setIsLoading(true)
        const credit = _.get(res, 'detail.credit', 0)
        if (credit === 'No credit exists' && !isCreatedWallet) {
          dispatch(
            setWalletCredit({
              wallet: {
                credit: 0,
              },
            })
          )
          setIsLoading(false)
        } else {
          dispatch(
            setWalletCredit({
              wallet: {
                credit,
              },
            })
          )
          setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
        setIsLoading(false)
      })
  }

  const getNotifications = async () => {
    console.log('===== Get Noti ======')

    setIsLoading(true)

    await API.get('notification', `/players/${auth.username}?limit=10`)
      .then((res) => {
        const notifications = _get(res, 'detail', [])
        let newArray = []
        for (let i = 0; i < notifications.length; i++) {
          newArray.push({
            ...notifications[i],
            message:
              notiTranslation(notifications[i].message) !== undefined
                ? notiTranslation(notifications[i].message)
                : notifications[i].message,
          })
        }
        setNotiItems(newArray)
        getNumberOfUnreadNotifications(newArray)
        setNeedSort('all')
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  // const getWebSocketNoti = async () => {
  //   try {
  //     const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
  //     // console.log(idToken, 'idToken')
  //     const uri = `${wsEndpoint}?token=${idToken}`
  //     const client = new W3CWebSocket(uri)
  //     // console.log(client, 'websocketClient')
  //     setWs(client)
  //     const timeStart = new Date().getTime()
  //     setStartTime(timeStart)
  //     // client.on('connect', (connection) => {
  //     //   /* declare function */
  //     //   function ping() {
  //     //     if (connection.connected) {
  //     //       console.log('send ping')
  //     //       connection.sendUTF(
  //     //         JSON.stringify({
  //     //           action: 'ping',
  //     //         })
  //     //       )
  //     //     }
  //     //   }
  //     //
  //     //   /* after connected */
  //     //   console.log('connected')
  //     //   ping()
  //     //
  //     //   connection.on('message', (message) => {
  //     //     const data = JSON.parse(message.utf8Data)
  //     //     console.log(data, 'data')
  //     //
  //     //     switch (data.action) {
  //     //       case 'notification':
  //     //         console.log('notification')
  //     //         console.log(data.data)
  //     //         break
  //     //       case 'pong':
  //     //         console.log('pong')
  //     //         setTimeout(ping, 54000)
  //     //         break
  //     //
  //     //       default:
  //     //         console.log(`invalid action ${data.action}`)
  //     //         console.log(data, 'data')
  //     //     }
  //     //   })
  //     //
  //     //   connection.on('close', (code, desc) => {
  //     //     console.log('connection closed', code, desc)
  //     //   })
  //     // })
  //     //
  //     // console.log('connecting')
  //     // client.connect(uri)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const readNotification = (notificationNumber) => {
    setIsLoading(true)
    // console.log(notificationNumber, 'notificationNumber')
    return API.post('notification', `/players/${auth.username}/${notificationNumber}`)
      .then((res) => {
        if (res.code === 0) {
          // Success, so refetch
          setNotiItems((prevState) =>
            prevState.map((obj) =>
              obj.notificationNumber === notificationNumber ? Object.assign(obj, { isRead: true }) : obj
            )
          )
          getNumberOfUnreadNotifications(notiItems)
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _onClickRead = (item) => {
    if (item.isRead) {
      return
    }
    const { notificationNumber } = item
    readNotification(notificationNumber)
  }

  useEffect(() => {
    if (!_.isNull(auth.username)) {
      // console.log(second)
      if (!isFirstFetchNoti) {
        // console.log('--- get First Noti ---')
        getNotifications()
        setIsFirstFetchNoti(true)
      }
      // console.log('--- get Noti ---')
      if (second === 10 || second === 40 || second === 11 || second === 41) {
        if (second === 10 || second === 40) {
          getNotifications()
        } else {
          if (!_isEmpty(notiItems)) {
            // console.log(notiItems, 'notiItems')
            const importantNotiItems = notiItems.filter(
              (item) =>
                item.isRead === false &&
                _some(importantNotificationText, (important) => {
                  // console.log(important, 'importantasd;lkfjals;kdjfl;kasdjfkl;as')
                  // console.log(item, 'itemmasd;flkjals;kdfj;laksdjfasdfasfd')
                  const regex = new RegExp(important)
                  return regex.test(item.message)
                })
            )
            // console.log(notiItems, 'notiItems')
            // console.log(importantNotiItems, 'importantNotiItems')

            // setTimeout(() => {
            if (!_isEmpty(importantNotiItems)) {
              const groupMessage = importantNotiItems.map((item) => ({
                imageUrl: require('../../assets/modal/coin.gif'),
                // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/${
                //   item.message.slice(-6) === 'บาทครับ'
                //     ? 'invite'
                //     : item.message.slice(-6) === 'ล้วครับ'
                //     ? 'brand'
                //     : item.message.slice(-6) === 'ยๆครับ'
                //     ? 'withdraw'
                //     : 'care'
                // }-st.png`,
                title: item.message,
                confirmButtonText: 'ทราบแล้ว',
                onClose: () => {
                  _onClickRead(item)
                },
              }))
              Swal.mixin({
                confirmButtonText: 'ทราบแล้ว',
              }).queue(groupMessage)
              getWalletCredit()
            }
            // }, 1000)
          }
        }
      }
    }
  }, [auth.username, second])

  // useEffect(() => {
  //   function ping(connection) {
  //     // console.log(connection, 'connection TYPEEEEEE')
  //     const connectType = connection.type
  //     if (connectType === 'open' || connectType === 'message') {
  //       console.log('send ping')
  //       ws.send(
  //         JSON.stringify({
  //           action: 'ping',
  //         })
  //       )
  //       // console.log(connection)
  //     }
  //   }

  //   ws.onopen = (connection) => {
  //     // console.log(connection, 'connection')
  //     console.log('WebSocket Connected')

  //     const myInterval = setInterval(() => {
  //       const timeNow = new Date().getTime()
  //       // console.log(timeNow, 'timeNow')
  //       // console.log(startTime, 'startTime')
  //       // console.log(timeNow - startTime, 'timeNow - startTime')
  //       if (timeNow - startTime > 6650000) {
  //         return () => clearInterval(myInterval)
  //       }
  //       ping(connection)
  //     }, 540000)
  //     return () => clearInterval(myInterval)
  //   }

  //   ws.onmessage = (e) => {
  //     console.log(e, 'e onmessage')
  //     const response = JSON.parse(e.data)
  //     // console.log(response, 'response')
  //     const newNotiItem = response.data
  //     // console.log(newNotiItem, 'message')
  //     const action = response.action
  //     // console.log(action, 'action')

  //     if (action === 'notification') {
  //       const newNoti = {
  //         ...newNotiItem,
  //         message:
  //           notiTranslation(newNotiItem.message) !== undefined
  //             ? notiTranslation(newNotiItem.message)
  //             : newNotiItem.message,
  //         isRead: false,
  //       }
  //       setNotiItems((prevState) => [newNoti, ...prevState])
  //       // console.log(notiItems, 'notiItems in useEffect')
  //       setNeedSort(newNotiItem.notificationNumber)
  //       setIsMessage(true)
  //       getWalletCredit()
  //     }

  //     if (action === 'pong') {
  //       console.log('pong')
  //       // console.log(e, 'connection in pong')
  //       // setTimeout(ping(ws), 9000)
  //     }
  //   }

  //   return () => {
  //     // ws.onerror = (evt) => {
  //     //   console.log(evt, 'evt')
  //     // }
  //     ws.onclose = () => {
  //       // console.log(e, 'e')
  //       console.log('WebSocket Disconnected')
  //       return getWebSocketNoti()
  //     }
  //   }
  // }, [ws.onmessage, ws.onopen, ws.onclose])

  useEffect(() => {
    // console.log('sort process')
    const sortedDetail = _reverse(_sortBy(notiItems, (noti) => moment(noti.timestamp).format('YYYY-MM-DD HH:mm:ss')))
    if (notiItems.length === sortedDetail.length || _isEqual(notiItems, sortedDetail)) {
      // Compare notification items with the new one (from interval check up), if so, early return
      getNumberOfUnreadNotifications(sortedDetail)
      setNotiItems(sortedDetail)
      return
    }
    setNotiItems(sortedDetail)
    getNumberOfUnreadNotifications(notiItems)
    return () => { }
  }, [needSort])

  return (
    <div className={`header_notification__dropdown ${props.activeClassname}`}>
      <div className='btn-bevel text-center py-1 px-5 mb-2 strong' style={{ background: '#AE9F72' }} to='/notification'>
        <span className='text-center'>การแจ้งเตือน</span>
      </div>
      <ul className='header_notification__dropdown-list'>
        {isLoading ? (
          <div className='loading-gif' style={{ top: '20%' }}>
            <img className='loading-indicator-gif' src={require('../../assets/loading.gif')} alt='loading' />
          </div>
        ) : null}
        {notiItems.map((item, index) => (
          <li
            key={index}
            className={`header_notification__dropdown-item ${!item.isRead ? 'unread' : ''}`}
            onClick={() => _onClickRead(item)}
          >
            <div className='header_notification__dropdown-content'>
              <div className='notification__dropdown-content-wrapper'>
                <div className='unread-indicator'></div>
                <div>
                  <h4 className='text-left'>{item.message}</h4>
                  <p>{moment(item.timestamp).subtract(7, 'hours').format('DD/MM/YYYY HH:mm')}</p>
                </div>
              </div>
              <div className='notification__dropdown-button-wrapper'>
                <span className='btn-bevel px-2 text-base'>ทราบแล้ว</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

DropdownNotification.propTypes = {
  activeClassname: PropTypes.string,
  getNumberUnreadNotifications: PropTypes.func,
}

DropdownNotification.defaultProps = {
  activeClassname: '',
  getNumberUnreadNotifications: () => { },
}

export default DropdownNotification
