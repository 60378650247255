import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import goButton from '../../assets/angalong/button/go_button.png'
import TrafficLight from 'react-trafficlight'
import fiveChip from '../../assets/angalong/chip/chip_5.png'
import tenChip from '../../assets/angalong/chip/chip_10.png'
import twentyChip from '../../assets/angalong/chip/chip_20.png'
import fiftyChip from '../../assets/angalong/chip/chip_50.png'
import hundredChip from '../../assets/angalong/chip/chip_100.png'
import fiveHundredChip from '../../assets/angalong/chip/chip_500.png'
import thousandChip from '../../assets/angalong/chip/chip_1000.png'
import anyChip from '../../assets/angalong/chip/chip_any.png'
import angelDice from '../../assets/angalong/dice/angel_dice.png'
import dongDice from '../../assets/angalong/dice/dong_dice.png'
import elephantDice from '../../assets/angalong/dice/elephant_dice.png'
import horseDice from '../../assets/angalong/dice/horse_dice.png'
import nagaDice from '../../assets/angalong/dice/naga_dice.png'
import tigerDice from '../../assets/angalong/dice/tiger_dice.png'
import anyDice from '../../assets/angalong/dice/any_dice.png'
import angelDong from '../../assets/angalong/reg_card/9_angel_dong.png'
import elephantAngel from '../../assets/angalong/reg_card/5_elephant_angel.png'
import angelNaga from '../../assets/angalong/reg_card/6_angel_naga.png'
import angelTiger from '../../assets/angalong/reg_card/7_angel_tiger.png'
import nagaTiger from '../../assets/angalong/reg_card/10_naga_tiger.png'
import nagaDong from '../../assets/angalong/reg_card/12_naga_dong.png'
import elephantTiger from '../../assets/angalong/reg_card/3_elephant_tiger.png'
import elephantHorse from '../../assets/angalong/reg_card/2_elephant_horse.png'
import elephantDong from '../../assets/angalong/reg_card/1_elephant_dong.png'
import elephantNaga from '../../assets/angalong/reg_card/4_elephant_naga.png'
import horseDong from '../../assets/angalong/reg_card/15_horse_dong.png'

import angelHorse from '../../assets/angalong/reg_card/8_angel_horse.png'
import nagaHorse from '../../assets/angalong/reg_card/11_naga_horse.png'
import tigerHorse from '../../assets/angalong/reg_card/13_tiger_horse.png'
import tigerDong from '../../assets/angalong/reg_card/14_tiger_dong.png'
import nagaCard from '../../assets/angalong/square_card/naga_t.png'
import elephantCard from '../../assets/angalong/square_card/elephant_t.png'
import horseCard from '../../assets/angalong/square_card/horse_t.png'
import tigerCard from '../../assets/angalong/square_card/tiger_t.png'
import angelCard from '../../assets/angalong/square_card/angel_t.png'
import dongCard from '../../assets/angalong/square_card/dong_t.png'
import nagaCardActive from '../../assets/angalong/square_card/naga.png'
import elephantCardActive from '../../assets/angalong/square_card/elephant.png'
import horseCardActive from '../../assets/angalong/square_card/horse.png'
import tigerCardActive from '../../assets/angalong/square_card/tiger.png'
import angelCardActive from '../../assets/angalong/square_card/angel.png'
import dongCardActive from '../../assets/angalong/square_card/dong.png'
import board from '../../assets/angalong/element/bg.png'
import boxDice from '../../assets/angalong/element/box.png'
import robe from '../../assets/angalong/element/robe.png'


import './Angalong.scss'
// import homeIconActive from '../../components/navigator/assets/home-active.png'
// import homeIcon from '../../components/navigator/assets/dong-home.png'

import _ from 'lodash'
import { API } from 'aws-amplify'
import Swal from 'sweetalert2'
import { clearCart, updateAmount } from '../../redux/actions/cart'
import numeral from 'numeral'
import { getWallet } from '../../redux/selectors/wallet'
import _get from 'lodash/get'
import { setWalletCredit } from '../../redux/actions/wallet'
import { getAuth } from '../../redux/selectors/auth'
import { setReceipt } from '../../redux/actions/receipts'

const Angalong = () => {
  const [currentChip, setCurrentChip] = useState(5)
  const [activeCard, setActiveCard] = useState([1, 1, 1])
  const [rewardCard, setRewardCard] = useState([0, 0, 0])
  const [previousBuy, setPreviousBuy] = useState([])
  const [isUndo, setIsUndo] = useState(false)
  const [isBuy, setIsBuy] = useState(false)
  const [buyHistory, setBuyHistory] = useState([])
  const [buyOne, setBuyOne] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
  const [buyPair, setBuyPair] = useState({
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    23: 0,
    24: 0,
    25: 0,
    26: 0,
    35: 0,
    36: 0,
    43: 0,
    45: 0,
    46: 0,
    56: 0,
  })
  const [lastBuyOne, setLastBuyOne] = useState({})
  const [lastBuyPair, setLastBuyPair] = useState({})
  const [second, setSecond] = useState(0)
  const [isAuto, setIsAuto] = useState(false)
  const [isMultiply, setIsMultiply] = useState(false)
  const [createReceipt, setCreateReceipt] = useState({})
  const [buyAngalong, setBuyAngalong] = useState({})
  const [resultAngalong, setResultAngalong] = useState({})
  const [walletValidate, setWalletValidate] = useState(null)

  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0)
  const sumBuyOne = sumValues(buyOne)
  const sumBuyPair = sumValues(buyPair)
  const purchaseAmount = sumBuyOne + sumBuyPair

  let history = useHistory()
  const dispatch = useDispatch()
  const walletCredit = useSelector((state) => _.get(state, 'wallet.credit', null))
  const auth = useSelector(getAuth)

  const activeOne = {
    1: _.countBy(rewardCard)['1'] > 0 ? _.countBy(rewardCard)['1'] + 1 : 0,
    2: _.countBy(rewardCard)['2'] > 0 ? _.countBy(rewardCard)['2'] + 1 : 0,
    3: _.countBy(rewardCard)['3'] > 0 ? _.countBy(rewardCard)['3'] + 1 : 0,
    4: _.countBy(rewardCard)['4'] > 0 ? _.countBy(rewardCard)['4'] + 1 : 0,
    5: _.countBy(rewardCard)['5'] > 0 ? _.countBy(rewardCard)['5'] + 1 : 0,
    6: _.countBy(rewardCard)['6'] > 0 ? _.countBy(rewardCard)['6'] + 1 : 0,
  }
  const activePair = {
    12: _.includes(rewardCard, 1) && _.includes(rewardCard, 2),
    13: _.includes(rewardCard, 1) && _.includes(rewardCard, 3),
    14: _.includes(rewardCard, 1) && _.includes(rewardCard, 4),
    15: _.includes(rewardCard, 1) && _.includes(rewardCard, 5),
    16: _.includes(rewardCard, 1) && _.includes(rewardCard, 6),
    23: _.includes(rewardCard, 2) && _.includes(rewardCard, 3),
    24: _.includes(rewardCard, 2) && _.includes(rewardCard, 4),
    25: _.includes(rewardCard, 2) && _.includes(rewardCard, 5),
    26: _.includes(rewardCard, 2) && _.includes(rewardCard, 6),
    35: _.includes(rewardCard, 3) && _.includes(rewardCard, 5),
    36: _.includes(rewardCard, 3) && _.includes(rewardCard, 6),
    43: _.includes(rewardCard, 4) && _.includes(rewardCard, 3),
    45: _.includes(rewardCard, 4) && _.includes(rewardCard, 5),
    46: _.includes(rewardCard, 4) && _.includes(rewardCard, 6),
    56: _.includes(rewardCard, 5) && _.includes(rewardCard, 6),
  }

  const cube = document.getElementById('cube')
  const cube2 = document.getElementById('cube2')
  const cube3 = document.getElementById('cube3')
  const minY = 16
  const maxY = 24
  const minX = 32
  const maxX = 48

  // const onClickCube = () => {
  //   const xRand = getRandom(max, min)
  //   const yRand = getRandom(max, min)
  //
  //   cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
  //   cube.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
  // }


  function getRandom(max, min) {
    return (Math.floor(Math.random() * (max - min)) + min) * 90
  }

  Array.prototype.random = function() {
    return this[Math.floor((Math.random() * this.length))]
  }

  // const walletAmount = _.get(useSelector(getWallet), 'credit', 0)

  const handleBuyAngalong = (type, amount) => {
    setIsUndo(false)
    setIsBuy(true)

    if (type < 10) {
      setBuyOne((prevState) => ({
        ...prevState,
        [type]: prevState[type] + amount >= 500 ? 500 : prevState[type] + amount,
      }))
    } else {
      setBuyPair((prevState) => ({
        ...prevState,
        [type]: prevState[type] + amount >= 500 ? 500 : prevState[type] + amount,
      }))
    }

    // const newPriceArray = priceArray.map((obj) => {
    //   if (obj.type === type && obj.detail === detail && obj.stamp === stamp) {
    //     obj.amount = amount
    //     dispatch(updateAmount({ purchaseItem: obj }))
    //   }
    //   return obj
    // })
    // setIsPriceChange(true)
    // setPriceArray(newPriceArray)
    // console.log('newPriceArray >>', newPriceArray)
  }

  const possibleChip = [0, 5, 10, 20, 50, 100, 500]

  const handleChip = (buy, type) => {
    // setIsAllSamePrice(false)

    if (buy === 'one') {
      if (buyOne[type] === 5) {
        return fiveChip
      }
      if (buyOne[type] === 10) {
        return tenChip
      }
      if (buyOne[type] === 20) {
        return twentyChip
      }
      if (buyOne[type] === 50) {
        return fiftyChip
      }
      if (buyOne[type] === 100) {
        return hundredChip
      }
      if (buyOne[type] === 500) {
        return fiveHundredChip
      }
      return anyChip
    }

    if (buy === 'pair') {
      if (buyPair[type] === 5) {
        return fiveChip
      }
      if (buyPair[type] === 10) {
        return tenChip
      }
      if (buyPair[type] === 20) {
        return twentyChip
      }
      if (buyPair[type] === 50) {
        return fiftyChip
      }
      if (buyPair[type] === 100) {
        return hundredChip
      }
      if (buyPair[type] === 500) {
        return fiveHundredChip
      }
      return anyChip
    }

    if (buy === 'current') {
      if (currentChip === 5) {
        return fiveChip
      }
      if (currentChip === 10) {
        return tenChip
      }
      if (currentChip === 20) {
        return twentyChip
      }
      if (currentChip === 50) {
        return fiftyChip
      }
      if (currentChip === 100) {
        return hundredChip
      }
      if (currentChip === 500) {
        return fiveHundredChip
      }
      return null
    }
  }

  const resetButton = () => {
    setIsBuy(false)
    setIsUndo(true)
    setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
    setBuyPair({
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      35: 0,
      36: 0,
      43: 0,
      45: 0,
      46: 0,
      56: 0,
    })
    setBuyHistory([{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }, {
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      35: 0,
      36: 0,
      43: 0,
      45: 0,
      46: 0,
      56: 0,
    }])
    setPreviousBuy([])
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const undoButton = () => {
    if (buyHistory.length > 2) {
      setIsUndo(true)
      if (isMultiply) {
        // const removeBuy = buyHistory.splice(-1)
        // console.log('remove last buyOne')
        const lastPairBuy = previousBuy.findLast((element) => Object.keys(element).length === 15)
        // console.log(lastBuy, 'newObj')
        setBuyPair(lastPairBuy)
        setBuyHistory(prevState => prevState.slice(0, -1))
        setPreviousBuy(prevState => prevState.slice(0, -1))

        const lastOneBuy = previousBuy.findLast((element) => Object.keys(element).length === 6)
        // console.log(lastBuy, 'newObj')
        setBuyOne(lastOneBuy)
        setBuyHistory(prevState => prevState.slice(0, -1))
        setPreviousBuy(prevState => prevState.slice(0, -1))

      } else {
        const lastTypeBuy = Object.keys(buyHistory[buyHistory.length - 1]).length
        // console.log(lastTypeBuy, 'lastTysdfgsdfgsdfgsdfgpeBuy')
        if (lastTypeBuy === 6) {
          // const removeBuy = buyHistory.splice(-1)
          // console.log('remove last buyOne')
          const lastBuy = previousBuy.findLast((element) => Object.keys(element).length === 6)
          // console.log(lastBuy, 'newObj')
          setBuyOne(lastBuy)
          setBuyHistory(prevState => prevState.slice(0, -1))
          setPreviousBuy(prevState => prevState.slice(0, -1))
        }
        if (lastTypeBuy === 15) {
          const lastBuy = previousBuy.findLast((element) => Object.keys(element).length === 15)
          // console.log(lastBuy, 'newObj')
          setBuyPair(lastBuy)
          setBuyHistory(prevState => prevState.slice(0, -1))
          setPreviousBuy(prevState => prevState.slice(0, -1))
        }
      }
    }
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const multiplyButton = () => {
    setIsUndo(false)
    setIsBuy(true)
    setIsMultiply(true)
    const buyOneType = [1, 2, 3, 4, 5, 6]
    const buyPairType = [12,
      13,
      14,
      15,
      16,
      23,
      24,
      25,
      26,
      35,
      36,
      43,
      45,
      46,
      56]
    for (let i = 0; i < buyOneType.length; i++) {
      setBuyOne((prevState) => ({
        ...prevState,
        [buyOneType[i]]: prevState[buyOneType[i]] * 2 >= 500 ? 500 : prevState[buyOneType[i]] * 2,
      }))
    }
    for (let j = 0; j < buyPairType.length; j++) {
      setBuyPair((prevState) => ({
        ...prevState,
        [buyPairType[j]]: prevState[buyPairType[j]] * 2 >= 500 ? 500 : prevState[buyPairType[j]] * 2,
      }))
    }
  }

  const luckyButton = () => {
    setIsUndo(false)
    setIsBuy(true)
    const rollDice = () => {
      return 1 + Math.floor(Math.random() * 4);
    }
    const buyOneType = [1, 2, 3, 4, 5, 6]
    const buyPairType = [12,
      13,
      14,
      15,
      16,
      23,
      24,
      25,
      26,
      35,
      36,
      43,
      45,
      46,
      56]

    if (rollDice() < 4) {
      const random = Math.floor(Math.random() * buyOneType.length);
      setBuyOne((prevState) => ({
        ...prevState,
        [buyOneType[random]]: prevState[buyOneType[random]] + currentChip,
      }))
    } else {
      const random = Math.floor(Math.random() * buyPairType.length);
      setBuyPair((prevState) => ({
        ...prevState,
        [buyPairType[random]]: prevState[buyPairType[random]] + currentChip,
      }))
    }
  }

  const followButton = () => {
    setIsUndo(true)
    setIsBuy(true)
    if (!_.isEmpty(lastBuyOne)) {
      console.log(lastBuyOne, 'lastBuyOne')
      const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
      setBuyOne(localBuyOne)
    }
    if (!_.isEmpty(lastBuyPair)) {
      console.log(lastBuyPair, 'lastBuyPair')
      const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
      setBuyPair(localBuyPair)
    }
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const autoPlayButton = () => {
    if (second >= 15 && second <= 50) {
      if (purchaseAmount <= 0) {
        setIsUndo(true)
        setIsBuy(true)
        if (!_.isEmpty(lastBuyOne)) {
          console.log(lastBuyOne, 'lastBuyOne')
          const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
          if (buyOne !== localBuyOne) {
            setBuyOne(localBuyOne)
          }
        }
        if (!_.isEmpty(lastBuyPair)) {
          console.log(lastBuyPair, 'lastBuyPair')
          const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
          if (buyPair !== localBuyPair) {
            setBuyPair(localBuyPair)
          }
        }
      }
    }
    setIsAuto(!isAuto)
  }

  // console.log(buyOne, buyPair, 'asdfasdfasdfasdfasdfasdf')

  const angalongDice = (cube, place) => {
    const currentDice = place === 'first' ? activeCard[0] : place === 'second' ? activeCard[1] : activeCard[2]
    return (
      <div className={`cube_container ${place}`}>
        <div id={cube} className={`${place}-dice`}>
          <div className="front">
            <img
              src={currentDice === 1 ? elephantDice : currentDice === 2 ? angelDice : currentDice === 3 ? tigerDice : currentDice === 4 ? nagaDice : currentDice === 5 ? horseDice : currentDice === 6 ? dongDice : anyDice}
              className={`${place}-dice-front`}
              alt={`${place}-dice-front`}
            />
          </div>
          <div className="right">
            <img
              src={currentDice === 1 ? angelDice : currentDice === 2 ? tigerDice : currentDice === 3 ? nagaDice : currentDice === 4 ? horseDice : currentDice === 5 ? dongDice : currentDice === 6 ? elephantDice : anyDice}
              className={`${place}-dice-right`}
              alt={`${place}-dice-right`}
            />
          </div>
          <div className="top">
            <img
              src={currentDice === 1 ? tigerDice : currentDice === 2 ? nagaDice : currentDice === 3 ? horseDice : currentDice === 4 ? dongDice : currentDice === 5 ? elephantDice : currentDice === 6 ? angelDice : anyDice}
              className={`${place}-dice-top`}
              alt={`${place}-dice-top`}
            />
          </div>
          <div className="back">
            <img
              src={currentDice === 1 ? nagaDice : currentDice === 2 ? horseDice : currentDice === 3 ? dongDice : currentDice === 4 ? elephantDice : currentDice === 5 ? angelDice : elephantDice[0] === 6 ? tigerDice : anyDice}
              className={`${place}-dice-back`}
              alt={`${place}-dice-back`}
            />
          </div>
          <div className="left">
            <img
              src={currentDice === 1 ? horseDice : currentDice === 2 ? dongDice : currentDice === 3 ? elephantDice : currentDice === 4 ? angelDice : currentDice === 5 ? tigerDice : currentDice === 6 ? nagaDice : anyDice}
              className={`${place}-dice-left`}
              alt={`${place}-dice-left`}
            />
          </div>
          <div className="bottom">
            <img
              src={currentDice === 1 ? dongDice : currentDice === 2 ? elephantDice : currentDice === 3 ? angelDice : currentDice === 4 ? tigerDice : currentDice === 5 ? nagaDice : currentDice === 6 ? horseDice : anyDice}
              className={`${place}-dice-bottom`}
              alt={`${place}-dice-bottom`}
            />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!isUndo) {
      setPreviousBuy(buyHistory)
      setBuyHistory(prevState => (
        [...prevState, buyOne]
      ))
    }
  }, [buyOne])

  useEffect(() => {
    if (!isUndo) {
      setPreviousBuy(buyHistory)
      setBuyHistory(prevState => (
        [...prevState, buyPair]
      ))
    }
  }, [buyPair])

  // console.log(buyHistory, 'buyHistory')
  // console.log(previousBuy, 'previousBuy')

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond(() => new Date().getSeconds()) // <-- Change this line!
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  // console.log(lastBuyOne, lastBuyPair, 'lasdfalsdkfjal;ksdjfas')

  useEffect(() => {
      if (second === 1) {
        for (let i = 0; i < 61; i++) {
          setTimeout(() => {
            const one = ~~(Math.random() * 6) + 1
            const two = ~~(Math.random() * 6) + 1
            const three = ~~(Math.random() * 6) + 1
            setActiveCard(() => ([one, two, three]))
            // console.log(i, 'i')
          }, (i + 1) * 100)
        }
      }
      if (second === 8) {
        setResultAngalong({})
        if (purchaseAmount > 0) {
          setIsUndo(true)
          setIsBuy(true)
          localStorage.setItem('lastBuyOne', JSON.stringify(buyOne))
          localStorage.setItem('lastBuyPair', JSON.stringify(buyPair))
          setLastBuyOne(buyOne)
          setLastBuyPair(buyPair)
        }
        // const xRand = getRandom(maxX, minX)
        // const yRand = getRandom(maxY, minY)
        //
        // const xRand2 = getRandom(maxX, minX)
        // const yRand2 = getRandom(maxY, minY)
        //
        // const xRand3 = getRandom(maxX, minX)
        // const yRand3 = getRandom(maxY, minY)
        //
        // const ranY = ['-','+'].random()
        //
        // console.log(xRand, yRand, xRand2, yRand2, xRand3, yRand3)
        //
        // // cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
        // cube.style.transform = 'rotateX(-' + xRand + 'deg) rotateY(' + ranY + '' + yRand + 'deg)'
        // cube2.style.transform = 'rotateX(-' + xRand2 + 'deg) rotateY(' + ranY + '' + yRand2 + 'deg)'
        // cube3.style.transform = 'rotateX(-' + xRand3 + 'deg) rotateY(' + ranY + '' + yRand3 + 'deg)'
        const fetchAngalong = async () => {
          const fetchResponse = await API.get('lotto', `/results?lottoGroup=Angalong&limit=1`)
          console.log(fetchResponse, 'fetchAngalong')
          if (fetchResponse.code === 0 && fetchResponse.detail.length > 0) {
            const resultLotto = fetchResponse.detail[0].results
            setResultAngalong(resultLotto)
          }
        }
        fetchAngalong().catch(console.error)
      }
      if (second === 15) {
        setIsUndo(true)
        setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
        setBuyPair({
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          23: 0,
          24: 0,
          25: 0,
          26: 0,
          35: 0,
          36: 0,
          43: 0,
          45: 0,
          46: 0,
          56: 0,
        })
        setBuyHistory([{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }, {
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          23: 0,
          24: 0,
          25: 0,
          26: 0,
          35: 0,
          36: 0,
          43: 0,
          45: 0,
          46: 0,
          56: 0,
        }])
        setPreviousBuy([])
        setRewardCard(() => ([0, 0, 0]))
      }
      if (isAuto && second === 16) {
        setIsUndo(true)
        if (!_.isEmpty(lastBuyOne)) {
          console.log(lastBuyOne, 'lastBuyOne')
          const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
          setBuyOne(localBuyOne)
        }
        if (!_.isEmpty(lastBuyPair)) {
          console.log(lastBuyPair, 'lastBuyPair')
          const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
          setBuyPair(localBuyPair)
        }
        // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
      }
      if (second === 30) {
        setActiveCard(() => ([1, 1, 1]))
      }
      if (second === 51 && purchaseAmount > 0) {
        if (!auth.username) {
          // Not login, so cannot purchase
          Swal.fire({
            icon: 'error',
            // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
            // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
            title: 'Error',
            text: 'Please log in to proceed',
            confirmButtonColor: '#B6964E',
            // confirmButtonText: 'เข้าสู่ระบบ',
          })
          return
        }
        setWalletValidate(null)
        setCreateReceipt({})
        setBuyAngalong({})
        // const walletBalance = fetchWallet()
        // console.log(walletBalance, purchaseAmount)
        // if (walletBalance >= purchaseAmount) {
        let allTong = []
        let allPair = []
        let allAlone = []
        let allBuyPair = []
        if (sumBuyOne > 0) {
          const allProperty = ['1', '2', '3', '4', '5', '6']
          for (const property in buyOne) {
            if (buyOne[property] > 0) {
              let duplicateAlone = []
              const filtered = allProperty.filter(function(value, index, arr) {
                return value !== property
              })
              console.log(filtered, 'filtered')
              allTong.push({ [`${property}${property}${property}`]: buyOne[property] * 4 / 9 })
              for (let i = 0; i < filtered.length; i++) {
                const pair = [parseInt(property), parseInt(property), parseInt(filtered[i])]
                const sortPair = pair.sort()
                const pairString = sortPair.toString().split(',').join('')
                allPair.push({ [pairString]: buyOne[property] / 15 })
                const filteredAlone = filtered.filter(function(value, index, arr) {
                  return value !== parseInt(filtered[i]).toString()
                })
                const alonePair = [parseInt(property), parseInt(filtered[i]), parseInt(filtered[i])]
                const sortAlonePair = alonePair.sort()
                const alonePairString = sortAlonePair.toString().split(',').join('')
                duplicateAlone.push(alonePairString)
                for (let j = 0; j < filteredAlone.length; j++) {
                  const alone = [parseInt(property), parseInt(filtered[i]), parseInt(filteredAlone[j])]
                  const sortAlone = alone.sort()
                  const aloneString = sortAlone.toString().split(',').join('')
                  duplicateAlone.push(aloneString)
                }
              }
              const uniqAlone = _.uniq(duplicateAlone)
              for (let k = 0; k < uniqAlone.length; k++) {
                allAlone.push({ [uniqAlone[k]]: buyOne[property] * 2 / 135 })
              }
            }
          }
          // console.log(allTong, allPair, allAlone)
        }
        if (sumBuyPair > 0) {
          for (const property in buyPair) {
            if (buyPair[property] > 0) {
              const pairArray = property.split('')
              const pairOne = [pairArray[0], pairArray[0], pairArray[1]]
              const pairTwo = [pairArray[0], pairArray[1], pairArray[1]]
              const sortPairOne = pairOne.sort()
              const sortPairTwo = pairTwo.sort()
              const pairOneString = sortPairOne.toString().split(',').join('')
              const pairTwoString = sortPairTwo.toString().split(',').join('')
              allBuyPair.push({ [pairOneString]: buyPair[property] / 2 }, { [pairTwoString]: buyPair[property] / 2 })
            }
          }
          // console.log(allBuyPair)
        }
        console.log(allTong, allPair, allAlone)
        console.log(allBuyPair)
        const getLottoData = async () => {
          const response = await API.get('lotto', '/lottos?lottoGroup=Angalong&status=open&limit=1')
          console.log(response, 'getLottoDataResponce')
          if (response.code === 0) {
            const data = response.detail || null
            if (!data || _.isEmpty(data)) {
              return Swal.fire({
                icon: 'error',
                title: `An error occurred`,
                text: 'Please return to Angalong menu. Or contact the staff',
                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                cancelButtonColor: '#C0C0C0',
                confirmButtonText: 'To Angalong',
              }).then((result) => {
                if (result.value) {
                  history.push('/angalong')
                }
              })
            } else {
              setCreateReceipt({
                allTong: allTong,
                allPair: allPair,
                allAlone: allAlone,
                allBuyPair: allBuyPair,
                lottoData: data[0],
              })
            }
          } else {
            return Swal.fire({
              icon: 'error',
              title: `An error occurred`,
              text: 'Please return to Angalong menu. Or contact the staff',
              confirmButtonColor: '#B6964E',
              confirmButtonText: 'To Angalong',
            }).then((result) => {
              console.log(result)
              history.push('/angalong')
            })
          }
        }
        getLottoData().catch(console.error)
      }
      // }
    }
    ,
    [second],
  )

  useEffect(() => {
    if (!_.isEmpty(createReceipt)) {
      console.log(createReceipt, 'createReceipt')
      const purchaseAngalong = async () => {
        const lottoData = createReceipt.lottoData

        if (_.isEmpty(lottoData)) {
          Swal.fire({
            icon: 'error',
            title: 'An error occurred',
            text: 'Please try again',
          })
          return
        }

        // setIsPurchasing(true) // To disable the "ส่งโพย" button while processing
        console.log(lottoData, 'lottoData')

        const lottoDetail = {
          refId: lottoData.lottoNumber,
          lottoGroup: lottoData.lottoGroup,
          lottoName: lottoData.lottoName,
          drawDateStr: lottoData.drawDateStr,
        }

        const purchaseAngalongAmount = Math.round((purchaseAmount + Number.EPSILON) * 100) / 100

        // console.log(sumPurchase, 'sumPurchase')
        // console.log(purchaseAmount, 'purchaseAmount2')
        // console.log(purchaseItems, 'purchaseItems2')

        let purchaseItems = []
        const purchaseBuyPair = createReceipt.allBuyPair
        const purchaseAllTong = createReceipt.allTong
        const purchaseAllPair = createReceipt.allPair
        const purchaseAllAlone = createReceipt.allAlone

        if (purchaseBuyPair.length > 0) {
          for (let i = 0; i < purchaseBuyPair.length; i++) {
            purchaseItems.push({
              type: '3R',
              detail: (Object.keys(purchaseBuyPair[i]))[0],
              amount: (Object.values(purchaseBuyPair[i]))[0],
              payRate: 12,
            })
          }
        }
        if (purchaseAllTong.length > 0) {
          for (let i = 0; i < purchaseAllTong.length; i++) {
            purchaseItems.push({
              type: '3B',
              detail: (Object.keys(purchaseAllTong[i]))[0],
              amount: (Object.values(purchaseAllTong[i]))[0],
              payRate: 9,
            })
          }
          for (let j = 0; j < purchaseAllPair.length; j++) {
            purchaseItems.push({
              type: '3A',
              detail: (Object.keys(purchaseAllPair[j]))[0],
              amount: (Object.values(purchaseAllPair[j]))[0],
              payRate: 45,
            })
          }
          for (let k = 0; k < purchaseAllAlone.length; k++) {
            purchaseItems.push({
              type: '3F',
              detail: (Object.keys(purchaseAllAlone[k]))[0],
              amount: (Object.values(purchaseAllAlone[k]))[0],
              payRate: 135,
            })
          }
        }
        console.log(purchaseItems, 'purchaseItems')

        const data = {
          playerUsername: auth.username, // This is Hash name, not human friendly name
          playerName: auth.attributes.name,
          receiptType: 'lotto',
          refId: lottoDetail.refId, // Earth See here
          // refId: '0', // Earth See here
          receiptDetail: {
            lottoGroup: lottoDetail.lottoGroup,
            lottoName: lottoDetail.lottoName,
            drawDateStr: lottoDetail.drawDateStr,
            remark: '',
          },
          purchaseItems,
          purchaseAngalongAmount,
          timeout: 120,
        }

        if (data.purchaseAmount > 10500) {
          Swal.fire({
            icon: 'error',
            // title: 'กรุณาทำรายการใหม่อีกครั้ง',
            title: 'Please try again',

            // html: 'แทงขั้นสูงสุดต่อครั้ง 100,000.00 </br> แทงขั้นสูงสุดต่อเลข 2,000.00',
            html: 'Max per receipt 10,500 </br> Max per number 500',

            confirmButtonColor: '#B6964E',
            // confirmButtonText: 'ทำรายการใหม่',
            confirmButtonText: 'New Bet',
          })
        } else if (purchaseAngalongAmount > walletCredit) {
          Swal.fire({
            icon: 'error',
            // title: 'เงินในกระเป๋าไม่พอ',
            title: 'Insufficiant fund',

            // confirmButtonText: 'ฝากเงิน',
            confirmButtonText: 'Deposit',
          }).then((result) => {
            if (result.value) {
              history.push('/deposit')
            }
          })
        } else {
          // About to buy angalong !!
          const createBuyAngalong = await API.post('purchase', '/receipts', { body: data })
          if (createBuyAngalong.code === 0) {
            const setReceipt = _.get(createBuyAngalong, 'detail', {})
            console.log(setReceipt, 'setReceipt')
            setBuyAngalong(setReceipt)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred',
              text: 'No Receipt Set',
            })
          }
        }
      }
      purchaseAngalong().catch(console.error)
    }
  }, [createReceipt])

  useEffect(() => {
    if (!_.isEmpty(buyAngalong)) {
      console.log(buyAngalong, 'buyAngalong')
      if (!buyAngalong.receiptNumber || !buyAngalong.receiptDetail.lottoName) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred',
          text: 'No Receipt Detail Set',
        })
      } else {
        const fetchWallet = async () => {
          if (auth.username) {
            const walletFetch = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (walletFetch.code === 0) {
              const credit = _get(walletFetch, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                }),
              )
              setWalletValidate(credit)
            } else {
              Swal.fire({
                icon: 'error',
                // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
                // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
                title: 'Error',
                text: 'Please log in to proceed',
                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'เข้าสู่ระบบ',
              })
            }
          }
        }
        fetchWallet().catch(console.error)
      }
    }
  }, [buyAngalong])

  useEffect(() => {
    if (walletValidate > 0) {
      console.log(walletValidate, 'walletValidate')
      console.log(buyAngalong, 'buyAngalong in walletValidate')
      const buyAngalongAmount = buyAngalong.purchaseAmount
      if (walletValidate >= buyAngalongAmount) {
        const confirmReceipt = async () => {
          const receiptNumber = buyAngalong.receiptNumber
          const receiptPurchaseAmount = buyAngalong.purchaseAmount
          const lottoName = buyAngalong.receiptDetail.lottoName
          const confirmAngalong = await API.post('purchase', `/receipts/${receiptNumber}/confirm`)
          if (confirmAngalong.code === 0) {
            // Success
            Swal.fire({
              // icon: 'error',
              title: '<h3 style=\'color:greenyellow\'>' + 'Purchase Complete' + '</h3>',
              text: `Total ${numeral(receiptPurchaseAmount).format('0,0')} Baht`,
              confirmButtonColor: 'transparent',
              background: 'transparent',
              showConfirmButton: false,
              timer: 3000,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred',
              text: confirmAngalong.detail || 'Please contact admin',
            })
          }
        }
        const refreshWallet = async () => {
          if (auth.username) {
            const responseWallet = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (responseWallet.code === 0) {
              const credit = _get(responseWallet, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                }),
              )
            }
          } else {
            // Not login, so cannot purchase
            Swal.fire({
              icon: 'error',
              // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
              // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
              title: 'Error',
              text: 'Please log in to proceed',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              // confirmButtonText: 'เข้าสู่ระบบ',
            })
          }
        }
        confirmReceipt().catch(console.error)
        refreshWallet().catch(console.error)
      } else {
        Swal.fire({
          icon: 'error',
          // title: 'ยอดเงินของคุณไม่เพียงพอ',
          title: 'Insufficient fund',
          // confirmButtonText: 'ฝากเงิน',
          confirmButtonText: 'Deposit',
          //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
          //     '0,0.00'
          //   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
          // }).then((result) => {
          //   if (result.value) {
          //     history.push('/deposit')
          //   }
          text: `Credit (${numeral(walletValidate).format('0,0.00')} Baht), require amount ${buyAngalongAmount}`,
        }).then((result) => {
          if (result.value) {
            history.push('/deposit')
          }
        })
      }
    }
  }, [walletValidate])

  useEffect(() => {
    if (!_.isEmpty(resultAngalong)) {
      const firstVal = parseInt(resultAngalong['6S'].split('')[0])
      const secondVal = parseInt(resultAngalong['6S'].split('')[1])
      const thirdVal = parseInt(resultAngalong['6S'].split('')[2])
      setActiveCard(() => ([firstVal, secondVal, thirdVal]))
      setRewardCard(() => ([firstVal, secondVal, thirdVal]))

      if (purchaseAmount > 0) {
        const tempReward = [firstVal, secondVal, thirdVal]
        let buyOnePrice = 0
        let buyPairPrice = 0

        let countBuyOne = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
        let countBuyPair = {
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          23: 0,
          24: 0,
          25: 0,
          26: 0,
          35: 0,
          36: 0,
          43: 0,
          45: 0,
          46: 0,
          56: 0,
        }

        if (sumBuyOne > 0) {
          for (const property in buyOne) {
            // console.log(property, 'property')
            const countReward = _.countBy(tempReward)[property]
            if (countReward > 0) {
              countBuyOne[property] = countReward + 1
            }
            // for (let i = 0; i < tempReward.length; i++) {
            //   // console.log(tempReward[i], 'reward')
            //   // console.log(buyOne[tempReward[i]])
            //   if (parseInt(property) === tempReward[i]) {
            //     countBuyOne[tempReward[i]] += 1
            //   }
            //   // countBuyOne[tempReward[i]] += buyOne[property]
            // }
          }
          // for (const property in countBuyOne) {
          //   if (countBuyOne[property] > 0) {
          //     countBuyOne[property] += 1
          //   }
          // }
          console.log(countBuyOne, 'countBuyOne')
          // const uniqTempReward = _.uniq(tempReward)
          for (const property in countBuyOne) {
            // for (let i = 0; i < uniqTempReward.length; i++) {
            //   // console.log(tempReward[i], 'reward')
            //   // console.log(buyOne[tempReward[i]])
            //   if (parseInt(property) === uniqTempReward[i]) {
            buyOnePrice += countBuyOne[property] * buyOne[property]
            // }
            // countBuyOne[tempReward[i]] += buyOne[property]
            // }
          }
        }
        console.log(buyOnePrice, 'buyOne Price')

        if (sumBuyPair > 0) {
          for (const property in buyPair) {
            // console.log(property, 'property')
            // console.log(property.split(''))
            // console.log(tempReward, 'tempReward')
            const firstNum = parseInt(property.split('')[0])
            const secondNum = parseInt(property.split('')[1])
            const arrayBuyPair = [firstNum, secondNum]
            // console.log(arrayBuyPair, 'arrayBuyPair')
            let allFounded = arrayBuyPair.every(ai => tempReward.includes(ai))
            if (allFounded) {
              // console.log(property, 'true')
              countBuyPair[property] += 5
            }
          }
          for (const property in countBuyPair) {
            if (countBuyPair[property] > 0) {
              countBuyPair[property] += 1
            }
          }
          for (const property in buyPair) {
            const firstNum = parseInt(property.split('')[0])
            const secondNum = parseInt(property.split('')[1])
            const arrayBuyPair = [firstNum, secondNum]
            let allFounded = arrayBuyPair.every(ai => tempReward.includes(ai))
            if (allFounded) {
              // console.log(property, 'true')
              buyPairPrice += countBuyPair[property] * buyPair[property]
            }
          }
        }
        // console.log(countBuyPair, 'countBuyPair')
        // console.log(buyOnePrice, buyPairPrice)
        const rewardPrize = buyPairPrice + buyOnePrice - purchaseAmount
        if (rewardPrize < 0) {
          Swal.fire({
            // icon: 'error',
            title: '<h3 style=\'color:red\'>' + 'You Lose' + '</h3>',
            text: -(rewardPrize) + ' Baht',
            confirmButtonColor: 'transparent',
            background: 'transparent',
            showConfirmButton: false,
            timer: 3000,
          })
        }
        if (rewardPrize > 0) {
          Swal.fire({
            // icon: 'error',
            title: '<h3 style=\'color:goldenrod\'>' + 'YOU WIN !!' + '</h3>',
            text: rewardPrize + ' BAHT',
            confirmButtonColor: 'transparent',
            background: 'transparent',
            showConfirmButton: false,
            timer: 3000,
          })
        }
        if (purchaseAmount > 0 && rewardPrize === 0) {
          Swal.fire({
            // icon: 'error',
            title: '<h3 style=\'color:goldenrod\'>' + 'DRAW !!' + '</h3>',
            text: 'Try Again',
            confirmButtonColor: 'transparent',
            background: 'transparent',
            showConfirmButton: false,
            timer: 3000,
          })
        }
        // if ((_.includes(currentType, 'SA')))
        // const rewardPrice =
      }

    }
    // else {
    //   // const one = ~~(Math.random() * 6) + 1
    //   // const two = ~~(Math.random() * 6) + 1
    //   // const three = ~~(Math.random() * 6) + 1
    //   // setActiveCard(() => ([one, two, three]))
    //   // setRewardCard(() => ([one, two, three]))
    //   // const tempReward = [one, two, three]
    //   Swal.fire({
    //     icon: 'error',
    //     // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
    //     // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
    //     title: 'Error',
    //     text: 'Could Not Load Result',
    //     confirmButtonColor: '#B6964E',
    //     // confirmButtonText: 'เข้าสู่ระบบ',
    //   })
    // }
  }, [resultAngalong])


// console.log(second, 'second')
// console.log(buyOne, 'buyOne')

  // const onTest = () => {
  //   const one = ~~(Math.random() * 6) + 1
  //   const two = ~~(Math.random() * 6) + 1
  //   const three = ~~(Math.random() * 6) + 1
  //
  //   setActiveCard(() => ([one, two, three]))
  // }

// console.log(activeCard, 'activeCard')

  return (

    <div className='angalong mt-20'>
      <div className='display'>
        <div className='menu'>
          {second >= 50 || second <= 10 ? <TrafficLight RedOn/> : second <= 15 || second >= 45 ?
            <TrafficLight YellowOn/> : <TrafficLight GreenOn/>}
        </div>
        <div className='random-dice'>
          <div className="go-button">
            <img
              src={goButton}
              className={`${second <= 15 || second >= 50 ? 'hidden' : ''}`}
              alt='go-button-img'
            />
          </div>
          <div className='dice_container'>
            {angalongDice('cube', 'first')}
            {angalongDice('cube2', 'second')}
            {angalongDice('cube3', 'third')}
          </div>
          {/*<div className="dice">*/}
          {/*  <img*/}
          {/*    src={activeCard[0] === 1 ? elephantDice : activeCard[0] === 2 ? angelDice : activeCard[0] === 3 ? tigerDice : activeCard[0] === 4 ? nagaDice : activeCard[0] === 5 ? horseDice : activeCard[0] === 6 ? dongDice : anyDice}*/}
          {/*    className='first-dice'*/}
          {/*    alt='first-dice'*/}
          {/*  />*/}
          {/*  <img*/}
          {/*    src={activeCard[1] === 1 ? elephantDice : activeCard[1] === 2 ? angelDice : activeCard[1] === 3 ? tigerDice : activeCard[1] === 4 ? nagaDice : activeCard[1] === 5 ? horseDice : activeCard[1] === 6 ? dongDice : anyDice}*/}
          {/*    className='second-dice'*/}
          {/*    alt='second-dice'*/}
          {/*  />*/}
          {/*  <img*/}
          {/*    src={activeCard[2] === 1 ? elephantDice : activeCard[2] === 2 ? angelDice : activeCard[2] === 3 ? tigerDice : activeCard[2] === 4 ? nagaDice : activeCard[2] === 5 ? horseDice : activeCard[2] === 6 ? dongDice : anyDice}*/}
          {/*    className='third-dice'*/}
          {/*    alt='third-dice'*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className='timer'>
          <div className='credit'>
            <span
              className='px-2'>{purchaseAmount > 0 && (second < 10 || second >= 15) ? numeral(Math.floor((walletCredit - purchaseAmount) * 100) / 100).format('0,0') : numeral(Math.floor(walletCredit * 100) / 100).format('0,0')}</span>
          </div>
          <div className='countdown'>
              <span
                className={`${second <= 15 || second >= 50 ? 'hidden' : ''}`}
                style={second >= 45 ? { color: 'red' } : null}
              >
                {50 - second}
              </span>
            {/*<img*/}
            {/*  className='home__lotto-title-refresh'*/}
            {/*  onClick={() => window.location.reload(false)}*/}
            {/*  src={require('../../assets/refresh.png')}*/}
            {/*  alt='refresh'*/}
            {/*/>*/}
          </div>
          <div className='current-chip'>
            <img
              src={handleChip('current', currentChip)}
              className='current-chip-img'
              alt='current-chip-img'
            />
          </div>
        </div>
      </div>
      <div className={`board ${second <= 15 || second >= 50 ? 'stop' : ''}`}>
        <div className='top-rec'>
          <div className={`head-chip ${activePair['12'] ? 'price' : ''}`}>
            <img
              src={elephantAngel}
              className='top-first'
              onClick={() => handleBuyAngalong(12, currentChip)}
              alt='top-first'
            />
            <img
              src={activePair['12'] ? anyChip : handleChip('pair', '12')}
              className={`top-head-chip ${buyPair['12'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['12'])  || (activePair['12'] && buyPair['12'] > 0) ? '' : 'no-chip'}`}>{activePair['12'] ? buyPair['12'] * 6 : buyPair['12']}</span>
          </div>
          <div className={`head-chip ${activePair['24'] ? 'price' : ''}`}>
            <img
              src={angelNaga}
              // src={angelNaga}
              className='top-second'
              onClick={() => handleBuyAngalong(24, currentChip)}
              alt='top-socond'
            />
            <img
              src={activePair['24'] ? anyChip : handleChip('pair', '24')}
              className={`top-head-chip ${buyPair['24'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['24'])  || (activePair['24'] && buyPair['24'] > 0) ? '' : 'no-chip'}`}>{activePair['24'] ? buyPair['24'] * 6 : buyPair['24']}</span>
          </div>
          <div className={`head-chip ${activePair['23'] ? 'price' : ''}`}>
            <img
              src={angelTiger}
              className='top-third'
              onClick={() => handleBuyAngalong(23, currentChip)}
              alt='top-third'
            />
            <img
              src={activePair['23'] ? anyChip : handleChip('pair', '23')}
              className={`top-head-chip ${buyPair['23'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['23'])  || (activePair['23'] && buyPair['23'] > 0) ? '' : 'no-chip'}`}>{activePair['23'] ? buyPair['23'] * 6 : buyPair['23']}</span>
          </div>
          <div className={`head-chip ${activePair['25'] ? 'price' : ''}`}>
            <img
              src={angelHorse}
              // src={angelElephant}
              className='top-forth'
              onClick={() => handleBuyAngalong(25, currentChip)}
              alt='top-forth'
            />
            <img
              src={activePair['25'] ? anyChip : handleChip('pair', '25')}
              className={`top-head-chip ${buyPair['25'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['25'])  || (activePair['25'] && buyPair['25'] > 0) ? '' : 'no-chip'}`}>{activePair['25'] ? buyPair['25'] * 6 : buyPair['25']}</span>
          </div>
          <div className={`head-chip ${activePair['26'] ? 'price' : ''}`}>
            <img
              src={angelDong}
              className='top-fifth'
              onClick={() => handleBuyAngalong(26, currentChip)}
              alt='top-fifth'
            />
            <img
              src={activePair['26'] ? anyChip : handleChip('pair', '26')}
              className={`top-head-chip ${buyPair['26'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['26'])  || (activePair['26'] && buyPair['26'] > 0) ? '' : 'no-chip'}`}>{activePair['26'] ? buyPair['26'] * 6 : buyPair['26']}</span>
          </div>
          <div className={`head-chip ${activePair['43'] ? 'price' : ''}`}>
            <img
              src={nagaTiger}
              className='top-sixth'
              onClick={() => handleBuyAngalong(43, currentChip)}
              alt='top-sixth'
            />
            <img
              src={activePair['43'] ? anyChip : handleChip('pair', '43')}
              className={`top-head-chip ${buyPair['43'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['43'])  || (activePair['43'] && buyPair['43'] > 0) ? '' : 'no-chip'}`}>{activePair['43'] ? buyPair['43'] * 6 : buyPair['43']}</span>
          </div>
          <div className={`head-chip ${activePair['45'] ? 'price' : ''}`}>
            <img
              src={nagaHorse}
              className='top-seventh'
              onClick={() => handleBuyAngalong(45, currentChip)}
              alt='top-seventh'
            />
            <img
              src={activePair['45'] ? anyChip : handleChip('pair', '45')}
              className={`top-head-chip ${buyPair['45'] > 0 ? '' : 'no-chip'}`}
              alt='top-head-chip'
            />
            <span
              className={`span-head-chip ${!_.includes(possibleChip, buyPair['45'])  || (activePair['45'] && buyPair['45'] > 0) ? '' : 'no-chip'}`}>{activePair['45'] ? buyPair['45'] * 6 : buyPair['45']}</span>
          </div>
        </div>
        <div className='bottom-rec'>
          <div className="left-rec">
            <div className={`side-chip ${activePair['14'] ? 'price' : ''}`}>
              <img
                src={elephantNaga}
                className='left-first main'
                onClick={() => handleBuyAngalong(14, currentChip)}
                alt='left-first'
              />
              <img
                src={activePair['14'] ? anyChip : handleChip('pair', '14')}
                className={`top-side-chip ${buyPair['14'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['14'])  || (activePair['14'] && buyPair['14'] > 0) ? '' : 'no-chip'}`}>{activePair['14'] ? buyPair['14'] * 6 : buyPair['14']}</span>
            </div>
            <div className={`side-chip ${activePair['13'] ? 'price' : ''}`}>
              <img
                src={elephantTiger}
                className='left-second'
                onClick={() => handleBuyAngalong(13, currentChip)}
                alt='left-second'
              />
              <img
                src={activePair['13'] ? anyChip : handleChip('pair', '13')}
                className={`top-side-chip ${buyPair['13'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['13'])  || (activePair['13'] && buyPair['13'] > 0) ? '' : 'no-chip'}`}>{activePair['13'] ? buyPair['13'] * 6 : buyPair['13']}</span>
            </div>
            <div className={`side-chip ${activePair['15'] ? 'price' : ''}`}>
              <img
                src={elephantHorse}
                className='left-third'
                onClick={() => handleBuyAngalong(15, currentChip)}
                alt='left-third'
              />
              <img
                src={activePair['15'] ? anyChip : handleChip('pair', '15')}
                className={`top-side-chip ${buyPair['15'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['15'])  || (activePair['15'] && buyPair['15'] > 0) ? '' : 'no-chip'}`}>{activePair['15'] ? buyPair['15'] * 6 : buyPair['15']}</span>
            </div>
            <div className={`side-chip ${activePair['16'] ? 'price' : ''}`}>
              <img
                src={elephantDong}
                className='left-forth'
                onClick={() => handleBuyAngalong(16, currentChip)}
                alt='left-forth'
              />
              <img
                src={activePair['16'] ? anyChip : handleChip('pair', '16')}
                className={`top-side-chip ${buyPair['16'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['16'])  || (activePair['16'] && buyPair['16'] > 0) ? '' : 'no-chip'}`}>{activePair['16'] ? buyPair['16'] * 6 : buyPair['16']}</span>
            </div>
          </div>
          <div className="main-board">
            <div className="first-row">
              <div className={`main-top-chip ${activeOne['1'] > 0 ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 1) ? elephantCardActive : elephantCard}
                  className='elephant main'
                  onClick={() => handleBuyAngalong(1, currentChip)}
                  alt='elephant'
                />
                <img
                  src={activeOne['1'] > 0 ? anyChip : handleChip('one', '1')}
                  className={`top-chip ${buyOne['1'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['1']) || activeOne['1'] > 0 ? '' : 'no-chip'}`}>{buyOne['1'] * activeOne['1'] > 0 ? buyOne['1'] * activeOne['1'] : buyOne['1'] === 0 ? '' : buyOne['1']}</span>
              </div>
              <div className={`main-top-chip ${activeOne['2'] > 0 ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 2) ? angelCardActive : angelCard}
                  className='angel main'
                  onClick={() => handleBuyAngalong(2, currentChip)}
                  alt='angel'
                />
                <img
                  src={activeOne['2'] > 0 ? anyChip : handleChip('one', '2')}
                  className={`top-chip ${buyOne['2'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['2']) || activeOne['2'] > 0 ? '' : 'no-chip'}`}>{buyOne['2'] * activeOne['2'] > 0 ? buyOne['2'] * activeOne['2'] : buyOne['2'] === 0 ? '' : buyOne['2']}</span>
              </div>
              <div className={`main-top-chip ${activeOne['3'] > 0 ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 3) ? tigerCardActive : tigerCard}
                  className='tiger main'
                  onClick={() => handleBuyAngalong(3, currentChip)}
                  alt='tiger'
                />
                <img
                  src={activeOne['3'] > 0 ? anyChip : handleChip('one', '3')}
                  className={`top-chip ${buyOne['3'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['3']) || activeOne['3'] > 0 ? '' : 'no-chip'}`}>{buyOne['3'] * activeOne['3'] > 0 ? buyOne['3'] * activeOne['3'] : buyOne['3'] === 0 ? '' : buyOne['3']}</span>
              </div>
            </div>
            <div className="second-row">
              <div className={`main-top-chip ${activeOne['4'] ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 4) ? nagaCardActive : nagaCard}
                  className='naga main'
                  onClick={() => handleBuyAngalong(4, currentChip)}
                  alt='naga'
                />
                <img
                  src={activeOne['4'] > 0 ? anyChip : handleChip('one', '4')}
                  className={`top-chip ${buyOne['4'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['4']) || activeOne['4'] > 0 ? '' : 'no-chip'}`}>{buyOne['4'] * activeOne['4'] > 0 ? buyOne['4'] * activeOne['4'] : buyOne['4'] === 0 ? '' : buyOne['4']}</span>
              </div>
              <div className={`main-top-chip ${activeOne['5'] ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 5) ? horseCardActive : horseCard}
                  className='horse main'
                  onClick={() => handleBuyAngalong(5, currentChip)}
                  alt='horse'
                />
                <img
                  src={activeOne['5'] > 0 ? anyChip : handleChip('one', '5')}
                  className={`top-chip ${buyOne['5'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['5']) || activeOne['5'] > 0 ? '' : 'no-chip'}`}>{buyOne['5'] * activeOne['5'] > 0 ? buyOne['5'] * activeOne['5'] : buyOne['5'] === 0 ? '' : buyOne['5']}</span>
              </div>
              <div className={`main-top-chip ${activeOne['6'] > 0 ? 'price' : ''}`}>
                <img
                  src={_.includes(rewardCard, 6) ? dongCardActive : dongCard}
                  className='dong main'
                  onClick={() => handleBuyAngalong(6, currentChip)}
                  alt='dong'
                />
                <img
                  src={activeOne['6'] > 0 ? anyChip : handleChip('one', '6')}
                  className={`top-chip ${buyOne['6'] > 0 ? '' : 'no-chip'}`}
                  alt='top-chip'
                />
                <span
                  className={`span-chip ${!_.includes(possibleChip, buyOne['6']) || activeOne['6'] > 0 ? '' : 'no-chip'}`}>{buyOne['6'] * activeOne['6'] > 0 ? buyOne['6'] * activeOne['6'] : buyOne['6'] === 0 ? '' : buyOne['6']}</span>
              </div>
            </div>
          </div>
          <div className="right-rec">
            <div className={`side-chip ${activePair['46'] ? 'price' : ''}`}>
              <img
                src={nagaDong}
                className='right-first'
                onClick={() => handleBuyAngalong(46, currentChip)}
                alt='right-first'
              />
              <img
                src={activePair['46'] ? anyChip : handleChip('pair', '46')}
                className={`top-side-chip ${buyPair['46'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['46'])  || (activePair['46'] && buyPair['46'] > 0) ? '' : 'no-chip'}`}>{activePair['46'] ? buyPair['46'] * 6 : buyPair['46']}</span>
            </div>
            <div className={`side-chip ${activePair['35'] ? 'price' : ''}`}>
              <img
                src={tigerHorse}
                // src={tigerHorse}
                className='right-second'
                onClick={() => handleBuyAngalong(35, currentChip)}
                alt='right-second'
              />
              <img
                src={activePair['35'] ? anyChip : handleChip('pair', '35')}
                className={`top-side-chip ${buyPair['35'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['35'])  || (activePair['35'] && buyPair['35'] > 0) ? '' : 'no-chip'}`}>{activePair['35'] ? buyPair['35'] * 6 : buyPair['35']}</span>
            </div>
            <div className={`side-chip ${activePair['36'] ? 'price' : ''}`}>
              <img
                src={tigerDong}
                className='right-third'
                onClick={() => handleBuyAngalong(36, currentChip)}
                alt='right-third'
              />
              <img
                src={activePair['36'] ? anyChip : handleChip('pair', '36')}
                className={`top-side-chip ${buyPair['36'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['36'])  || (activePair['36'] && buyPair['36'] > 0) ? '' : 'no-chip'}`}>{activePair['36'] ? buyPair['36'] * 6 : buyPair['36']}</span>
            </div>
            <div className={`side-chip ${activePair['56'] ? 'price' : ''}`}>
              <img
                src={horseDong}
                // src={horseElephant}
                className='right-forth'
                onClick={() => handleBuyAngalong(56, currentChip)}
                alt='right-forth'
              />
              <img
                src={activePair['56'] ? anyChip : handleChip('pair', '56')}
                className={`top-side-chip ${buyPair['56'] > 0 ? '' : 'no-chip'}`}
                alt='top-side-chip'
              />
              <span
                className={`span-side-chip ${!_.includes(possibleChip, buyPair['56'])  || (activePair['56'] && buyPair['56'] > 0) ? '' : 'no-chip'}`}>{activePair['56'] ? buyPair['56'] * 6 : buyPair['56']}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='chip-board'>
        <img
          src={fiveChip}
          className={`five-chip ${currentChip === 5 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(5)
          }}
          alt='five-chip'
        />
        <img
          src={tenChip}
          className={`ten-chip ${currentChip === 10 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(10)
          }}
          alt='ten-chip'
        />
        <img
          src={twentyChip}
          className={`twenty-chip ${currentChip === 20 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(20)
          }}
          alt='twenty-chip'
        />
        <img
          src={fiftyChip}
          className={`fifty-chip ${currentChip === 50 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(50)
          }}
          alt='fifty-chip'
        />
        <img
          src={hundredChip}
          className={`hundred-chip ${currentChip === 100 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(100)
          }}
          alt='hundred-chip'
        />
        <img
          src={fiveHundredChip}
          className={`five-hundred-chip ${currentChip === 500 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(500)
          }}
          alt='five-hundred-chip'
        />
        {/*<img*/}
        {/*  src={thousandChip}*/}
        {/*  className={`thousand-chip ${currentChip === 'thousand' ? 'active' : ''}`}*/}
        {/*  onClick={() => {*/}
        {/*    setCurrentChip('thousand')*/}
        {/*  }}*/}
        {/*  alt='thousand-chip'*/}
        {/*/>*/}
      </div>
      <div className='action-button'>
        <button className={!isBuy || second <= 15 || second >= 50 ? 'inactive' : ''}
                onClick={() => resetButton()}>Reset
        </button>
        <button className={buyHistory.length < 3 || second <= 15 || second >= 50 ? 'inactive' : ''}
                onClick={() => undoButton()}>Undo
        </button>
        {/*<button onClick={onTest}>Test</button>*/}
      </div>
      <div className='action-button action-two'>
        <button className={second <= 15 || second >= 50 ? 'inactive' : ''}
                onClick={() => luckyButton()}>Lucky?
        </button>
        <button className={!isBuy || second <= 15 || second >= 50 ? 'inactive' : ''}
                onClick={() => multiplyButton()}>X2
        </button>
        <button
          className={(_.isEmpty(lastBuyOne) && _.isEmpty(lastBuyPair)) || second <= 15 || second >= 50 ? 'inactive' : ''}
          onClick={() => followButton()}>Follow
        </button>
        <button
          className={isAuto ? 'onAuto' : !(_.isEmpty(lastBuyOne) && _.isEmpty(lastBuyPair)) || isBuy ? '' : 'inactive'}
          onClick={() => autoPlayButton()}>Auto
        </button>
        {/*<button onClick={onTest}>Test</button>*/}
      </div>
      <div className='history'>
        {/*<button onClick={onTest}>Test</button>*/}
      </div>
    </div>
  )
}

export default Angalong
