import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// import Swal from 'sweetalert2'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import { getAuth } from '../../../redux/selectors/auth'
import YeekeeNumPad from './YeekeeNumPad'
import { listNewTests } from '../../../graphql/queries'
import Timer from './Timer'

export default function YeekeeModal(props) {
  const auth = useSelector(getAuth)

  const showModal = props.showModal

  const setShowModal = props.setShowModal
  const lottoData = props.lottoData

  const [yeekeeNumberList, setYeekeeNumberList] = useState([])
  const [isYeekeeInput, setIsYeekeeInput] = useState(false)
  const [sumYeekeeNumber, setSumYeekeeNumber] = useState(0)
  const [yeekeeSixteen, setYeekeeSixteen] = useState(0)
  const [yeekeeReward, setYeekeeReward] = useState(0)

  // console.log(isYeekeeInput, 'isYeekeeInput is Rendered')
  // console.log(sumYeekeeNumber, 'sumYeekeeNumber is Rendered')

  const newDrawDateStr = props.lottoData.drawDateStr.replace(/\//g, '-')
  const drawDateTime = moment(newDrawDateStr + ' ' + props.lottoData.drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
  const timeToCheck = moment(drawDateTime).subtract(props.lottoData.closeMinutesBeforeDraw, 'minutes')

  function amount(item) {
    return +item.number[0]
  }
  function sum(prev, next) {
    return prev + next
  }

  const day = moment().isoWeekday()

  const lottoRound =
    lottoData.lottoGroup === 'หวยยี่กี'
      ? day + 'Y' + lottoData.lottoName.split(' ')[1]
      : lottoData.lottoGroup === 'หวยป้าตุ๊ก'
      ? day + 'P' + lottoData.lottoName.split(' ')[1]
      : lottoData.lottoName.slice(-2)

  const getAllGraph = async (event) => {
    try {
      let allData = []
      let more = true
      let paginationToken = ''

      while (more) {
        const rawData = await API.graphql(
          graphqlOperation(listNewTests, { filter: { round: { contains: event } }, nextToken: paginationToken })
        )
        const getData = rawData.data.listNewTests
        allData = allData.concat(getData.items)
        if (getData.nextToken) {
          paginationToken = getData.nextToken
        } else {
          more = false
        }
      }
      return allData
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (auth.username) {
      setTimeout(() => {
        const graphData = getAllGraph(lottoRound).then((res) => {
          if (res.length > 0) {
            const sortData = res.sort((a, b) => (b.time > a.time ? 1 : a.time > b.time ? -1 : 0))
            const sumNumber = res.map(amount).reduce(sum)
            setSumYeekeeNumber(sumNumber)
            if (res.length > 15) {
              setYeekeeSixteen(sortData[15].number[0])
              setYeekeeReward(sumNumber - sortData[15].number[0])
            }
            setYeekeeNumberList(sortData)
          }
        })
        console.log(graphData, 'graphData')
      }, 500)
    }
    return () => {}
  }, [isYeekeeInput])

  const yeekeeTable = []
  if (yeekeeNumberList.length > 0) {
    // console.log(yeekeeNumberList, 'asdfasdfasdfasdf YeekeeNumberList')
    yeekeeTable.push(
      <table className='table-auto' style={{ borderSpacing: '0 6px' }}>
        <thead>
          <tr>
            <th className='px-4 py-2'></th>
            <th className='px-4 py-2'></th>
            <th className='px-4 py-2'></th>
            <th className='px-4 py-2'></th>
          </tr>
        </thead>
        <tbody>
          {yeekeeNumberList.map((item, index) =>
            index === 0 || index === 15 ? (
              <tr
                className={`buy-lotto__modal-box-cart-counter`}
                key={index}
                style={{
                  color: `${
                    auth.attributes.email.split('@')[0] === item.email[0] ||
                    auth.attributes.phone_number === item.email[0]
                      ? 'green'
                      : 'red'
                  }`,
                  fontWeight: 'bold',
                }}
              >
                <td
                  className='px-4 py-2'
                  style={{
                    backgroundColor: 'lightgreen',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                    borderLeft: '1px solid #8F8F8F',
                  }}
                >
                  {index + 1}.
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: 'lightgreen',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                  }}
                >
                  <div>
                    {item.email[0].substring(0, 2) + '*'.repeat(item.email[0].length - 4) + item.email[0].slice(-2)}
                  </div>
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: 'lightgreen',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                  }}
                >
                  <div>{item.number}</div>
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: 'lightgreen',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                    borderRight: '1px solid #8F8F8F',
                  }}
                >
                  <div>{item.time}</div>
                </td>
                {/*<td className='px-4 py-2' style={{ backgroundColor: 'lightgreen', color: 'red', fontWeight: 'bold' }}>*/}
              </tr>
            ) : (
              <tr
                className={`buy-lotto__modal-box-cart-counter`}
                // key={`${key}-${item.email}-${index}`}
                key={index}
                style={{
                  color: `${
                    auth.attributes.email.split('@')[0] === item.email[0] ||
                    auth.attributes.phone_number === item.email[0]
                      ? 'green'
                      : ''
                  }`,
                }}
              >
                <td
                  className='px-4 py-2'
                  style={{
                    backgroundColor: '#ECECEC',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                    borderLeft: '1px solid #8F8F8F',
                  }}
                >
                  {index + 1}.
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: '#ECECEC',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                  }}
                >
                  <div>
                    {item.email[0].substring(0, 2) + '*'.repeat(item.email[0].length - 4) + item.email[0].slice(-2)}
                  </div>
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: '#ECECEC',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                  }}
                >
                  <div>{item.number}</div>
                </td>
                <td
                  className='px-4 py-2 my-2'
                  style={{
                    backgroundColor: '#ECECEC',
                    borderTop: '1px solid #8F8F8F',
                    borderBottom: '1px solid #8F8F8F',
                    borderRight: '1px solid #8F8F8F',
                  }}
                >
                  <div>{item.time}</div>
                </td>
                {/*<td className='px-4 py-2' style={{ backgroundColor: 'lightgreen', color: 'red', fontWeight: 'bold' }}>*/}
              </tr>
            )
          )}
        </tbody>
      </table>
    )
  }

  return showModal.isShow && auth.username ? (
    <section className={`buy-lotto__modal active`}>
      <div className={`buy-lotto__modal-box active`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          <div className='buy-lotto__lotto-number text-2xl mr-3 text-white text-bold'>
            <div>{lottoData.lottoName}</div>
          </div>
          <div className='text-blue-900'>
            <Timer
              drawDateStr={lottoData.drawDateStr}
              drawTimeStr={lottoData.drawTimeStr}
              closeMinutesBeforeDraw={lottoData.closeMinutesBeforeDraw}
            />
          </div>
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        {lottoData.status === 'rewarded' ? (
          <div>
            <div
              className='yeekee-box h-20 text-center text-xl'
              style={{
                border: '2px solid #3b5998',
                borderRadius: '10px',
              }}
            >
              <div style={{ color: '#676767' }}>เลขที่ออก</div>
              <div className='text-2xl sm:text-4xl'>
                <span style={{ fontFamily: 'DESG7 Classic', color: 'green' }}>
                  {yeekeeReward.toString().slice(0, -5)}
                </span>
                <span style={{ fontFamily: 'DESG7 Classic', color: '#3b5998' }}>
                  {yeekeeReward.toString().slice(-5).slice(0, 2)}
                </span>
                <span style={{ fontFamily: 'DESG7 Classic', color: 'red' }}>{yeekeeReward.toString().slice(-3)}</span>
              </div>
            </div>
            <div className='flex-parent'>
              <div
                className='yeekee-result w-1/2 h-20 text-center text-xl'
                style={{
                  marginLeft: '10px',
                  marginRight: '2px',
                  border: '2px solid red',
                  borderRadius: '10px',
                }}
              >
                <div style={{ color: '#676767' }}>สามตัวบน</div>
                <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: 'red' }}>
                  {yeekeeReward > 0 ? yeekeeReward.toString().slice(-3) : '000'}
                </div>
              </div>
              <div
                className='yeekee-result w-1/2 h-20 text-center text-xl'
                style={{
                  marginRight: '10px',
                  marginLeft: '2px',
                  border: '2px solid red',
                  borderRadius: '10px',
                }}
              >
                <div style={{ color: '#676767' }}>สองตัวล่าง</div>
                <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: '#3b5998' }}>
                  {yeekeeReward > 0 ? yeekeeReward.toString().slice(-5).slice(0, 2) : '00'}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className='yeekee-box h-20 text-center text-xl'
          style={{
            border: '2px solid #31a700',
            borderRadius: '10px',
          }}
        >
          <div style={{ color: '#676767' }}>ผลรวมยิงเลข</div>
          <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: '#00AC2F' }}>
            {sumYeekeeNumber}
          </div>
        </div>
        {yeekeeNumberList.length > 15 ? (
          <div
            className='yeekee-box h-20 text-center text-xl'
            style={{
              border: '2px solid #31a700',
              borderRadius: '10px',
              marginTop: '5px',
            }}
          >
            <div style={{ color: '#676767' }}>ลำดับที่ 16</div>
            <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: '#31a700' }}>
              {yeekeeSixteen}
            </div>
          </div>
        ) : null}
        {lottoData.status === 'open' ? (
          <YeekeeNumPad
            isYeekeeInput={isYeekeeInput}
            setIsYeekeeInput={setIsYeekeeInput}
            lottoRound={lottoRound}
            timeToCheck={timeToCheck}
          />
        ) : null}
        <div className='buy-lotto__modal-box-yeekee'>{yeekeeTable}</div>
      </div>
    </section>
  ) : null
}

YeekeeModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  lottoData: PropTypes.object,
}
