import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import Countdown, { zeroPad } from 'react-countdown'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import { getCognitoProvider } from '../../config/cognito'
import { composePhoneNumber } from '../../utils/phoneNumber'
import Input from '../Form/Input'
import { FacebookIcon } from '../Icon/Facebook'
import { LineIcon } from '../Icon/Line'
import './SignUp.scss'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'

function SignUp(props) {
  const dispatch = useDispatch()

  const showModal = props.showModal
  const setShowModal = props.setShowModal
  const confirmPassword = props.confirmPassword
  const setConfirmPassword = props.setConfirmPassword
  const handleLogin = props.handleLogin
  const setNewUser = props.setNewUser
  const newUser = props.newUser

  const auth = useSelector(getAuth)
  const [OTPValue, setOTPValue] = useState('')
  const [OTPNumber, setOTPNumber] = useState('')
  const [timeLeft, setTimeLeft] = useState(new Date())
  const [isRequestOTP, setIsRequestOTP] = useState(false)
  const history = useHistory()
  const cognitoProvider = { provider: getCognitoProvider() }

  // const modalLoginSuccess = () => {
  //   const storage = getStorage()
  //
  //   const swFire = async (options) => {
  //     await Swal.fire({
  //       ...options,
  //       confirmButtonColor: '#7dc351',
  //       confirmButtonText: 'แอดไลน์',
  //       showCloseButton: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.open('http://line.me/ti/p/@paatook')
  //       }
  //     })
  //   }
  //
  //   swFire({
  //     html: 'ยินดีต้อนรับเข้าสู่ป้าตุ๊ก.com ค่ะ </br> แอดไลน์ป้าตุ๊กไว้ได้เลยค่า',
  //     imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/valentine-iphone.jpg`,
  //   })
  // }

  const validateAll = () => {
    if (!newUser.username || !newUser.password || !newUser.userConfirmPassword) {
      return false
    }

    if (newUser.password !== newUser.userConfirmPassword) {
      return false
    }

    return true
  }

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    // if (inputName === 'username') {
    //   const newValue = value.replace(/[^0-9]/g, '')
    //   setNewUser({ ...newUser, [inputName]: newValue })
    //   return
    // }

    setNewUser({ ...newUser, [inputName]: value })
  }

  const _handlePhoneNumber = () => {
    // if (newUser.username.length !== 10) {
    //   if (newUser.password.length < 8) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้องค่ะ และรหัสผ่านของท่านมีความยาวไม่ถึง 8 ตัวอักษรค่ะ',
    //     })
    //     return
    //   }
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้องค่ะ',
    //   })
    //   return
    // }
    if (newUser.password.length < 8) {
      Swal.fire({
        icon: 'error',
        title: 'รหัสผ่านของท่านมีความยาวไม่ถึง 8 ตัวอักษรครับ',
      })
      return
    }
    setConfirmPassword(true)
  }

  const handleSignUp = async (e) => {
    const isValidForm = validateAll()
    if (!isValidForm) {
      return Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'รหัสผ่านและยืนยันรหัสผ่านของท่านไม่ตรงกันครับ',
      })
    } else {
      if (showModal.currentModal === 'confirm-forgot') {
        const newUsername = composePhoneNumber('+66', newUser.username)
        Auth.forgotPasswordSubmit(newUsername, OTPValue, newUser.password)
          .then((data) => {
            console.log(data, 'forgotPassword')
            setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
            Auth.signIn(newUsername, newUser.password).then((user) => {
              if (user.attributes['custom:otp']) {
                const customData = JSON.parse(user.attributes['custom:otp'])
                if (customData.isVerify === 'YES') {
                  // modalLoginSuccess()
                  Auth.currentAuthenticatedUser().then((user) => {
                    dispatch(setAuth({ user }))
                  })
                } else {
                  const payload = {
                    'custom:otp': JSON.stringify({ isVerify: 'YES' }),
                  }
                  Auth.updateUserAttributes(user, payload).then((res) => {
                    // console.log(res)
                    if (res === 'SUCCESS') {
                      setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
                      // modalLoginSuccess()
                      Auth.currentAuthenticatedUser().then((user) => {
                        dispatch(setAuth({ user }))
                      })
                    }
                  })
                }
              } else {
                // modalLoginSuccess()
                Auth.currentAuthenticatedUser().then((user) => {
                  dispatch(setAuth({ user }))
                })
              }
            })
            history.replace('/callback')
          })
          .catch((err) => {
            console.log(err)
            return Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด',
              text: 'ท่านกรอกรหัส OTP ไม่ถูกต้องครับ',
            })
          })
      } else {
        try {
          const newUsername = composePhoneNumber('+66', newUser.username)
          // const newUsername = newUser.username.split('@')[0]
          await Auth.signUp({
            username: newUsername,
            password: newUser.password,
            attributes: {
              name: newUsername,
              phone_number: newUsername,
            },
          }).then((res) => {
            console.log(res, 'res user signUp')
          })

          setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
          handleLogin(e)
        } catch (e) {
          console.error(e)
          if (e.message === 'User already exists') {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด',
              text: 'บัญชีนี้ซ้ำกับบัญชีที่มีอยู่ในระบบครับ',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด',
              text: e.message || '',
            })
          }
        }
      }
    }
  }

  const _onChangeCode = (e) => {
    const value = e.target.value
    const newValue = value.replace(/[^0-9]/g, '')
    if (newValue.length > 6) {
      return
    }
    setOTPValue(newValue)
  }

  // function diffMinutes(dt2, dt1) {
  //   const diff = dt2.getTime() - dt1.getTime()
  //   // console.log(dt2, dt1, 'dt')
  //   return Math.abs(Math.round(diff))
  // }

  const getUser = async () => {
    return await Auth.currentAuthenticatedUser()
  }

  const updateCountdown = async (user) => {
    try {
      let inputNum = []
      const number = '0123456789'
      for (let i = 0; i < 6; i++) {
        const numberInput = number.charAt(Math.floor(Math.random() * 10))
        inputNum.push(numberInput)
      }
      const outputNum = `${inputNum[0]}${inputNum[1]}${inputNum[2]}${inputNum[3]}${inputNum[4]}${inputNum[5]}`
      const payload = {
        'custom:otp': JSON.stringify({ isVerify: 'NO', countdown: new Date(), code: outputNum }),
      }
      const username = user.username.slice(-11)
      // console.log(payload, 'payload')
      return await Auth.updateUserAttributes(user, payload).then((res) => {
        // console.log(res, 'res update countdown')
        if (res === 'SUCCESS') {
          return sendOTP(username, outputNum).then((resOTP) => {
            if (resOTP === 'SUCCESS') {
              return res
            } else {
              Swal.fire({
                icon: 'error',
                title:
                  'เกิดข้อผิดพลาด อาจเกิดจากเบอร์โทรศัพท์ไม่ถูกต้อง, ปิดกั้น SMS หรือระบบ OTP อาจกำลังปิดปรับปรุงครับ',
              })
              return res
            }
          })
        }
      })
    } catch (error) {
      console.error('send request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
        text: error.message || '',
      })
    }
  }

  const sendOTP = async (username, outputNum) => {
    try {
      return await API.post('user', `/test-otp`, { body: { mobile: username, code: outputNum } }).then((res) => {
        // console.log(res, 'response from USER API')
        if (res.code === 0) {
          const response = JSON.parse(res.detail)
          const XMLData = response.data
          const isSuccess = XMLData.includes('OK')
          // console.log(isSuccess, 'isSuccess')
          // console.log(response, 'response from API')
          return isSuccess ? 'SUCCESS' : 'FAILED'
        }
      })
    } catch (error) {
      console.error('send request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด อาจเกิดจากเบอร์โทรศัพท์ไม่ถูกต้อง, ปิดกั้น SMS หรือระบบ OTP อาจกำลังปิดปรับปรุงครับ',
        text: error.message || '',
      })
    }
  }

  const verifyUser = async (user) => {
    const response = await Auth.confirmSignUp(user, OTPValue);
    console.log(response, 'response')
    if (OTPNumber === OTPValue) {
      const payload = {
        'custom:otp': JSON.stringify({ isVerify: 'YES' }),
      }
      // console.log(payload, 'payload')
      return await Auth.updateUserAttributes(user, payload).then((res) => {
        if (res === 'SUCCESS') {
          return res
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ท่านกรอกรหัส OTP ไม่ถูกต้องครับ',
      }).then((res) => {
        // console.log(showModal, 'showmodal')
        if (res) {
          setShowModal({ isShow: true, currentModal: 'verify' })
        }
      })
    }
  }

  // const Completionist = () => (
  //   <span className={`${OTPValue.length > 0 ? 'hidden' : ''}`}>กรุณากดปุ่ม {'ขอ OTP'} เพื่อรับรหัส OTP ของท่าน</span>
  // )
  // const renderer = ({ minutes, seconds, completed }) => {
  //   if (completed || isNaN(minutes) || !isRequestOTP) {
  //     // Render a completed state
  //     return <Completionist />
  //   } else {
  //     // Render a countdown
  //     return (
  //       <span className={`${OTPValue.length > 0 ? 'hidden' : ''}`}>
  //         สามารถลองใหม่อีกครั้งภายใน {zeroPad(minutes)}:{zeroPad(seconds)} นาที
  //       </span>
  //     )
  //   }
  // }

  const requestOTP = async () => {
    try {
      if (auth.username) {
        const user = await Auth.currentAuthenticatedUser()
        // console.log(user, 'user')
        updateCountdown(user).then((res) => {
          if (res === 'SUCCESS') {
            setIsRequestOTP(true)
          }
        })
      } else {
        const newUsername = composePhoneNumber('+66', newUser.username)
        Auth.forgotPassword(newUsername)
          .then((data) => {
            console.log(data)
            setShowModal({ isShow: true, currentModal: 'confirm-forgot' })
          })
          .catch((err) =>
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด อาจเกิดจากเบอร์โทรศัพท์ของท่านไม่ถูกต้อง หรือมีการส่งข้อความถี่เกินไปครับ',
              text: err.message || '',
            })
          )
      }
    } catch (error) {
      console.error('error request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  const handleOTP = async () => {
    try {
      // const user = await Auth.currentAuthenticatedUser()
      // console.log(user, 'verify OTP')
      // console.log(user, 'user')
      verifyUser(newUser.username).then((res) => {
        // console.log(res, 'verify User')
        if (res === 'SUCCESS') {
          setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
          // modalLoginSuccess()
          Auth.currentAuthenticatedUser().then((user) => {
            dispatch(setAuth({ user }))
          })
          history.replace('/callback')
        }
      })
    } catch (error) {
      console.error('error request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  // useEffect(() => {
  //   if (auth) {
  //     if (!_.isUndefined(auth.attributes['custom:otp'])) {
  //       const customData = JSON.parse(auth.attributes['custom:otp'])
  //       // console.log(auth, 'auth')
  //       if (customData.isVerify === 'NO') {
  //         getUser().then((res) => {
  //           // console.log(res, 'response')
  //           const customData = JSON.parse(res.attributes['custom:otp'])
  //           const secondsLeft = 120500 - diffMinutes(new Date(), new Date(customData.countdown))
  //           if (secondsLeft > 0) {
  //             // console.log(secondsLeft, 'secondsLeft')
  //             // console.log(res.attributes['custom:otp'], 'otp in useEffect')
  //             // if (secondsLeft < 120001) {
  //             setTimeLeft(secondsLeft)
  //             setOTPNumber(customData.code)
  //             // }
  //             setIsRequestOTP(true)
  //             setTimeout(() => {
  //               setIsRequestOTP(false)
  //             }, secondsLeft)
  //           }
  //         })
  //       }
  //     }
  //   }
  // }, [isRequestOTP])

  return (
    <div
      className={`${
        showModal.isShow &&
        (showModal.currentModal === 'register' ||
          showModal.currentModal === 'verify' ||
          showModal.currentModal === 'forget' ||
          showModal.currentModal === 'confirm-forgot')
          ? ''
          : 'hidden'
      }`}
    >
      <div className='fixed z-10 inset-0 overflow-y-auto'>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          {/*<div className='fixed inset-0 transition-opacity'>*/}
          {/*  <div className='absolute inset-0 bg-gray-800 opacity-75'></div>*/}
          {/*</div>*/}

          {/*<span className='hidden sm:inline-block sm:align-middle sm:h-screen'>&#8203;</span>*/}

          <div
            className='signup__modal inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transition-all my-40 sm:align-middle sm:max-w-xl sm:w-full'
            role='dialog'
          >
            <div className='signup__modal-header px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <h3 className='text-xl text-center text-white' id='modal-headline'>
                {showModal.currentModal === 'register' ? 'สมัครสมาชิก' : 'กรอกรหัส OTP ยืนยัน'}
              </h3>
            </div>

            <hr className='divider' />

            <div className='px-4 py-3'>
              {showModal.currentModal === 'verify' || showModal.currentModal === 'forget' ? (
                <div className='form__setWrapper mt-4 mb-4'>
                  <div className='form__item'>
                    <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                      เบอร์โทร
                    </label>
                    <Input
                      containerClassName='input'
                      type='text'
                      id='current-username'
                      value={auth.username ? auth.username : newUser.username}
                      disable
                    />
                  </div>
                  <div className={`form__item  ${showModal.currentModal === 'forget' ? 'hidden' : ''}`}>
                    <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                      รหัสยืนยัน
                    </label>
                    <Input
                      containerClassName='input'
                      onChange={(e) => _onChangeCode(e)}
                      type='number'
                      id='otp'
                      value={OTPValue}
                      min='1'
                      step='1'
                    />
                  </div>
                  {/*<Countdown date={Date.now() + timeLeft} renderer={renderer}>*/}
                  {/*  <Completionist />*/}
                  {/*</Countdown>*/}
                </div>
              ) : !confirmPassword ? (
                <div className='form__setWrapper mt-4 mb-4'>
                  {showModal.currentModal === 'confirm-forgot' ? (
                    <div className='form__item'>
                      <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                        รหัสยืนยัน
                      </label>
                      <Input
                        containerClassName='input'
                        onChange={(e) => _onChangeCode(e)}
                        type='number'
                        id='otp'
                        value={OTPValue}
                        min='1'
                        step='1'
                      />
                    </div>
                  ) : (
                    <div className='form__item'>
                      <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                        ชื่อผู้ใช้
                      </label>
                      <Input
                        containerClassName='input'
                        placeholder='เช่น 0891234567'
                        onChange={(e) => _onChangeForm(e, 'username')}
                        type='text'
                        id='current-username'
                        value={newUser.username}
                      />
                    </div>
                  )}

                  <div className='form__item'>
                    <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                      {showModal.currentModal === 'confirm-forgot' ? 'รหัสผ่านใหม่ของท่าน' : 'รหัสผ่าน'}
                    </label>
                    <Input
                      containerClassName='input'
                      onChange={(e) => _onChangeForm(e, 'password')}
                      placeholder='รหัสผ่านความยาวอย่างน้อย 8 ตัวอักษร'
                      type='password'
                      id='current-password'
                      autocomplete='current-password'
                      value={newUser.password}
                    />
                  </div>
                </div>
              ) : (
                <div className='form__setWrapper mt-4 mb-4'>
                  <div className='form__item'>
                    <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                      ยืนยันรหัสผ่าน
                    </label>
                    <Input
                      containerClassName='input'
                      onChange={(e) => _onChangeForm(e, 'userConfirmPassword')}
                      placeholder='กรุณากรอกรหัสผ่านของท่านเพื่อยืนยัน'
                      type='password'
                      id='current-userConfirmPassword'
                      value={newUser.userConfirmPassword}
                    />
                  </div>
                </div>
              )}

              {!confirmPassword ? (
                showModal.currentModal === 'register' ? (
                  <div className='mt-4 mb-4 otp-message'>
                    * กรุณากรอกเบอร์มือถือจริงของท่าน ระบบจะมีการยืนยัน OTP ครับ *
                  </div>
                ) : (
                  <div className='mt-4 mb-4 otp-message'>รหัส OTP จะถูกส่งไปให้ท่านทาง SMS เมื่อกดขอรับ OTP ครับ</div>
                )
              ) : null}

              {showModal.currentModal === 'register' ? (
                <div className='mt-4 mb-4 grid grid-cols-1 gap-4'>
                  {/* <button
                    className='border border-white border-solid px-4 py-2 rounded-full'
                    onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
                  >
                    <FacebookIcon />
                    <span className='align-middle' style={{ color: '#3b5998' }}>
                      เข้าเลยด้วย Facebook ไม่ต้องสมัคร
                    </span>
                  </button> */}
                  <button
                    className='border border-white border-solid px-4 py-2 rounded-full'
                    onClick={() => Auth.federatedSignIn(cognitoProvider)}
                  >
                    <LineIcon />
                    <span className='align-middle' style={{ color: '#00AD00' }}>
                      เข้าได้เลยด้วย LINE ไม่ต้องสมัคร
                    </span>
                  </button>
                </div>
              ) : showModal.currentModal === 'confirm-forgot' && confirmPassword ? (
                <div className='mt-4 mb-4 otp-message'>จดรหัสผ่านของท่านไว้ในที่ปลอดภัย แม้มือถือหายก็ไม่หวั่น</div>
              ) : null}

              <div className='mt-4 mb-4'>
                {showModal.currentModal === 'verify' || showModal.currentModal === 'forget' ? (
                  <div className={`grid grid-cols-2  gap-4 text-xl`}>
                    <button
                      className={`btn-back w-fullinline-flex justify-center px-4 py-2 rounded-full ${
                        showModal.currentModal === 'forget' ? '' : 'hidden'
                      }`}
                      onClick={() => setShowModal((prevState) => ({ ...prevState, currentModal: 'login' }))}
                    >
                      <span>กลับ</span>
                    </button>
                    <button
                      className={`${
                        isRequestOTP || OTPValue.length > 0 ? 'disabled' : ''
                      } btn-next w-full inline-flex justify-center px-4 py-2 rounded-full border-green`}
                      onClick={() => requestOTP()}
                    >
                      <span>ขอ OTP</span>
                    </button>
                    <button
                      className={`${OTPValue.length < 6 ? 'disabled' : ''} ${
                        showModal.currentModal === 'forget' ? 'hidden' : ''
                      } btn-next w-full inline-flex justify-center px-4 py-2 rounded-full border-green`}
                      onClick={handleOTP}
                    >
                      <span>ยืนยัน</span>
                    </button>
                  </div>
                ) : !confirmPassword ? (
                  <div className='grid grid-cols-2 gap-4 text-xl'>
                    <button
                      className='btn-back w-fullinline-flex justify-center px-4 py-2 rounded-full'
                      onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))}
                    >
                      <span>กลับ</span>
                    </button>
                    <button
                      className='btn-next w-full inline-flex justify-center px-4 py-2 rounded-full'
                      onClick={() => {
                        _handlePhoneNumber()
                      }}
                    >
                      <span>ต่อไป</span>
                    </button>
                  </div>
                ) : (
                  <div className='grid grid-cols-2 gap-4 text-xl'>
                    <button
                      className='btn-back w-full inline-flex justify-center px-4 py-2 rounded-full'
                      onClick={() => setConfirmPassword(false)}
                    >
                      <span>กลับ</span>
                    </button>
                    <button
                      className={`${
                        newUser.userConfirmPassword.length < 8 ? 'disabled' : ''
                      } btn-next w-full inline-flex justify-center px-4 py-2 rounded-full border-green`}
                      onClick={handleSignUp}
                    >
                      <span>เสร็จสิ้น</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SignUp.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  confirmPassword: PropTypes.bool,
  setConfirmPassword: PropTypes.func,
  handleLogin: PropTypes.func,
  setNewUser: PropTypes.func,
  newUser: PropTypes.object,
}

export default SignUp
