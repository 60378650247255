import React, { useEffect, useState } from 'react'
import moment from 'moment'
// import { useHistory } from 'react-router-dom'

export default function Timer({ drawDateStr = '09/09/2030', drawTimeStr = '23:59:59', closeMinutesBeforeDraw = 0 }) {
  const [diffTime, setDiffTime] = useState('--:--:--')
  const newDrawDateStr = drawDateStr.replace(/\//g, '-')
  const drawDateTime = moment(newDrawDateStr + ' ' + drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
  const realDrawTimeMoment = moment(drawDateTime).subtract(closeMinutesBeforeDraw, 'minutes')
  const [isBlock, setIsBlock] = useState(false)


  useEffect(() => {
    const differ = realDrawTimeMoment.diff(moment())
    let myInterval
    const createInverval = (drawTimeMoment) => {
      const updateTime = (drawTimeMoment) => {
        const differ = drawTimeMoment.diff(moment())
        let withOutDate = moment.utc(differ).format('HH:mm:ss')
        let withDate = moment.utc(differ).subtract(1, 'days').format('DD วัน HH:mm:ss')
        let withOutHours = moment.utc(differ).format('mm:ss')

        if (differ < 86400000 && differ > 3600000) {
          setDiffTime(withOutDate)
        } else if (differ < 3600000) {
          setDiffTime(withOutHours)
        } else {
          setDiffTime(withDate)
        }

        if (differ < 0 || differ === 0) {
          clearInterval(myInterval)
        }
      }

      myInterval = setInterval(() => {
        updateTime(drawTimeMoment)
      }, 1000)
    }
    if (parseInt(differ) < 0) {
      setIsBlock(true)
    } else {
      createInverval(realDrawTimeMoment)
    }
    return () => {
      clearInterval(myInterval)
    }
  }, [])
  return <div className='__timer text-2xl'>{isBlock ? 'ปิดรับ' : diffTime}</div>
}
