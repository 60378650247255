import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Auth, API } from 'aws-amplify'
import Swal from 'sweetalert2'
import Iframe from 'react-iframe'
import Modal from 'react-modal'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import { useHistory, Link } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates'
import TimePicker from 'react-time-picker'
import moment from 'moment'
import AliceCarousel from 'react-alice-carousel'
import md5 from 'md5'

import BoxWhite from '../../components/Box/BoxWhite'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import CreditCard from '../../components/CreditCard/CreditCard'
import { getAuth } from '../../redux/selectors/auth'
import './Deposit.scss'
import { ppthb888Deposit } from '../../third-party/ppthb888'
import Button from '../../components/Button/Button'
import { copyToClipboard } from '../../utils/copy'
import './Deposit.scss'
import Box from '../../components/Box/Box'
import Login from '../../components/Login/Login'
// import loadingImg from '../../assets//'
import 'react-alice-carousel/lib/alice-carousel.css'
// import mobile01 from '../../assets/bar01.jpg'
// import mobile02 from '../../assets/bar02.jpg'
// import mobile03 from '../../assets/bar03.jpg'
// import mobile04 from '../../assets/bar04.jpg'
//
// const imagesMobile = [mobile01, mobile04]


let timeoutId = null
let intervalCheckExpiredDepositId = null

// const SlideshowMobile = () => {
//   return (
//     <div className='home mobile' style={{ marginBottom: '10px' }}>
//       {/*<div className='home__marquee'>*/}
//       {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
//       {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
//       {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
//       {/*    เว็บดีๆ จงบอกเพื่อน*/}
//       {/*  </Marquee>*/}
//       {/*</div>*/}
//       <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
//         {imagesMobile.map((each, index) => (
//           <img key={index} className='home__sliderimg' src={each} alt='slide' />
//         ))}
//       </AliceCarousel>
//     </div>
//   )
// }
//

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '99',
  },
}

Modal.setAppElement('#root')

const bankCodeMap = {
  kbank: 'kkb',
  scb: 'scb',
  ktb: 'ktb',
  bbl: 'bkb',
  ttb: 'tmb',
  gsb: 'gsb',
  bay: 'boa',
  baac: 'baac',
  uob: 'uob',
  ghb: 'ghb',
};

const bankCodeNumberMap = {
  kbank: '004',
  scb: '014',
  ktb: '006',
  bbl: '002',
  ttb: '011',
  gsb: '030',
  bay: '025',
  baac: '034',
  uob: '024',
  ghb: '033',
};

const Deposit = () => {
  const auth = useSelector(getAuth)
  const username = auth.username
  const history = useHistory()
  const [availableBank, setAvailableBank] = useState([])
  const [selectedAvailableBankAccountNumber, setSelectedAvailableBankAccountNumber] = useState('')
  const [playerBankAccount, setPlayerBankAccount] = useState('')
  const [depositAmount, setDepositAmount] = useState('')
  const [paymentUrl, setPaymentUrl] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSystemBank, setIsLoadingSystemBank] = useState(false)
  const [successDepositData, setSuccessDepositData] = useState(null)
  const [depositDate, setDepositDate] = useState(moment())
  const [depositDateFocus, setDepositDateFocus] = useState(false)
  const [depositTime, setDepositTime] = useState(moment().format('HH:mm'))
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  const [depositType, setDepositType] = useState('bookBank')
  const [imageQRCode, setImageQRCode] = useState(null)
  const [isLoadingQRCode, setLoadingQRCode] = useState(null)
  const [amount, setAmount] = useState(0)
  // const handleDepositAmountChange = (e) => {
  //   const value = e.target.value

  const [pompayInfo, setPompayInfo] = useState({clientId: '', transactionId: '', custName: '', returnUrl: '', callbackUrl: '', bankAcc: ''})
  const [hashVal, setHashVal] = useState('')
  const [amountDeposit, setAmountDeposit] = useState({ amount: '' })

  //   // handle non-number type
  //   if (value === '' || /^\d+(\.\d{0,2})?$/g.test(value)) {
  //     setDepositAmount(value)
  //   }
  // }
  // console.log(depositType, 'depositType')

  const _onClickToCopy = (text = '') => {
    // const value = _get(
    //   _find(availableBank, (bank) => bank.accountNumber === selectedAvailableBankAccountNumber),
    //   'accountNumber'
    // )
    if (text) {
      copyToClipboard(text)
      Swal.fire({
        icon: 'success',
        title: 'คัดลอกสำเร็จ',
        text: text,
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ไม่เจอข้อความ',
      })
    }
  }

  const alertGoToAddBankAccount = (message = '') => {
    const isNotFoundBankAccount = message === 'Request failed with status code 400'
    Swal.fire({
      icon: 'error',
      title: isNotFoundBankAccount ? 'กรุณาเพิ่มบัญชีธนาคาร' : 'เกิดข้อผิดพลาด',
      text: isNotFoundBankAccount ? 'ไม่เจอบัญชีธนาคารของท่าน' : message,
      confirmButtonColor: '#B6964E',
      confirmButtonText: 'เพิ่มธนาคาร',
      showCancelButton: true,
      cancelButtonColor: '#C0C0C0',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        history.push('/add-bank-account')
      }
    })
  }

  const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substring(2)
    return dateString + randomness
  }

  const onDeposit = () => {
    if (playerBankAccount !== '' && amountDeposit.amount > 0) {
      if (amountDeposit.amount < 100) {
        return Swal.fire({
          icon: 'error',
          title: 'การฝากขั้นต่ำ 100 บาทครับ',
        })
      }
      setIsLoading(true)
      const currentTime = new Date().getTime()
      const clientCode = 'S000654TR8Cs'
      const chainName = 'BANK'
      const coinUnit = 'THB'
      const clientNo =
      'yodtong' + '@' + uniqueId() + '@' + playerBankAccount.bankCode + '-' + playerBankAccount.accountNumber
      const memberFlag = playerBankAccount.username
      const requestAmount = amountDeposit.amount
      const requestTimestamp = currentTime
      const callbackurl = 'https://1u03sz9wea.execute-api.ap-southeast-1.amazonaws.com/3monkeys-prod/auto-deposit/deposit'
      const hrefbackurl = 'https://www.huayyodtong.com/lottos'
      const toPayQr = 0
      const privateKey = 'bf852c1ba568db582eb4918549b81f8e'
      const hashArr = [clientCode, chainName, coinUnit, clientNo, currentTime]
      const signature = md5(hashArr.join('&') + privateKey)
      const bodyToSend = {
        clientCode: clientCode,
        chainName: chainName,
        coinUnit: coinUnit,
        clientNo: clientNo,
        memberFlag: memberFlag,
        requestTimestamp: requestTimestamp,
        requestAmount: requestAmount,
        callbackurl: callbackurl,
        hrefbackurl: hrefbackurl,
        sign: signature,
        toPayQr: toPayQr,
      }
      // console.log(bodyToSend, 'bodyToSend')

      API.post('third-party', '/auto-deposit/create-deposit', {
        body: bodyToSend,
      })
        .then((res) => {
          console.log(res, 'res')
          if (res.code === 0) {
            const redirect = res.detail.data.payUrl
            window.location.replace(redirect)
          }
        })
        .catch((err) => {
          console.log('error withdraw ======')
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'กรุณาลองใหม่อีกครั้งในภายหลัง',
          })
        })
        .finally(() => {
          // setIsLoading(false)
        })
    }
  }

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

  const onDeposit2 = () => {
    if (playerBankAccount !== '' && amountDeposit.amount > 0) {
      if (amountDeposit.amount < 300) {
        return Swal.fire({
          icon: 'error',
          title: 'การฝากขั้นต่ำ 300 บาทครับ',
        })
      }
      setIsLoading(true)
      const qrCodeTransactionId =
        'h' + '@' + makeid(4) + '@' + playerBankAccount.bankCode + '-' + playerBankAccount.accountNumber.slice(-6)
      const amount = +parseFloat(Math.round(amountDeposit.amount * 100) / 100).toFixed(2)
      const currency = 'THB'
      const description = 'PAYMENT'
      const bodyToSend = {
        qrCodeTransactionId: qrCodeTransactionId,
        currency: currency,
        amount: amount,
        payMethod: 'thaiqr',
        bankCode: bankCodeNumberMap[playerBankAccount.bankCode],
        accountNumber: playerBankAccount.accountNumber,
        accountName: playerBankAccount.accountName,
        description: description,
      }
      console.log(bodyToSend, 'bodyToSend')

      API.post('third-party', '/auto-deposit/create-deposit2', {
        body: bodyToSend,
      })
        .then((res) => {
          console.log(res, 'res')
          if (res.code === 0) {
            const redirect = res.detail.payurl
            window.location.replace(redirect)
          }
        })
        .catch((err) => {
          console.log('error withdraw ======')
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'กรุณาลองใหม่อีกครั้งในภายหลัง',
          })
        })
        .finally(() => {
          // setIsLoading(false)
        })
    }
  }

  // console.log(pompayInfo, hashVal, 'pompayInfo')

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    // if (inputName === 'username') {
    //   const newValue = value.replace(/[^0-9]/g, '')
    //   setNewUser({ ...newUser, [inputName]: newValue })
    //   return
    // }
    const newValue = value

    setAmountDeposit({ ...pompayInfo, [inputName]: newValue })
  }

  useEffect(() => {
    setIsLoadingSystemBank(true)

    if (!username) {
      // Not login yet
      timeoutId = setTimeout(() => {
        Swal.fire({
          icon: 'error',
          title: 'Please login',
          text: 'Please login before proceeding',
          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: '#C0C0C0',
          confirmButtonText: 'Login',
        }).then((result) => {
          if (result.value) {
            setShowModal({ isShow: true, currentModal: 'login' })
          }
        })
      }, 1500)
      setIsLoadingSystemBank(false)
    } else {
      clearTimeout(timeoutId)

      API.get('payment', `/bank-accounts`)
        .then((res) => {
          const allBank = _get(res, 'detail', null)
          const forSortBank = allBank.filter(
            (obj) =>
              obj.accountNumber !== '0684000309' &&
              obj.accountNumber !== '2152617967' &&
              obj.accountNumber !== '1702965691'
          )
          if (forSortBank && !_isEmpty(forSortBank)) {
            const availableBank = shuffleArray(forSortBank)
            console.log('availableBank >>>', availableBank)
            setAvailableBank(availableBank)

            const bankAccountNumber = _get(availableBank, '0.accountNumber', '')
            setSelectedAvailableBankAccountNumber(bankAccountNumber)
          }
        })
        .catch((err) => {
          console.log('error get available bank account ======')
          console.error(err)
        })
        .finally(() => {
          setIsLoadingSystemBank(false)
        })

      API.get('playerBankAccount', `/players/${username}/bank-account`)
        .then((res) => {
          const playerBankAccount = _get(res, 'detail', null)
          if (playerBankAccount && !_isEmpty(playerBankAccount)) {
            console.log('playerBankAccount >>>', playerBankAccount)
            setPlayerBankAccount(playerBankAccount)
          } else {
            alertGoToAddBankAccount('ไม่พบบัญชีของท่าน กรุณาเพิ่มบัญชีธนาคาร')
          }
        })
        .catch((err) => {
          console.log('error get bank account ======')
          console.error(err)
          alertGoToAddBankAccount(_get(err, 'message', ''))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [username])

  function shuffleArray(array) {
    let i = array.length
    while (i--) {
      const ri = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[ri]] = [array[ri], array[i]]
    }
    return array
  }

  // const handleConfirmPayment = async () => {
  //   // Check depositAmount >= 100 THB
  //   if (Number(depositAmount) < 100) {
  //     return Swal.fire({
  //       icon: 'error',
  //       title: 'กรุณาฝากเงินขั้นต่ำ 100 บาท',
  //     })
  //   }

  //   // Alert to check user confirmation
  //   let topupModal = await Swal.fire({
  //     icon: 'info',
  //     title: 'ยืนยันการทำรายการ',
  //     text: `ฝากเงินเข้าระบบจำนวน ${depositAmount} บาท`,
  //     confirmButtonText: 'ยืนยันฝากเงิน',
  //     confirmButtonColor: '#7dc351',
  //     showCancelButton: true,
  //     cancelButtonColor: '#C0C0C0',
  //   })

  //   if (topupModal.isConfirmed) {
  //     const username = auth.username
  //     try {
  //       const res = await API.post('payment', '/transactions', {
  //         body: {
  //           playerUsername: username,
  //           playerAccountId: username,
  //           collectionAccountId: username,
  //           transactionType: 'deposit',
  //           amount: depositAmount,
  //         },
  //       })
  //       const transactionNumber = get(res, 'detail', null)
  //       if (transactionNumber) {
  //         try {
  //           const username = auth.username
  //           const url = await ppthb888Deposit({
  //             playerUsername: username,
  //             amount: depositAmount,
  //             transactionNumber,
  //             remark: '',
  //           })
  //           setOpenModal(true)
  //           setPaymentUrl(url)
  //         } catch (err) {
  //           console.log(err)
  //           return Swal.fire({
  //             icon: 'error',
  //             title: 'ระบบไม่สามารถใช้งานได้ในขณะนี้',
  //             text: 'ขออภัยครับ ไม่สามารถฝากเงินด้วย QR ได้ในขณะนี้ กรุณาติดต่อเจ้าหน้าที่ครับ',
  //           }).then((clicked) => {
  //             console.log('clicked', clicked)
  //             history.push('/deposit')
  //           })
  //         }
  //       }
  //       throw new Error('Not found transaction number')
  //     } catch (err) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'เกิดข้อผิดพลาด',
  //         text: `${err.message}`,
  //       })
  //     }
  //   }

  //   // else
  //   // topup value not specified
  //   // --> do nothing
  // }

  const _onDepositAmountChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+(\.\d{0,2})?$/g.test(value)) {
      setDepositAmount(value)
    }
  }

  const _onClickConfirmDepositButton = (e) => {
    e.preventDefault()
    const amountNumber = Number(depositAmount)
    // Validate
    if (!amountNumber || amountNumber < 100) {
      Swal.fire({
        icon: 'error',
        title: 'Your withdrawal amount is below the limit',
        text: 'Please specify the amount with a minimum number of 100 baht',
      })
      return
    }

    setIsLoading(true)
    API.post('payment', '/transactions', {
      body: {
        playerUsername: username,
        playerAccountId: username,
        collectionAccountId: username,
        transactionType: 'deposit',
        amount: amountNumber,
      },
    })
      .then((res) => {
        const transactionNumber = _get(res, 'detail', null)
        if (transactionNumber) {
          return Swal.fire({
            icon: 'success',
            title: 'Successful deposit order',
            text: `(Transaction number: ${transactionNumber})`,
          })
        } else {
          throw new Error('System error, transaction number not found')
        }
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'An error occurred',
          text: err.message,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _onAutoDepositAmountChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+$/g.test(value)) {
      setDepositAmount(value)
    }
  }

  const _onClickAutoDeposit = (e) => {
    e.preventDefault()
    const amountNumber = Number(depositAmount)
    // Validate
    if (!amountNumber || amountNumber < 1) {
      Swal.fire({
        icon: 'error',
        title: 'An error occurred',
        text: 'Please specify the amount with a minimum number of 1 baht.',
      })
      return
    }

    setIsLoading(true)

    API.post('thirdParty', `/auto-deposit/reserve`, {
      body: {
        amount: amountNumber,
      },
    })
      .then((res) => {
        const detail = _get(res, 'detail', null)
        if (!_isEmpty(detail)) {
          const { reserveAmount, id, expiredDate } = detail

          const expiredCheckTime = moment(expiredDate)
          let expiredText = moment(expiredDate).format('DD/MM/YYYY [time] HH:mm:ss')

          intervalCheckExpiredDepositId = setInterval(() => {
            const now = moment()
            console.log('-> now', now.format())
            console.log('expiredDate ======', expiredCheckTime)
            const isExpired = now.isAfter(expiredCheckTime)
            if (isExpired) {
              clearInterval(intervalCheckExpiredDepositId)
              intervalCheckExpiredDepositId = null
              setSuccessDepositData(null)
              setDepositAmount('')
            }
          }, 10000)

          setSuccessDepositData({ ...detail, expiredText })

          return Swal.fire({
            icon: 'success',
            title: 'ส่งคำสั่งฝากเงินสำเร็จ',
            text: `กรุณาโอนเงินจำนวน ${reserveAmount} บาท ภายใน ${expiredText}\nหมายเลขธุรกรรม: ${id})`,
          })
        } else {
          throw new Error('ระบบผิดพลาด ไม่พบหมายเลขธุรกรรม Transaction number')
        }
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message,
        })
      })
      .finally(() => {
        setIsLoading(false)
        setDepositAmount('')
      })
  }

  const _onClickAutoCancel = (e) => {
    e.preventDefault()

    setIsLoading(true)
    API.del('thirdParty', `/auto-deposit/cancel-reserve`, {})
      .then((res) => {
        console.log('cancel >>>', res)

        const code = _get(res, 'code', null)
        const detail = _get(res, 'detail', null)
        if (code === 0) {
          // const message = _get(detail, 'message', '')
          setSuccessDepositData(null)

          return Swal.fire({
            icon: 'success',
            title: 'ยกเลิกคำสั่งที่ค้างทั้งหมดสำเร็จ',
          })
        } else {
          throw new Error('ระบบผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
        }
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message + 'กรุณาติดต่อเจ้าหน้าที่',
        })
      })
      .finally(() => {
        setIsLoading(false)
        setDepositAmount('')
      })
  }

  // const getBankAccountText = () => {
  //   if (playerBankAccount) {
  //     return playerBankAccount.bankName + ' ' + playerBankAccount.accountNumber
  //   }
  //   return '-'
  // }

  const handleChangeDepositType = (e) => {
    const value = e.target.value
    setDepositType(value)
    setLoadingQRCode(false)
    setImageQRCode(null)
  }

  const handleAmount = (e) => {
    const value = e.target.value
    setAmount(value)
    setImageQRCode(null)
  }

  const handleGenerateQRCode = async () => {
    if (amount < 200) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกเงินขั้นต่ำที่ 200 บาท',
        confirmButtonColor: '#B6964E',
        cancelButtonColor: '#C0C0C0',
        confirmButtonText: 'ปิด',
      })
      return
    }
    setLoadingQRCode(true)
    setImageQRCode(null)
    API.get('playerBankAccount', `/players/${username}/bank-account`)
      .then((res) => {
        const playerBankAccount = _get(res, 'detail', null)
        if (playerBankAccount && !_isEmpty(playerBankAccount)) {
          console.log('playerBankAccount >>>', playerBankAccount)
          setPlayerBankAccount(playerBankAccount)
        } else {
          alertGoToAddBankAccount('ไม่พบบัญชีธนาคารของคุณ กรุณาเพิ่มบัญชีธนาคารก่อน \nหรือกรุณาติดต่อเจ้าหน้าที่')
        }

        const split = playerBankAccount.accountName.split(' ')
        let firstName = ''
        let lastName = ''
        if (split.length > 0) {
          firstName = split[0]
          lastName = split[1] ? split[1] : ''
        }

        API.post('thirdParty', '/amb/generateQR', {
          body: {
            player_username: username,
            amount: amount,
            payer_account_no: playerBankAccount.accountNumber,
            payer_account_first_name: firstName,
            payer_account_last_name: lastName,
            bank_name: playerBankAccount.bankCode,
          },
        })
          .then((res) => {
            try {
              setLoadingQRCode(false)
              setImageQRCode(res.detail.value)
            } catch (error) {
              console.log('error => ', error)
            }
          })
          .catch((err) => {
            setLoadingQRCode(false)
            console.log('error => ', err)
            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถสร้าง QR Code ได้',
              text: 'กรุณาสร้าง QR Code ใหม่อีกครั้ง',
              confirmButtonColor: '#B6964E',
              cancelButtonColor: '#C0C0C0',
              confirmButtonText: 'ปิด',
            })
          })
      })
      .catch((err) => {
        console.log('error get bank account ======')
        console.error(err)
        alertGoToAddBankAccount(_get(err, 'message', ''))
        setLoadingQRCode(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <section className='deposit deposit__container mt-20'>
      {/* <div className='home__banking-action mt-20'> */}
      <div className='btn_box_header deposit__container'>
      <div className='home__banking-action mt-20'>
        <div>
          <Link to='/deposit'>
            <button className='px-10 py-2 btn-bevel green' style={{ border: '4px ridge #B9A97D' }}>
              <span>+ ฝากเงิน</span>
            </button>
          </Link>
        </div>
        <div>
          <Link to='/withdraw'>
            <button className='px-10 py-2 btn-bevel red'>
              <span>- ถอนเงิน</span>
            </button>
          </Link>
        </div>
      </div>
      </div>
      {/* <SlideshowMobile />
      <SlideshowPc /> */}
      <CreditCard />
      <>
        {/* <BoxWhite containerClass='deposit__stepBox'> */}
        {/* <span className='deposit__step'>ขั้นตอนที่ 1</span> */}
        {/*<p>เลือก 1 บัญชีเพื่อทำการโอนเงิน</p>*/}
        {/* <p style={{ color: 'grey', textAlign: 'center', marginTop: '5px' }}> */}
        {/* &nbsp;&nbsp;เพียงแค่โอนเงินจากบัญชีที่ท่านได้ลงทะเบียนไว้ */}
        {/* </p> */}
        {/* <p style={{ color: 'grey', marginBottom: '5px' }}> */}
        {/* &nbsp;&nbsp;&nbsp;&nbsp;ท่านจะได้รับเครดิตทันที ขั้นต่ำ 1 บาท */}
        {/* </p> */}
        {/*<p style={{ color: 'grey', fontSize: '13px', textAlign: 'center' }}>*/}
        {/*  การฝากเงินไม่มีขั้นต่ำ ท่านสามารถทดสอบระบบด้วยการโอน 1 บาท หากท่านต้องการ*/}
        {/*</p>*/}
        {/* {!isLoadingSystemBank && playerBankAccount ? ( */}
        {/* availableBank.map((bankData, i) => { */}
        {/* return ( */}
        {/* <div key={bankData.bankCode + i}> */}
        {/* <div className='deposit__bankDetail'> */}
        {/* {bankData.bankCode === 'SCB' ? ( */}
        {/* <img src={require('../../pages/Deposit/images/scb-logo.png')} alt='scb-logo' /> */}
        {/* ) : ( */}
        {/* <img src={require('../../pages/Deposit/images/magnifying-glass-white.png')} alt='bank-logo' /> */}
        {/* )} */}
        {/* <div className='deposit__bankDetail_user'> */}
        {/* <p className='deposit__bankDetail_accountNumber' style={{ fontSize: '25px' }}> */}
        {/* {bank.bankCode} {bank.bankName} - {bank.accountNumber} */}
        {/* {!isLoadingSystemBank ? bankData.accountNumber : 'กำลังโหลด...'} */}
        {/* </p> */}
        {/* <div className='deposit__bankDetail_userInfo' style={{ fontSize: '15px' }}> */}
        {/* <p>{!isLoadingSystemBank ? bankData.accountName : 'กำลังโหลด...'}</p> */}
        {/* <button */}
        {/* style={{ borderRadius: '0px', color: '#FFFFFF' }} */}
        {/* onClick={() => _onClickToCopy(bankData.accountNumber)} */}
        {/* > */}
        {/* <span>คัดลอก</span> */}
        {/* </button> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* ) */}
        {/* }) */}
        {/* ) : !isLoadingSystemBank && !playerBankAccount ? ( */}
        {/* <p> */}
        {/* กรุณา */}
        {/* <Link to='/add-bank-account' style={{ color: '#5fc22f' }}> */}
        {/* เพิ่มบัญชีธนาคาร */}
        {/* </Link> */}
        {/* ของท่าน ก่อนดำเนินการต่อไปครับ */}
        {/* </p> */}
        {/* ) : isLoadingSystemBank ? ( */}
        {/* <span>กำลังโหลด...</span> */}
        {/* ) : null} */}
        {/* </BoxWhite> */}

        <div className='depositForm'>
        {/* <h1>
          <strong>ฝากเงิน</strong>
        </h1> */}
        <div className='item'>
          {/* <label>Amount</label> */}
          <strong>ฝากออโต้ ระบุจำนวนที่ต้องการฝาก</strong>
          <input
            type='number'
            name='deposit-value'
            id='deposit-value'
            placeholder='ระบุจำนวนที่ต้องการฝาก'
            onChange={(e) => _onChangeForm(e, 'amount')} 
            value={amountDeposit.amount}
          />
          {/* {language !== 'MM' ? <p className='description'>
            การถอนเงินออกจากระบบจะเป็นการโอนเงินไปยังบัญชีที่ลงทะเบียนไว้เท่านั้น
            โปรดตรวจสอบจำนวนเงินและเลขบัญชีผู้รับเงินให้ถูกต้อง ก่อนกดถอนเงิน
          </p> :
          <p className='description'>
            The money shall transfer to the registered bank account only. Please confirm the amount and bank account of
            the recipient before proceed.
          </p> } */}
          <div className='button-box'>
          <div>
          {/* <input name='clientId' type='hidden' value={pompayInfo.clientId} />
          <input name='transactionId' type='hidden' value={pompayInfo.transactionId} />
          <input name='custName' type='hidden' value={pompayInfo.custName} />
          <input name='custBank' type='hidden' value={pompayInfo.custBank} />
          <input name='amount' type='hidden' value={parseFloat(amountDeposit.amount).toFixed(2)} />
          <input name='returnUrl' type='hidden' value={pompayInfo.returnUrl} />
          <input name='callbackUrl' type='hidden' value={pompayInfo.callbackUrl} />
          <input name='bankAcc' type='hidden' value={pompayInfo.bankAcc} />
          <input name='hashVal' type='hidden' value={hashVal} /> */}
                  <button
                    className={`deposit-btn ${isLoading ? 'disabled' : ''}`}
                    type={amountDeposit.amount > 0 ? 'submit' : 'hidden'}
                    onClick={onDeposit2}
                  >
                    ฝากเงิน
                  </button>
        </div>
          </div>
        </div>
      </div>
      </>
      <Modal
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false)
        }}
        style={customStyles}
        contentLabel='Payment Modal'
      >
        <Iframe
          url={paymentUrl}
          width='600px'
          height='600px'
          id='deposit-iframe'
          className='depositIframe'
          display='initial'
          position='relative'
        />
      </Modal>
      {!auth.username ? <Login showModal={showModal} setShowModal={setShowModal} /> : null}
    </section>
  )
}

export default Deposit
