import React from 'react'
import PropTypes from 'prop-types'

import Input from '../Form/Input'
import { FacebookIcon } from '../Icon/Facebook'
import { LineIcon } from '../Icon/Line'

function Login(props) {
  const showModal = props.showModal
  const setShowModal = props.setShowModal
  const handleLogin = props.handleLogin
  const setNewUser = props.setNewUser
  const newUser = props.newUser

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    if (inputName === 'username') {
      const newValue = value.split('@')[0]
      setNewUser({ ...newUser, [inputName]: newValue })
      return
    }

    setNewUser({ ...newUser, [inputName]: value })
  }

  return (
    <>
      <section className={`home__modal ${showModal.isShow && showModal.currentModal === 'login' ? 'active' : ''}`}>
        <div className={`home__modal-box ${showModal.currentModal === 'login' ? 'active' : ''}`}>
          <div className='home__modal-box-header text-xl'>
            <span>เข้าสู่ระบบ</span>
            <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))}>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
              </svg>
            </button>
          </div>
          <div className='home__modal-box-content text-xl'>
            <form className='form__wrapper' onSubmit={handleLogin}>
              <div className='form__setWrapper-login'>
                <div className='form__item-login'>
                  <Input
                    onChange={(e) => _onChangeForm(e, 'username')}
                    placeholder='กรอกชื่อผู้ใช้'
                    type='text'
                    id='login-username'
                    value={newUser.username}
                  />
                  <button
                    className={`${newUser.password.length < 8 ? 'disabled' : ''}`}
                    onClick={handleLogin}
                    type='submit'
                  >
                    <span>เข้าสู่ระบบ</span>
                  </button>
                </div>
                <div className='form__item-login'>
                  <Input
                    onChange={(e) => _onChangeForm(e, 'password')}
                    placeholder='กรอกรหัสผ่านของท่าน'
                    type='password'
                    id='login-password'
                    autocomplete='login-password'
                    value={newUser.password}
                  />
                  <button
                    className={`${newUser.username.length !== 10 ? 'disabled forgetPassword' : 'forgetPassword'}`}
                    // onClick={() => setShowModal((prevState) => ({ ...prevState, currentModal: 'forget' }))}
                  >
                    <span>ลืมรหัส</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='home__banking-action'>
            <div className='rounded-full' style={{ marginBottom: '10px', marginTop: '0' }}>
              <button
                onClick={(e) => handleLogin(e, 'facebook')}
                className='btn-bevel text-xl px-6 py-2 font-bold text-white rounded-full __facebook'
              >
                <FacebookIcon /> <span>เข้าสู่ระบบ</span>
              </button>
              <span>หรือ</span>
              <button
                onClick={(e) => handleLogin(e, 'line')}
                className='btn-bevel text-xl px-6 py-2 font-bold text-white rounded-full __line'
              >
                <LineIcon /> <span>เข้าสู่ระบบ</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Login.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  handleLogin: PropTypes.func,
  setNewUser: PropTypes.func,
  newUser: PropTypes.object,
}

export default Login
