import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import _get from 'lodash/get'
// import _isEmpty from 'lodash/isEmpty'
// import _isNumber from 'lodash/isNumber'
import numeral from 'numeral'
// import { Link } from 'react-router-dom'

import { getAuth } from '../../redux/selectors/auth'

import './CreditCard.scss'

const CreditCard = () => {
  const auth = useSelector(getAuth)
  const username = auth.username
  const walletCredit = useSelector((state) => _get(state, 'wallet.credit', null))
  const [isLoading, setIsLoading] = useState(false)
  const [playerBankAccount, setPlayerBankAccount] = useState(null)

  useEffect(() => {
    fetchActiveBankAccount()
  }, [])

  const fetchActiveBankAccount = async () => {
    setIsLoading(true)
    const result = await API.get('playerBankAccount', `/players/${username}/bank-account`)
      .then((res) => {
        const detail = _get(res, 'detail', null)
        const code = _get(res, 'code', null)
        if (code === 0) {
          setPlayerBankAccount(detail)

          return detail
        }
      })
      .catch((err) => {
        console.log('error check bank account ======')
        console.error(err)
        setPlayerBankAccount(null)
        // Swal.fire({
        //   icon: 'error',
        //   title: 'เกิดข้อผิดพลาด',
        //   text: err.message,
        // })

      })
      .finally(() => {
        setIsLoading(false)
      })

    return result
  }

  return (
    <section className='creditCard__container'>
      <div className='creditCard__bookbankWrapper'>
        <img src={require('./images/credit-card.png')} className='creditCard__bookbankWrapper image' alt='credit' />
        <div className='bookbank'>
          <p style={{ fontWeight: 'bold' }}>{numeral(walletCredit).format('0,0.00')}</p>
          <p className='bookbank__unit' style={{ fontWeight: 'bold' }}>
            บาท
          </p>
          {/* <img src={require('./images/desc.png')} alt='description' /> */}
          {playerBankAccount && playerBankAccount.bankCode ?
            <img className='bookbank__logo' src={require(`../../assets/bank-logo/${playerBankAccount.bankCode}.png`)}
                 alt='bank-user-logo'/> : null}
          {/*<p className='bookbank__description' style={{ fontWeight: 'bold' }}>*/}
          {/*  มูลค่าทั้งหมด*/}
          {/*</p>*/}
          {/*<div className='bookbank__infoWrapper'>*/}
          {/*  <p className='bookbank__infoWrapper__first-rule' style={{ fontWeight: 'bold' }}>*/}
          {/*    ฝากขั้นต่ำ 1 บาท*/}
          {/*  </p>*/}
          {/*  <p className='bookbank__infoWrapper__second-rule' style={{ fontWeight: 'bold' }}>*/}
          {/*    เพื่อความสะดวกรวดเร็ว กรุณาใส่จุดทศนิยมในการโอนครั้งที่สอง หากโอนยอดเดิมห่างจากครั้งแรกไม่เกิน 30 วินาที*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className='bookbank__userInfo'>
            <span>{_get(playerBankAccount, 'bankName', 'กำลังตรวจสอบบัญชี')}</span>
            <span>{_get(playerBankAccount, 'accountName', '')}</span>
            <span>{_get(playerBankAccount, 'accountNumber', '')}</span>
          </div>
        </div>
      </div>
      {/*<div className='creditCard__cardWrapper'>*/}
      {/*  <div className='creditCard__topWrapper'>*/}
      {/*    <div className='creditCard__topWrapper_chip'>*/}
      {/*      <img className='creditCard__chip' src={require('./images/sim-card.png')} alt='credit card chip' />*/}
      {/*    </div>*/}
      {/*    <div className='creditCard__topWrapper_text'>*/}
      {/*      <div className='creditCard__topWrapper_textWithBall'>*/}
      {/*        <img className='creditCard__ball' src={require('./images/pool-ball-3.png')} alt='pool ball number 3' />*/}
      {/*        <div className='creditCard__userInfo'>*/}
      {/*          <span>{_get(playerBankAccount, 'bankName', 'กำลังตรวจสอบบัญชี')}</span>*/}
      {/*          <span>{_get(playerBankAccount, 'accountName', '')}</span>*/}
      {/*          <span>{_get(playerBankAccount, 'accountNumber', '')}</span>*/}
      {/*        </div>*/}
      {/*        <Link to='/add-bank-account'>*/}
      {/*          <button style={{ color: 'red' }}>แก้ไขบัญชี</button>*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*      <div className='creditCard__topWrapper_textWithTotal'>*/}
      {/*        <p>ยอดเงินของคุณ</p>*/}
      {/*        <p style={{ fontWeight: 'bold' }}>{numeral(walletCredit).format('0,0.00')}</p>*/}
      {/*        <p>บาท</p>*/}
      {/*      </div>*/}
      {/*      <div className='creditCard__bottomWrapper'>*/}
      {/*        <p className='creditCard__title'>PAATOOK</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
  )
}

export default CreditCard
